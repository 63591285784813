import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const MensajeErrror = (
    {
        setShowMensajeError,
        showMensajeError,
        mensajeError
    }
) => {

    /**
     * Muestra el mensaje
     */
    const showMessage = async () => {
        if (showMensajeError) {
            toast.error(mensajeError, {
                theme: 'colored',
                position: "bottom-right"
            });


            setShowMensajeError(false);
        }
    };

    /**
     * Se ejecuta por primera vez
     */
    useEffect(() => {

        showMessage();

    }, [setShowMensajeError, showMensajeError, mensajeError]);

    return (
        //<ToastContainer/>
        <></>
    );
};

MensajeErrror.propTypes = {

    setShowMensajeError: PropTypes.func,
    showMensajeError: PropTypes.bool,
    mensajeError: PropTypes.string
};


export default MensajeErrror;