import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.vToken
    };

    const getName = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.vNombre;
    };

    const getNombreCompleto = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.vNombre + " " + userToken?.vApellidos;
    };

    const getIdUsuario = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.idUsuario;
    };

    const getProfileImage = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.imagen;
    };

    const getUsuario = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        return userToken?.vUsuario;
    };

    const getIdCatTipoUsuario = () => {
        const idCatTipoUsuarioString = localStorage.getItem('token');
        const idCatTipoUsuario = JSON.parse(idCatTipoUsuarioString);
        return idCatTipoUsuario?.idCatTipoUsuario;
    };


    const [usuario, setUsuario] = useState(getUsuario());
    const [idCatTipoUsuario, setIdCatTipoUsuario] = useState(getIdCatTipoUsuario());
    const [name, setName] = useState(getName());

    const [nombreCompleto, setNombreCompleto] = useState(getNombreCompleto());

    const [idUsuario, setIdUsuario] = useState(getIdUsuario());
    const [profileImage, setProfileImage] = useState(getProfileImage());
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken?.vToken);
    };

    const saveImage = image => {
        const token = localStorage.getItem('token');

        let userToken = JSON.parse(token);
        userToken.imagen = image;
        localStorage.clear();
        localStorage.setItem('token', JSON.stringify(userToken));
        setProfileImage(userToken?.imagen);
    };

    return {
        setToken: saveToken,
        token,
        setName,
        name,
        nombreCompleto,
        setNombreCompleto,
        setIdUsuario,
        idUsuario,
        profileImage,
        setProfileImage: saveImage,
        setUsuario,
        usuario,
        idCatTipoUsuario,
        setIdCatTipoUsuario
    }
}