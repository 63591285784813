import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import IconButton from 'components/common/IconButton';

const Greetings = (

    {
        setShowModalNuevo,
        setShowModalRegistrarGasto,
    }

) => {

    return (
        <Card className="mb-1 p-2">
            <Row className="g-2 align-items-sm-center">
                <Col>
                    <h5>
                        Lista de Gastos
                    </h5>
                </Col>
                <Col className="col-auto">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-2"
                        className="d-sm-block"
                        onClick={() => { setShowModalRegistrarGasto(true); setShowModalNuevo(true); }}

                    >
                        Nuevo Gasto
                    </IconButton>
                </Col>

            </Row>
        </Card>
    );
};

Greetings.propTypes = {

    setShowModalNuevo: PropTypes.func,

    setShowModalRegistrarGasto: PropTypes.func,

};




export default Greetings;
