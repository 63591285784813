
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { Link } from 'react-router-dom';
//import Skeleton from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';

import SoftBadge from 'components/common/SoftBadge';
import IconButton from 'components/common/IconButton';
import { Col, Row, Card } from 'react-bootstrap';

import ModalInfoCuenta from './ModalInfoCuenta'





const TablaCuentas = (
    {

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo

    }
) => {

    const columns = [
        {
            accessor: 'idCatCuentaBancaria',
            Header: 'ID',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { idCatCuentaBancaria } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row className="justify-content-start">
                            <Col className="col-auto fw-bold ms-1">
                                <Link to={"#"} onClick={() => { setIDCuenta(idCatCuentaBancaria); setshowAgregarCuentaModal(true); }}>
                                    {"Acc # " + idCatCuentaBancaria}
                                </Link>
                            </Col>
                          
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'vNumCta',
            Header: 'Número de cuenta',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNumCta } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col>{vNumCta}</Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'vAlias',
            Header: 'Alias',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vAlias } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col>{vAlias}</Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'vNombreBanco',
            Header: 'Banco',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNombreBanco } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col>{vNombreBanco}</Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'vNombreTitular',
            Header: 'Titular',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNombreTitular } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col className="fw-bold">{vNombreTitular}</Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'bStripeActive',
            Header: 'Stripe',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { bStripeActive } = rowData.row.original;

                //var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col className="fw-bold">

                                <SoftBadge
                                    pill
                                    bg={bStripeActive ? "stripe" : "danger"}
                                    className="fs--2 ms-1"
                                >
                                    <span className="mt-1 mb-1">
                                        {bStripeActive ? "Vigente" : "No vigente"}
                                    </span>
                                </SoftBadge>
                            </Col>
                        </Row>
                    </>
                );
            }
        }
    ];

    const { token, setToken } = useToken();

    const [tableData, setTableData] = useState([]);
    const [idCuenta, setIDCuenta] = useState(0);
    const [showAgregarCuentaModal, setshowAgregarCuentaModal] = useState(false);


    /**
    * Obtiene las cuentas
    */
    const obtenerCuentas = async () => {

        const response = await fetch("/api/cuentas/obtenerCuentas",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER CUENTAS ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataCuentas: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetalles Cuentas:", ObjDetalles);

                setTableData(ObjDetalles.table2);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            console.log("Error", response);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {
        obtenerCuentas();
    }, []);


    return (
        <>

            <Row>

                <Col className="col-12">
                    <AdvanceTableWrapper
                        columns={columns}
                        data={tableData}

                        selectionColumnWidth={52}
                        sortable
                        pagination
                        rowCount={tableData.length}
                    >
                        <Card>
                            <Card.Header className="px-0">

                                <Row className="ms-1 me-1 justify-content-end">

                                    <Col className="col-auto">
                                        <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            transform="shrink-2"
                                            className="d-sm-block me-2"
                                            type="submit"
                                            onClick={(e) => { e.preventDefault(); setshowAgregarCuentaModal(true); }}
                                        >
                                            <span className="d-none d-xl-inline-block ms-1">Agregar Cuenta</span>
                                        </IconButton>
                                    </Col>

                                 
                                </Row>

                                <Row className="mt-2 justify-content-end">

                                    <Col className="col-xl-5">
                                        <AdvanceTableSearchBox table />
                                    </Col>

                                </Row>

                            </Card.Header>
                            <Card.Body className="p-0">
                                <AdvanceTable
                                    table
                                    headerClassName="bg-light text-800 align-middle"
                                    rowClassName="btn-reveal-trigger align-middle"
                                    tableProps={{
                                        size: 'sm',
                                        className: 'fs--2 mb-0 overflow-hidden'
                                    }}
                                />
                            </Card.Body>
                            <Card.Footer>
                                <AdvanceTableFooter
                                    rowCount={tableData.length}
                                    table
                                    rowInfo
                                    rowsPerPageSelection
                                    navButtons
                                />
                            </Card.Footer>
                        </Card>
                    </AdvanceTableWrapper>

                </Col>

            </Row>

            {
                showAgregarCuentaModal &&
                <ModalInfoCuenta
                    showAgregarCuentaModal={showAgregarCuentaModal}
                    setshowAgregarCuentaModal={setshowAgregarCuentaModal}
                    tableData={tableData }
                    //setTableData={setTableData}
                    idCuenta={idCuenta}
                    setIDCuenta={setIDCuenta }

                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    setMensajeInformativo={setMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                />
            }

        </>
    );
};


TablaCuentas.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func

};

export default TablaCuentas;