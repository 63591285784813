import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const MensajeInformativo = (
    {
        setShowMensajeInformativo,
        showMensajeInformativo,
        mensajeInformativo
    }
) => {
   
    /**
     * Muestra el mensaje
     */
    const showMessage = async () => {
        if (showMensajeInformativo) {
            toast.success(mensajeInformativo, {
                theme: 'colored',
                position: "bottom-right",
                closeOnClick: true,
            });
            setShowMensajeInformativo(false);
        }
    };

    /**
     * Se ejecuta por primera vez
     */
    useEffect(() => {

        showMessage();

    }, [setShowMensajeInformativo, showMensajeInformativo, mensajeInformativo]);

    return (
        //<ToastContainer />
        <></>
    );
};

MensajeInformativo.propTypes = {

    setShowMensajeInformativo: PropTypes.func,
    showMensajeInformativo: PropTypes.bool,
    mensajeInformativo: PropTypes.string
};

export default MensajeInformativo;