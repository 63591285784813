import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import useToken from '../../../hooks/useToken';
const AssetForm = () => {

    //MENSAJES Y AVISOS
    const { token, setToken } = useToken();
    const navigate = useNavigate();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [empleados, setEmpleados] = useState([]);
    const [asset, setAsset] = useState({
        vNumeroRegistro: '',
        vNombreActivo: '',
        vDescripcion: '',
        idCategoria: 0,
        vNumeroSerie: '',
        vUbicacionActivo: '',
        dCostoAdquisicion: 0,
        dFechaCompra: '',
        vImageName: '',
        dFechaUso: '',
        iEmpleadoResponsable: 0,
        iEstadoActivo: 1

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAsset({ ...asset, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(asset);
        sendAssetData(asset);
    };

    const sendAssetData = async (asset) => {
        try {
            alert(JSON.stringify(asset))
            setCargando(true);
            const response = await fetch('/api/activofijo/GuardarActivoFijo',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(asset)
                });
            if (response.ok) {

                console.log('Activo registrado con �xito');
                navigate("/contabilidad/activofijo/");
            } else {
                console.log('Error al registrar el activo');
                // Manejar errores 
            }
        } catch (error) {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.error('Error de conexi&oacute;n:', error);
            // Manejar errores de conexi�n 
        }
        setCargando(false);
    };

    const obtenerListaEmpleados = async () => {
        setCargando(true);
        const response = await fetch("/api/usuario/ObtenerDatosVendedores",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            setEmpleados(data);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        obtenerListaEmpleados();
    }, []);
    return (
        <>
            <PageHeader
                title={"Registro de Nuevo Activo Fijo"}
                className="mb-1"
            >
            </PageHeader>

            <Form onSubmit={handleSubmit}>
                <Card>
                    <Card.Header>Descripci&oacute;n del Activo</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formAssetName">
                                    <Form.Label>N&uacute;mero de registro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                        placeholder="Ingresa el n&uacute;mero del registro"
                                        name="vNumeroRegistro"
                                        value={asset.vNumeroRegistro}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetName">
                                    <Form.Label>Nombre del Activo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                        placeholder="Ingresa el nombre del activo"
                                        name="vNombreActivo"
                                        value={asset.vNombreActivo}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetDescription">
                                    <Form.Label>Descripci&oacute;n</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                        placeholder="Ingresa una descripci&oacute;n"
                                        name="vDescripcion" value={asset.vDescripcion}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetValue">
                                    <Form.Label>Valor</Form.Label>
                                    <Form.Control
                                        type="number"
                                        autoComplete="off"
                                        placeholder="Ingresa el valor del activo"
                                        name="dCostoAdquisicion"
                                        value={asset.dCostoAdquisicion}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetCategory">
                                    <Form.Label>Categor&iacute;a</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="idCategoria"
                                        value={asset.idCategoria}
                                        onChange={handleChange} >
                                        <option value="">Selecciona una categor&iacute;a</option>
                                        <option value="1">Electr&oacute;nica</option>
                                        <option value="2">Mobiliario</option>
                                        <option value="3">Veh&iacute;culos</option>
                                        <option value="4">Inmuebles</option>
                                        <option value="5">Otros</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetSerialNumber">
                                    <Form.Label>N&uacute;mero de Serie</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                        placeholder="Ingresa el n&uacute;mero de serie"
                                        name="vNumeroSerie"
                                        value={asset.vNumeroSerie}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetPurchaseDate">
                                    <Form.Label>Fecha de Compra</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dFechaCompra"
                                        value={asset.dFechaCompra}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Header>Operatividad</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formAssetResponsible">
                                    <Form.Label>Responsable</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="iEmpleadoResponsable"
                                        value={asset.iEmpleadoResponsable}
                                        onChange={handleChange}
                                    >
                                        <option value="">Todos los empleados</option>
                                        {empleados.map((empleado, index) => {
                                            return (<option key={index} value={empleado.idUsuario}>{empleado.vNombre} {empleado.vApellidos}</option>)
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetPurchaseDate">
                                    <Form.Label>Fecha de Uso</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dFechaUso"
                                        value={asset.dFechaUso}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetLocation">
                                    <Form.Label>Ubicaci&oacute;n</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                        placeholder="Ingresa la ubicaci&oacute;n"
                                        name="vUbicacionActivo"
                                        value={asset.vUbicacionActivo}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formAssetStatus">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="status"
                                        value={asset.status}
                                        onChange={handleChange} >
                                        <option value="">Selecciona el estado</option>
                                        <option value="0">0 - Inservible</option>
                                        <option value="1">1 - Activo</option>
                                        <option value="2">2 - En Reparaci&oacute;n</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={cargando} variant="primary" type="submit"> Registrar Activo
                        </Button>
                    </Card.Footer>
                </Card>
            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>

            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />
        </>
    );
};

export default AssetForm