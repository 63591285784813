import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState /*, useCallback, useRef */ } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import useToken from 'hooks/useToken';

import { Button, Card, CloseButton, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import Background from 'components/common/Background';
import corner2 from 'assets/img/illustrations/corner-5.png';
import circle from 'assets/img/icons/connect-circle.png';
import ModalSeguimientoLead from './ModalSeguimientoLead';

import ModalClienteGanado from './ModalClienteGanado';

import DailyStatistics from './daily-statistics/DailyStatistics';


const Contacts = () => {

    const columns = [
        {
            accessor: 'name',
            Header: 'Nombre',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNombreLead, idLead, vJsonTags } = rowData.row.original;

                var objTag = JSON.parse(vJsonTags);


                return (
                    <>
                        <Row>
                            <Col className="col-12 fs--3">


                                &nbsp;
                                <Link to={"#"} onClick={() => { setIdLead(idLead); setShowModal(true); }}>
                                    {"Lead " + idLead}
                                </Link>

                            </Col>
                        </Row>
                        <h6 className="mb-0">
                            <Link to={"#"} onClick={() => { setIdLead(idLead); setShowModal(true); }}>
                                <img title="Ver informaci&oacute;n" src="/img/ver20.png"></img > {vNombreLead}
                            </Link>
                        </h6>

                        <span>
                            {objTag.map(
                                (tag, index) => {

                                    const objDDLTag = tags.find(t => t.idLeadTag == tag.idLeadTag);

                                    return (
                                        <OverlayTrigger key={index} placement="left" overlay={<Tooltip id="tooltip"><strong>{objDDLTag.vNombreTag}</strong></Tooltip>}>
                                            <span style={{ cursor: 'pointer' }}>
                                                <SoftBadge
                                                    className={"me-1 bgTag_" + index}
                                                    bg={objDDLTag.colorActive}

                                                >
                                                    {objDDLTag.vAbreviacionTag}
                                                </SoftBadge>
                                            </span >
                                        </OverlayTrigger>




                                    )
                                }
                            )
                            }

                        </span>
                    </>
                );
            }
        },
        {
            accessor: 'PE',
            Header: 'P.E.',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { dPropuestaEconomica, vMoneda } = rowData.row.original;
                return (
                    <>
                        <span>{dPropuestaEconomica} {vMoneda}</span>
                    </>
                );
            }
        },
        {
            accessor: 'telefono',
            Header: 'Tel\u00E9fono',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { vTelefonoLead } = rowData.row.original;

                //console.log(listaEventos);


                //console.log(objEventoProximo);

                return (
                    <>
                        <Row>
                            <Col className="col-12">
                                &nbsp;&nbsp;
                                {vTelefonoLead.includes('+52') && <img title="M&eacute;xico" src="/img/bandera-mexico-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+1809') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+1829') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+1849') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+502') && <img title="Guatemala" src="/img/bandera-guatemala-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+503') && <img title="El Salvador" src="/img/bandera-el-salvador-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+504') && <img title="Honduras" src="/img/bandera-honduras-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+505') && <img title="Nicaragua" src="/img/bandera-nicaragua-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+506') && <img title="Costa Rica" src="/img/bandera-costa-rica-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+507') && <img title="Panamá" src="/img/bandera-panama-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+51') && <img title="Perú" src="/img/bandera-peru-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+53') && <img title="Cuba" src="/img/bandera-cuba-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+54') && <img title="Argentina" src="/img/bandera-argentina-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+56') && <img title="Chile " src="/img/bandera-chile-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+57') && <img title="Colombia " src="/img/bandera-colombia-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+58') && <img title="Venezuela " src="/img/bandera-venezuela-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+591') && <img title="Bolivia " src="/img/bandera-bolivia-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+593') && <img title="Ecuador " src="/img/bandera-ecuador-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+595') && <img title="Paraguay " src="/img/bandera-paraguay-circular-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+598') && <img title="Uruguay " src="/img/bandera-uruguay-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+1787') && <img title="Puerto Rico " src="/img/bandera-puerto-rico-48.png" width="20"></img>}
                                {vTelefonoLead.includes('+1939') && <img title="Puerto Rico " src="/img/bandera-puerto-rico-48.png" width="20"></img>}

                                <a className="text-700" href={"https://wa.me/" + vTelefonoLead} target="_blank" rel="noreferrer">
                                    <img width="25" src="/img/whatsapp40.png"></img>
                                    <span className="fs-0">{vTelefonoLead} </span>

                                </a>
                            </Col>




                        </Row>

                    </>
                );
            }
        },
        {
            accessor: 'vGiroComercial',
            Header: 'Giro Comercial',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { vGiroComercial } = rowData.row.original;
                return (


                    <span
                        onClick={
                            (event) => {
                                if (event.detail == 2) {
                                    //
                                }

                            }
                        }
                    >
                        {vGiroComercial}
                    </span>
                );
            }
        },
        {
            accessor: 'vNombreUsuario',
            Header: 'Vendedor',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {

                const { vNombreUsuario, dCreatedDate, iDaysDiff, idEtapa, idLead } = rowData.row.original;
                const objEventoProximo = listaEventos.find(t => t.idLead == idLead);
                //console.log(objEventoProximo);
                return (
                    <Row>

                        <Col className="col-12 text-dark fw-bold">
                            {
                                objEventoProximo != null ?
                                    <>
                                        <OverlayTrigger ClassName="me-2" placement="top"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    <strong>
                                                        Evento Próximo &nbsp;
                                                        {new Date(objEventoProximo.dFechaEvento).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                                                        &nbsp; a las &nbsp;
                                                        {
                                                            new Date(objEventoProximo.dFechaEvento).toLocaleTimeString(
                                                                'es-mx',
                                                                {
                                                                    hour12: true
                                                                }
                                                            )
                                                        }
                                                    </strong>
                                                </Tooltip>
                                            }>
                                            <FontAwesomeIcon icon={icon({ name: 'calendar', style: "solid" })} style={{ color: "#1E90FF", }} size="xl" />
                                        </OverlayTrigger>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            &nbsp;
                            {
                                vNombreUsuario
                            }
                        </Col>
                        <Col className="col-12 text-muted">Registro: {new Date(dCreatedDate).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Col>
                        <Col className="col-12">
                            {
                                idEtapa == 4 ?
                                    <>
                                        <SoftBadge
                                            bg={
                                                iDaysDiff >= 0 && iDaysDiff < 3 ? "success" :
                                                    iDaysDiff >= 3 && iDaysDiff < 5 ? "warning" : "danger"}
                                        >
                                            {iDaysDiff + " días sin actualizaciones"}
                                        </SoftBadge>

                                    </>
                                    :
                                    <>
                                        <SoftBadge
                                            bg={
                                                iDaysDiff >= 0 && iDaysDiff < 3 ? "success" :
                                                    iDaysDiff >= 3 && iDaysDiff < 5 ? "warning" : "danger"}
                                        >
                                            {iDaysDiff + " días sin actualizaciones"}
                                        </SoftBadge>
                                    </>
                            }

                        </Col>
                    </Row>

                );
            }
        },
        {
            accessor: 'etapa',
            Header: 'Etapa',
            headerProps: { className: 'text-end white-space-nowrap' },
            cellProps: {
                className: 'font-sans-serif fs-0 text-end'
            },
            Cell: rowData => {
                const { idEtapa, vNombreEtapa, idLead, vJsonLeadsVinculados } = rowData.row.original;
                //console.log(rowData);
                return (

                    editarEtapa == idLead ?
                        <>
                            <div className="">

                                <Row>

                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            aria-label="Default select"
                                            className="in-line"
                                            value={idEtapa}
                                            onChange={
                                                async (event) => {
                                                    console.log(event);

                                                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                                                    if (event.target.value == 5) {
                                                        //muestra el modal para mandar un comentario
                                                        setIdLead(idLead);
                                                        setShowEtapaNoInteresadoModal(true);
                                                        setHabilitarBtnGuardar(true);

                                                    } else if (event.target.value == 4 && contactos[currentLeadIndex].idClienteAkasia == null) {
                                                       
                                                        setvNombreEtapa(vNombreEtapa);
                                                        setIdLead(idLead);

                                                        console.log("leads vinculados: ", vJsonLeadsVinculados);
                                                        var leadsVinculados = JSON.parse(vJsonLeadsVinculados);
                                                        if (leadsVinculados != null) {
                                                            const newArray = leadsVinculados.filter(l => l.idLead !== idLead);
                                                            setListaJsonLeadsVinculados(newArray);
                                                        }

                                                        setShowEtapaClienteGanadoModal(true);

                                                    }
                                                    else {
                                                        //Debe modificar la etapa
                                                        var jDataSend = {};

                                                        jDataSend.idEtapa = event.target.value;
                                                        jDataSend.idLead = idLead;

                                                        const response = await fetch("/api/leads/changeEtapaLead/",
                                                            {
                                                                method: 'POST',
                                                                headers:
                                                                {
                                                                    'Authorization': 'Bearer ' + token,
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify(jDataSend)
                                                            }).catch((error) => {

                                                                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                                                                setShowMensajeError(true);
                                                                console.log('Hubo un problema con la peticion Fetch:' + error);
                                                            });

                                                        if (response.ok) {

                                                            console.log("==== Change Lead Etapa ====");
                                                            console.log(response);
                                                            const data = await response.json();
                                                            console.log(data);

                                                            if (data.iResponseType == 1) {

                                                                var detalles = JSON.parse(data.detalles);
                                                                console.log(detalles);


                                                                GuardarComentario("Modificó etapa de <b>" + contactos[currentLeadIndex].vNombreEtapa + "</b> a <b>" + detalles[0].vNombreEtapa + "</b>", "2", contactos[currentLeadIndex].idLead);

                                                                console.log(currentLeadIndex);

                                                                contactos[currentLeadIndex].idEtapa = detalles[0].idEtapa;
                                                                contactos[currentLeadIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                                                                contactos[currentLeadIndex].iDaysDiff = 0;

                                                                setEditarEtapa("");

                                                                setMensajeInformativo("Cambio de etapa exitoso");
                                                                setShowMensajeInformativo(true);

                                                                //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead.
                                                                if (idUsuario != vendedor) {

                                                                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                                                                    var objToActionString = crearObjAction(1, '', 1, contactos[currentLeadIndex].idLead);
                                                                    guardarNotificacion("Etapa de lead cambiada", "<b> " + nombreCompleto + "</b> modificó la etapa del lead <b>#" + contactos[currentLeadIndex].idLead + "</b> a: <i> '" + detalles[0].vNombreEtapa.substring(0, 7) + "...'</i>", vendedor, objToActionString);
                                                                }

                                                            } else {
                                                                setShowMensajeError(true);
                                                                setMensajeError(data.vMessage);
                                                            }

                                                        }
                                                        else {
                                                            setShowMensajeError(true);
                                                            setMensajeError(response.status + ' ' + response.statusText);
                                                            if (response.status == 401) {
                                                                setToken(null);
                                                                location.href = "";
                                                            }
                                                            console.log(response);
                                                        }
                                                    }


                                                }
                                            }
                                        >
                                            {catEtapas.map((etapa, index) => {
                                                return (<option key={index} value={etapa.idEtapa}>{etapa.vNombreEtapa}</option>)
                                            })}
                                        </Form.Select>
                                    </Col>
                                    <Col
                                        style={{ cursor: 'pointer', paddingLeft: 0, paddingTop: '1%' }}
                                        onClick={
                                            (event) => {
                                                console.log(event);
                                                setEditarEtapa("");
                                            }
                                        }
                                        className="col-2 text-left"
                                    >
                                        ❌
                                    </Col>

                                </Row>




                            </div>

                        </>
                        :
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={
                                (event) => {
                                    //Double click
                                    if (event.detail === 2) {

                                        setEditarEtapa(idLead);

                                    }

                                }
                            }
                        >

                            <SoftBadge

                                bg={
                                    idEtapa == 1 ? "secondary" :
                                        idEtapa == 2 ? "primary" :
                                            idEtapa == 3 ? "warning" :
                                                idEtapa == 4 ? "success" :
                                                    idEtapa == 5 ? "danger" : 
                                                        idEtapa == 6 ? "warning" :
                                                            idEtapa == 7 ? "success" : "success"}>
                                {vNombreEtapa}
                            </SoftBadge>

                        </span>

                )
            }
        }
    ];

    const columnsRepeatedLeads = [
        {
            accessor: 'name',
            Header: 'Nombre',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNombreLead, idLead } = rowData.row.original;
                return (
                    <>
                        <Row>
                            <Col className="col-12 fs--3">

                                <Link to={"#"} onClick={() => { setIdLead(idLead); setShowModal(true); }}>
                                    {"Lead " + idLead}
                                </Link>

                            </Col>
                        </Row>
                        <h6 className="mb-0">
                            <Link to={"#"} onClick={() => { setIdLead(idLead); setShowModal(true); }}>
                                <img title="Ver informaci&oacute;n" src="/img/ver20.png"></img > {vNombreLead}
                            </Link>
                        </h6>
                    </>
                );
            }
        },
        {
            accessor: 'telefono',
            Header: 'Tel\u00E9fono',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { vTelefonoLead } = rowData.row.original;
                return (
                    <>
                        &nbsp;&nbsp;
                        {vTelefonoLead.includes('+52') && <img title="M&eacute;xico" src="/img/bandera-mexico-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+1809') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+1829') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+1849') && <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+502') && <img title="Guatemala" src="/img/bandera-guatemala-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+503') && <img title="El Salvador" src="/img/bandera-el-salvador-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+504') && <img title="Honduras" src="/img/bandera-honduras-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+505') && <img title="Nicaragua" src="/img/bandera-nicaragua-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+506') && <img title="Costa Rica" src="/img/bandera-costa-rica-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+507') && <img title="Panamá" src="/img/bandera-panama-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+51') && <img title="Perú" src="/img/bandera-peru-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+53') && <img title="Cuba" src="/img/bandera-cuba-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+54') && <img title="Argentina" src="/img/bandera-argentina-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+56') && <img title="Chile " src="/img/bandera-chile-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+57') && <img title="Colombia " src="/img/bandera-colombia-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+58') && <img title="Venezuela " src="/img/bandera-venezuela-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+591') && <img title="Bolivia " src="/img/bandera-bolivia-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+593') && <img title="Ecuador " src="/img/bandera-ecuador-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+595') && <img title="Paraguay " src="/img/bandera-paraguay-circular-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+598') && <img title="Uruguay " src="/img/bandera-uruguay-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+1787') && <img title="Puerco Rico " src="/img/bandera-puerto-rico-48.png" width="20"></img>}
                        {vTelefonoLead.includes('+1939') && <img title="Puerco Rico " src="/img/bandera-puerto-rico-48.png" width="20"></img>}


                        <img width="25" src="/img/whatsapp40.png"></img>
                        <span className="fs-0">{vTelefonoLead}</span>

                    </>
                );
            }
        },
        {
            accessor: 'vNombreUsuario',
            Header: 'Vendedor',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { vNombreUsuario, dCreatedDate, iDaysDiff } = rowData.row.original;
                return (
                    <Row>
                        <Col className="col-12 text-dark fw-bold">{vNombreUsuario}</Col>
                        <Col className="col-12 text-muted">Registro: {new Date(dCreatedDate).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Col>
                        <Col className="col-12">
                            <SoftBadge
                                bg={
                                    iDaysDiff >= 0 && iDaysDiff < 3 ? "success" :
                                        iDaysDiff >= 3 && iDaysDiff < 5 ? "warning" : "danger"}
                            >
                                {iDaysDiff + " días sin actualizaciones"}
                            </SoftBadge>
                        </Col>
                    </Row>
                );
            }
        },

        {
            accessor: 'etapa',
            Header: 'Etapa',
            headerProps: { className: 'text-end white-space-nowrap' },
            cellProps: {
                className: 'font-sans-serif fs-0 text-end'
            },
            Cell: rowData => {
                const { idEtapa, vNombreEtapa } = rowData.row.original;
                return (
                    <SoftBadge bg={idEtapa == 1 ? "secondary" :
                        idEtapa == 2 ? "primary" :
                            idEtapa == 3 ? "warning" :
                                idEtapa == 4 ? "success" : "danger"}>{vNombreEtapa}</SoftBadge>
                )
            }
        }
    ];

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { token, setToken, idUsuario, nombreCompleto } = useToken();
    const [cargando, setCargando] = useState(true);
  
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    
    
    const [vendedores, setVendedores] = useState([]);

    const [editarEtapa, setEditarEtapa] = useState("");
    const [showEtapaNoInteresadoModal, setShowEtapaNoInteresadoModal] = useState(false);//state para cuando el cliente cambie a no interesado
    const [motivoNoInteresado, setMotivoNoInteresado] = useState('');//este estado guardara el motivo de porque no estuvo interesado

    //Filters
    const [ordenarPor, setOrdenarPor] = useState(0);
    const [vendedor, setVendedor] = useState(0);
    const [etapa, setEtapa] = useState(0);
    const [Filter_Tags, setFilter_Tags] = useState([]);
    const [Filter_NumTelNameLead, setFilter_NumTelNameLead] = useState('');

    const [tags, setTags] = useState([]);
    
    const [vNombreEtapa, setvNombreEtapa] = useState(null);

    const [proximoEvento, setProximoEvento] = useState([]);
    const [filtroEventos, setFiltroEventos] = useState(false);
    const [filtroEventosVencidos, setFiltroEventosVencidos] = useState(false);
    
    const [listaEventos, setListaEventos] = useState([]);

    const [contactos, setContactos] = useState([]);
    const [catEtapas, setCatEtapas] = useState([]);

    const [showModal, setShowModal] = useState(false);
    
    const [esExactamenteIgual, setEsExactamenteIgual] = useState(false);

    const [showModalSimilarLeads, setshowModalSimilarLeads] = useState(false);
    const [procesandoModalSimilarLeads, setProcesandoModalSimilarLeads] = useState(false);
    const [dataRepeatedLeads, setDataRepeatedLeads] = useState([]);
    const [telefonoRegistrar, setTelefonoRegistrar] = useState("");

    const [showModalEventosNoTerminados, setShowModalEventosNoTerminados] = useState(true);
    const [listaEventosPasados, setListaEventosPasados] = useState([]);
    const [listaEventosHoy, setListaEventosHoy] = useState([]);
    const [listaEventosFuturos, setListaEventosFuturos] = useState([]);
    const [cargandoModalEventos, setCargandoModalEventos] = useState(true);

    const [listaLeadsLiberados, setListaLeadsLiberados] = useState([]);
    const [lista3LeadsLiberados, setLista3LeadsLiberados] = useState([]);
    const [showModalLeadsLiberados, setShowModalLeadsLiberados] = useState(false);
    const [cargandoModalLeads, setCargandoModalLeads] = useState(false);
    const [habilitarBtnGuardar, setHabilitarBtnGuardar] = useState(true);
    const [idLead, setIdLead] = useState(0);

    const [showEtapaClienteGanadoModal, setShowEtapaClienteGanadoModal] = useState(false);


    const [listaJsonLeadsVinculados, setListaJsonLeadsVinculados] = useState([]);



    useEffect(() => {
        mostrarLeads(Filter_Tags, filtroEventosVencidos);
        //obtenerClientesAkasia();
        console.log("showModal desde contactos: ", showModal);

        if (showModalEventosNoTerminados)
            eventosNoTerminados();

    }, [vendedor, etapa, ordenarPor, filtroEventos]);

    
    /**
     * Retorna la información para mostrar los leads en la tabla
     */
    const mostrarLeads = async (_filterTags, _bFiltroEventosVencidos) => {

        setCargando(true);
        
        var idVendedor_ = sessionStorage.getItem("selectIDUsuario") == null ? (vendedor == 0) ? idUsuario : vendedor : sessionStorage.getItem("selectIDUsuario");
        setVendedor(idVendedor_);

        //console.log("vendedor: ",idVendedor_);

        var objDataSend = {};
        objDataSend.idVendedor = idVendedor_;
        objDataSend.idEtapa = etapa;
        objDataSend.jsonTags = _filterTags;
        objDataSend.vNumNameLead = Filter_NumTelNameLead;
        objDataSend.bFiltroEventos = filtroEventos;
        objDataSend.iOrdenarPor = ordenarPor;
        objDataSend.bFiltroEventosVencidos = _bFiltroEventosVencidos;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/leads/ObtenerCatalogoLeads/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jDataSend
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== MOSTRAR LEADS ====");
            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setVendedores(detalles['table2']);
                setCatEtapas(detalles['table3']);//categoria etapas
                setContactos(detalles['table4']);

                setTags(detalles['table5']);

                setProximoEvento(detalles['table6']);//lista de los proximos 3 eventos
                setListaEventos(detalles['table7']);//lista de todos los eventos

                setLista3LeadsLiberados(detalles['table8']);//lista de 3 leads liberados para mostrar al lado de los proximos eventos

                console.log("primeros 3 leads liberados: ", lista3LeadsLiberados);


            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    /**
     * Guarda el Lead
     * @param {any} data
     */
    const GuardarLead = async (data) => {
        setCargando(true);
        const response = await fetch("/api/leads/GuardarLead?vTelefonoLead=" + encodeURIComponent(data),
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response) {

            console.log("==== GUARDAR LEAD ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {
                setCargando(false);
                var objLead = JSON.parse(data.detalles);
                console.log("objLead: ",objLead);

                setMensajeInformativo(data.vMessage);
                setShowMensajeInformativo(true);

                setValue("vTelefonoLead", "");

                setIdLead(objLead[0].idLead);

                GuardarComentario("<b class='badge badge-soft-success' >Lead registrado por " + objLead[0].vVendedor + "</b>", 2, objLead[0].idLead);

                setShowModal(true);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

        setCargando(false);

    };

    /**
     * Verifica si el lead para agregar ya existe
     * @param {any} data
     */
    const VerificarLeadRepetido = async (data) => {

        setshowModalSimilarLeads(true);
        setProcesandoModalSimilarLeads(true);
        setEsExactamenteIgual(false);


        const response = await fetch("/api/leads/VerificarLeadRepetido?vTelefonoLead=" + encodeURIComponent(data.vTelefonoLead),
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {

            console.log("======== LEADS REPETIDOS ======== ");

            console.log(response);
            const data = await response.json();
            console.log(data);

            var objLeadsRepetidos = [];

            //Lo deja guardar
            if (data.iResponseType == 1) {

                let vTelefonoLead = data.vCode;
                GuardarLead(vTelefonoLead);
                setshowModalSimilarLeads(false);
                setProcesandoModalSimilarLeads(false);

            }

            //No lo deja guardar & muestra modal
            if (data.iResponseType == 4) {
                setEsExactamenteIgual(true);
                console.log(esExactamenteIgual);
                objLeadsRepetidos = JSON.parse(data.detalles);
                console.log(objLeadsRepetidos);

                setDataRepeatedLeads(objLeadsRepetidos);
                setTelefonoRegistrar(data.vCode);
            }


            //Muestra modal con las coincidencias & deja que el usuario elija si continuar o no.
            if (data.iResponseType == 5) {
                console.log(esExactamenteIgual);
                objLeadsRepetidos = JSON.parse(data.detalles);
                console.log(objLeadsRepetidos);

                setTelefonoRegistrar(data.vCode);
                setDataRepeatedLeads(objLeadsRepetidos);

            }

            //Esto significa que hubo un error
            if (data.iResponseType == 2) {

                setShowMensajeError(true);
                setMensajeError(data.vMensaje);

            }

            console.log("======== LEADS REPETIDOS ======== ");

        } else {

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

        setProcesandoModalSimilarLeads(false);

    };

    /**
     * Maneja el evento del filtro de eventos del día de hoy
     * @param {any} event
     */
    const handleCheckedFiltroEventos = (event) => {
        setFiltroEventos(event.target.checked);
        //mostrarLeads(Filter_Tags, event.target.checked, filtroEventosVencidos);

        event.preventDefault();
        event.stopPropagation();
        //console.log(event.target.checked);

    }

    /**
     * Maneja el evento de filtros vencidos
     * @param {any} event
     */
    const handleCheckedFiltroEventosVencidos = (event) => {
        setFiltroEventosVencidos(event.target.checked);
        mostrarLeads(Filter_Tags, event.target.checked);

        event.preventDefault();
        event.stopPropagation();
        //console.log(event.target.checked);

    }

    /**
     * Maneja la etapa de no interesado
     * @param {any} idLead
     */
    const etapaNoInteresado = async (idLead) => {

        if (motivoNoInteresado.length > 5) {

            setHabilitarBtnGuardar(false);

            var jDataSend = {};

            jDataSend.idEtapa = 5;
            jDataSend.idLead = idLead;

            const response = await fetch("/api/leads/changeEtapaLead/",
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jDataSend)
                }).catch((error) => {

                    setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                    setShowMensajeError(true);
                    console.log('Hubo un problema con la peticion Fetch:' + error);
                });

            if (response.ok) {

                console.log("==== CHANGE ETAPA LEAD CONTACTOS ====");
                console.log(response);
                const data = await response.json();
                console.log(data);

                if (data.iResponseType == 1) {

                    var detalles = JSON.parse(data.detalles);
                    console.log(detalles);

                    GuardarComentario("<b class='badge badge-soft-danger'>Lead no interesado </b> " + motivoNoInteresado, 2, idLead);

                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                    console.log("currentLeadIndex: ", currentLeadIndex);

                    contactos[currentLeadIndex].iDaysDiff = 0;
                    contactos[currentLeadIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                    contactos[currentLeadIndex].idEtapa = detalles[0].idEtapa;

                    setEditarEtapa("");
                    setMotivoNoInteresado("");
                    setMensajeInformativo("Cambio de etapa exitoso");
                    setShowMensajeInformativo(true);
                    setShowEtapaNoInteresadoModal(false);

                    //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead a no interesado.
                    if (idUsuario != vendedor) {

                        //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                        var objToActionString = crearObjAction(1, '', 1, contactos[currentLeadIndex].idLead);
                        guardarNotificacion("Etapa de lead cambiada", "<b> " + nombreCompleto + "</b> modificó la etapa del lead <b>#" + contactos[currentLeadIndex].idLead + "</b> a: <i> 'No intere...'</i>", vendedor, objToActionString);
                    }


                } else {
                    setHabilitarBtnGuardar(true);
                    setShowMensajeError(true);
                    setMensajeError(data.vMessage);
                }



            }
            else {
                setHabilitarBtnGuardar(true);
                setShowMensajeError(true);
                setMensajeError(response.status + ' ' + response.statusText);
                if (response.status == 401) {
                    setToken(null);
                    location.href = "";
                }
                console.log(response);
            }
        } else {

            setMensajeError('Es obligatorio poner un motivo de no interesado');
            setShowMensajeError(true);

        }

    }

    /**
     * Muestra el modal de eventos no terminados
     */
    const eventosNoTerminados = async () => {

        setCargandoModalEventos(true);
        setShowModalEventosNoTerminados(true);

        var idVendedor_ = sessionStorage.getItem("selectIDUsuario") == null ? (vendedor == 0) ? idUsuario : vendedor : sessionStorage.getItem("selectIDUsuario");
        setVendedor(idVendedor_);

        var objDataSend = {};
        objDataSend.idVendedor = idVendedor_;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/leads/eventosPorVendedor/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jDataSend
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== MOSTRAR EVENTOS ====");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setListaEventosPasados(detalles['table2']);
                setListaEventosHoy(detalles['table3']);
                setListaEventosFuturos(detalles['table4']);
                setCargandoModalEventos(false);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    /**
     * muestra el modal de los leads liberados
     */
    const leadsLiberados = async () => {

        setShowModalLeadsLiberados(true);
        setCargandoModalLeads(true);

        const response = await fetch("/api/leads/obtenerLeadsLiberados/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== MOSTRAR LEADS LIBERADOS ====");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setListaLeadsLiberados(detalles['table2']);
                setCargandoModalLeads(false);

            } else {
                setCargandoModalLeads(false);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        } else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    /**
     * Guarda los comentarios
     * @param {any} vComentario
     * @param {any} iTipoCommentario
     * @param {any} _idLead
     */
    const GuardarComentario = async (vComentario, iTipoCommentario, _idLead) => {

        //setProcesandoModal(true);


        const response = await fetch("/api/leads/RegistrarComentario?idLead=" + _idLead +
            "&vComentario=" + encodeURIComponent(vComentario) + "&idTipoComentario=" + encodeURIComponent(iTipoCommentario), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            console.log(" ==== Registrar comentarios ==== ");
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {


                var ObjDetalles = JSON.parse(data.detalles);
                console.log(ObjDetalles);
            } else {

                setShowMensajeError(true);
                setMensajeError(data.vMessage);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);

            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    }

    /**
     * Crea un objeto de acción que especifica la acción a realizar al hacer clic en una notificación,
     * ya sea abrir un modal o redireccionar a otra página.
     * @param {any} TypeAction
     * @param {any} vURL
     * @param {any} idModalType
     * @param {any} idLead
     * @returns
     */
    const crearObjAction = (TypeAction, vURL, idModalType, idLead,) => {
        var objToAction = {};
        var objDetalles = {};
        var objData = {};

        objData.idLead = idLead;

        objDetalles.vUrl = vURL;
        objDetalles.idModalType = idModalType;
        objDetalles.data = objData

        objToAction.TypeAction = TypeAction;
        objToAction.detalles = objDetalles;

        var objToActionString = JSON.stringify(objToAction);
        console.log("objToAction: ", objToActionString);
        return objToActionString;
    };

    /**
     * Guarda una notificación
     */
    const guardarNotificacion = async (vTitulo, vCuerpo, idUsuarioTarget, objToAction) => {

        var jDataSend = {};
        jDataSend.vTitulo = vTitulo;
        jDataSend.vCuerpo = vCuerpo;
        jDataSend.idUsuarioTarget = idUsuarioTarget;
        jDataSend.objToAction = objToAction;

        const response = await fetch("/api/leads/guardarNotificacion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACIÓN GUARDADA ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);
            console.log("objToAction: ", objToAction);
            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var notificacionesList = detalles.table2;
                console.log("notificacionesList: ", notificacionesList);

                notificacionesList.map((objNotify) => {
                    sendPushNotificationRealTime(objNotify.vToken, objNotify.vCuerpo);
                })


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    };

    /**
     * Envia una notificacion push al usuario con el token que recibe como parametro
     * @param {any} token
     * @param {any} title
     * @param {any} body
     */
    const sendPushNotificationRealTime = async (vToken, vNotificacion) => {


        try {
            const response = await fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer AAAAmo0KFik:APA91bHXQQ21frvYbXVi_R73j9riLL3r1OxN9__xmYHkY_WjWxENgDgQnqwMMVdyNf8wywyFJGnShNNMrioJdgPDqyEWoW1GgT08sC3Kfg47SR-UFdBNM95t-npZ93s-3fJNtWvRjuTk',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    to: vToken,
                    data: { vNotificacion: vNotificacion }
                })
            });

            const data = await response.json();
            console.log('Notificación enviada:', data);

        } catch (error) {
            console.error('Error al enviar la notificación:', error.message);
        }
    };

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/cliente96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Cat&aacute;logo de contactos / Leads
                        </h5>
                    </Col>
                </Row>
                <Row>
                    {
                        cargando ?
                            <>
                                <Skeleton />
                            </>
                            :
                            <>
                                <DailyStatistics
                                    vendedor={vendedor}
                                    setMensajeError={setMensajeError}
                                    setShowMensajeError={setShowMensajeError}
                                />
                            </>
                    }
                    
                </Row>
            </Card>
            <Card className="mb-1">
                <Row className="m-2">{/*Próximos eventos y leads liberados*/}
                    <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Row>
                            <Col className="col-12">
                                <Row>
                                    <Col className="col-12 fs--1">
                                        {
                                            cargando ?
                                                <>
                                                    <Skeleton />
                                                </>
                                                :
                                                <>
                                                    <label className="muted">Eventos próximos: &nbsp;</label>
                                                    <Link to={"#"} onClick={() => { eventosNoTerminados(); }}>{"Ver eventos"}</Link>
                                                </>
                                        }

                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        cargando ?
                                            <>
                                                <Row>
                                                    <Col className="col-6">
                                                        <Skeleton height={100} />
                                                    </Col>

                                                    <Col className="col-6">
                                                        <Skeleton height={100} />
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                {
                                                    proximoEvento.length > 0 ?
                                                        proximoEvento.map((event, index) => (
                                                            <Col key={index} className="h-100 mt-2 fs--2 border-end col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                                                <Link to={"#"} onClick={() => { setIdLead(event.idLead); setShowModal(true); }}>

                                                                    <Row>
                                                                        <Col className="col-12">
                                                                            {"Lead " + event.idLead + " - " + event.vNombreLead}
                                                                        </Col>

                                                                    </Row>
                                                                </Link>

                                                                <Row className="justify-content-left">

                                                                    <Col className="col-3 col-xxl-3 col-xl-4 col-lg-4 col-md-3 col-sm-4 align-item-center align-self-center">
                                                                        <Card className="m-0 p-0 align-self-center">

                                                                            <Card.Header className="m-0 p-0 text-center text-light fw-bold" style={{ backgroundColor: "#e63757", fontSize: "0.8em" }}>
                                                                                {

                                                                                    new Date(event.dFechaEvento).getMonth() == 0 ? "ENE" :
                                                                                        new Date(event.dFechaEvento).getMonth() == 1 ? "FEB" :
                                                                                            new Date(event.dFechaEvento).getMonth() == 2 ? "MAR" :
                                                                                                new Date(event.dFechaEvento).getMonth() == 3 ? "ABR" :
                                                                                                    new Date(event.dFechaEvento).getMonth() == 4 ? "MAY" :
                                                                                                        new Date(event.dFechaEvento).getMonth() == 5 ? "JUN" :
                                                                                                            new Date(event.dFechaEvento).getMonth() == 6 ? "JUL" :
                                                                                                                new Date(event.dFechaEvento).getMonth() == 7 ? "AGO" :
                                                                                                                    new Date(event.dFechaEvento).getMonth() == 8 ? "SEP" :
                                                                                                                        new Date(event.dFechaEvento).getMonth() == 9 ? "OCT" :
                                                                                                                            new Date(event.dFechaEvento).getMonth() == 10 ? "NOV" :
                                                                                                                                new Date(event.dFechaEvento).getMonth() == 11 ? "DIC" : ''}
                                                                            </Card.Header>
                                                                            <Card.Body className="m-0 p-0 text-center border border-danger fw-bold text-muted" style={{ fontSize: "1.2em" }}>
                                                                                {new Date(event.dFechaEvento).getDate()}
                                                                            </Card.Body>

                                                                        </Card>

                                                                    </Col>

                                                                    <Col className="col-9 col-xxl-9 col-xl-8 col-lg-8 col-md-6 col-sm-5 p-0">

                                                                        <Row>
                                                                            <Col className="col-12 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-center col">
                                                                                <span className="fw-bold fs--2">
                                                                                    <Link to="#" onClick={() => { setIdLead(event.idLead); setShowModal(true); }}>
                                                                                        <span className="">{event.vTituloEvento}</span>
                                                                                    </Link>
                                                                                </span>
                                                                            </Col>

                                                                        </Row>


                                                                    </Col>

                                                                    <Col className="col-12 mt-1">
                                                                        <img width={20} src="/img/reloj48.png"></img>
                                                                        {
                                                                            new Date(event.dFechaEvento).toLocaleTimeString(
                                                                                'es-mx',
                                                                                {
                                                                                    hour12: true
                                                                                }
                                                                            )
                                                                        }

                                                                        <SoftBadge
                                                                            className={"ms-1"}

                                                                            bg={event.minLeft > 10 && event.minLeft < 20 ? "warning" : event.minLeft > 0 && event.minLeft <= 10 ? "danger" : "success"}
                                                                        >
                                                                            {"Faltan " + event.minLeft + " mins"}

                                                                        </SoftBadge>

                                                                    </Col>


                                                                </Row>

                                                                <br />
                                                            </Col>
                                                        ))
                                                        :
                                                        <>
                                                            <label className="fw-bold text-danger" > Ningún próximo evento programado </label>
                                                        </>
                                                }
                                            </>


                                    }

                                </Row>

                            </Col>

                        </Row>

                    </Col>
                    <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                        <Row>
                            <Col className="col-12 ms-2">
                                <Row>
                                    <Col className="col-12 fs--1">
                                        {
                                            cargando ?
                                                <>
                                                    <Skeleton />
                                                </>
                                                :
                                                <>
                                                    <label className="muted">Leads Liberados: &nbsp;</label>
                                                    <Link to={"#"} onClick={() => leadsLiberados()}>{"Ver Leads Liberados"}</Link>
                                                </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            cargando ?
                                                <>
                                                    <Row>
                                                        <Col className="col-4">
                                                            <Skeleton height={100} />
                                                        </Col>

                                                        <Col className="col-4">
                                                            <Skeleton height={100} />
                                                        </Col>

                                                        <Col className="col-4">
                                                            <Skeleton height={100} />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        {
                                                            lista3LeadsLiberados.length > 0 ?
                                                                lista3LeadsLiberados.map(
                                                                    (lead, index) => {
                                                                        var objTag = JSON.parse(lead.vJsonTags);
                                                                        return (
                                                                            <Col key={index} className="h-100 mt-2 fs--2 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">
                                                                                <Link className="m-0" to={"#"} onClick={() => { setIdLead(lead.idLead); setShowModal(true); }}>
                                                                                    <Row>

                                                                                        <Col className="col-12 text-900 fw-semi-bold">
                                                                                            <img width="25" src="/img/cliente48.png"></img>&nbsp;
                                                                                            {"Lead " + lead.idLead}
                                                                                        </Col>
                                                                                        <Col className="col-12 fw-medium text-600">
                                                                                            {lead.vNombreLead.length > 13 ? lead.vNombreLead.substring(0, 13) + "..." : lead.vNombreLead.length > 0 ? lead.vNombreLead : "Lead #" + lead.idLead}
                                                                                        </Col>
                                                                                        <Col className="col-12">
                                                                                            <SoftBadge pill bg={
                                                                                                lead.idEtapa == 1 ? "secondary" :
                                                                                                    lead.idEtapa == 2 ? "primary" :
                                                                                                        lead.idEtapa == 3 ? "warning" :
                                                                                                            lead.idEtapa == 4 ? "success":
                                                                                                                lead.idEtapa == 5 ? "danger" :
                                                                                                                    lead.idEtapa == 6 ? "warning" :
                                                                                                                        lead.idEtapa == 7 ? "success" : "success"
                                                                                            }
                                                                                                className="mt-1"
                                                                                            >{lead.vNombreEtapa}

                                                                                            </SoftBadge>
                                                                                        </Col>
                                                                                        <Col className="col-12">
                                                                                            <SoftBadge
                                                                                                bg={
                                                                                                    lead.iDaysDiff >= 0 && lead.iDaysDiff < 3 ? "success" :
                                                                                                        lead.iDaysDiff >= 3 && lead.iDaysDiff < 5 ? "warning" : "danger"}
                                                                                            >
                                                                                                {lead.iDaysDiff + " días sin actualizaciones"}
                                                                                            </SoftBadge>
                                                                                        </Col>
                                                                                        <Col className="col-12">
                                                                                            {

                                                                                                <span>
                                                                                                    {objTag.map(
                                                                                                        (tag, index) => {

                                                                                                            const objDDLTag = tags.find(t => t.idLeadTag == tag.idLeadTag);

                                                                                                            return (
                                                                                                                <OverlayTrigger key={index} placement="left" overlay={<Tooltip id="tooltip"><strong>{objDDLTag.vNombreTag}</strong></Tooltip>}>
                                                                                                                    <span style={{ cursor: 'pointer' }}>
                                                                                                                        <SoftBadge
                                                                                                                            className={"me-1 bgTag_" + index}
                                                                                                                            bg={objDDLTag.colorActive}

                                                                                                                        >
                                                                                                                            {objDDLTag.vAbreviacionTag}
                                                                                                                        </SoftBadge>
                                                                                                                    </span >
                                                                                                                </OverlayTrigger>




                                                                                                            )
                                                                                                        }
                                                                                                    )
                                                                                                    }

                                                                                                </span>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Link>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                )

                                                                :
                                                                <>
                                                                    <label className="fw-bold text-danger" > Ningún Lead liberado disponible </label>
                                                                </>
                                                        }
                                                    </Row>
                                                </>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr></hr>
                <Row className="m-2">{/*Filtros de busqueda*/}

                    <Col className="col-12">
                        <Row>
                            <Col className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="mb-2 mt-n2">
                                    <Form.Label className="mb-1 fs--1">Vendedor</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={vendedor}
                                        onChange={(event) => { sessionStorage.setItem("selectIDUsuario", event.target.value); setVendedor(event.target.value); }}
                                    >
                                        <option value="">Todos los vendedores</option>
                                        {vendedores.map((vendedor, index) => {
                                            return (<option key={index} value={vendedor.idUsuario}>{vendedor.vNombre + " " + vendedor.vApellidos}</option>)
                                        })}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="mb-2 mt-n2">
                                    <Form.Label className="mb-1 fs--1">Etapa</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={etapa}
                                        onChange={(event) => { setEtapa(event.target.value); }}
                                    >
                                        <option value="">Todas las etapas</option>
                                        {catEtapas.map((etapa, index) => {
                                            return (<option key={index} value={etapa.idEtapa}>{etapa.vNombreEtapa}</option>)
                                        })}
                                    </Form.Select>
                                </div>
                            </Col>

                            <Col className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="mb-2 mt-n2">
                                    <Form.Label className="mb-1 fs--1">Ordenar Por:</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={ordenarPor}
                                        onChange={(event) => { setOrdenarPor(event.target.value); }}
                                    >
                                        <option value="0">Números de leads Desc.</option>
                                        <option value="1">Números de leads Asc.</option>
                                        <option value="2">Dias sin actualizar Desc.</option>
                                        <option value="3">Dias sin actualizar Asc.</option>



                                    </Form.Select>
                                </div>
                            </Col>

                            <Col className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="mb-2 mt-n2">
                                    <Form.Label className="mb-1 fs--1">Selecciona las tags que desees filtrar</Form.Label><br />
                                    {
                                        tags.map(
                                            (tag, index) => {
                                                //console.log(Filter_Tags);
                                                if (tag.bIsActive == 1) {
                                                    const objDDLTag = Filter_Tags.find(t => t.idLeadTag == tag.idLeadTag);


                                                    return (
                                                        <OverlayTrigger key={index} placement="bottom" overlay={<Tooltip id="tooltip"><strong>{tag.vNombreTag}</strong></Tooltip>}>
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={
                                                                    () => {

                                                                        //const [Filter_Tags, setFilter_Tags] = useState([]);
                                                                        var _filterTags = Filter_Tags;
                                                                        if (objDDLTag == null) {
                                                                            setFilter_Tags([...Filter_Tags, tag]);
                                                                            _filterTags.push(tag);
                                                                        } else {
                                                                            const arr = Filter_Tags.filter((item) => item.idLeadTag !== tag.idLeadTag);
                                                                            setFilter_Tags(arr);

                                                                            _filterTags = arr
                                                                        }
                                                                        //var _filterTags = Filter_Tags;
                                                                        mostrarLeads(_filterTags, filtroEventosVencidos);

                                                                    }
                                                                }

                                                            >


                                                                <SoftBadge
                                                                    className={(objDDLTag == null) ? "me-1 border border-dark" : "me-1"}
                                                                    //bg={(Filter_Tags.length == 0) ? tag.colorActive : (objDDLTag == null) ? "light" : objDDLTag.colorActive}
                                                                    bg={(objDDLTag == null) ? "light" : objDDLTag.colorActive}
                                                                >
                                                                    {tag.vAbreviacionTag}
                                                                </SoftBadge>


                                                            </span >
                                                        </OverlayTrigger>

                                                    )

                                                }


                                            }
                                        )
                                    }
                                </div>
                            </Col>

                        </Row>

                        <hr />


                    </Col>
                    <Col xs={12} md={5} lg={4}>
                        <span>#Tel. del contacto / Lead: </span>

                        <Form noValidate onSubmit={handleSubmit(VerificarLeadRepetido)}>
                            <InputGroup className="fs--1 align-items-center">

                                <Form.Control
                                    type="text"
                                    size="sm"
                                    disabled={cargando ? true : false}
                                    autoComplete="off"
                                    isInvalid={!!errors.vTelefonoLead}
                                    {...register("vTelefonoLead",
                                        {
                                            required: 'Este campo es requerido, incluya el c\u00f3digo de pais: ejemplo: +52',
                                            maxLength: 20,
                                            minLength: 10,
                                            pattern: {
                                                value: /^[+][ 0-9()-]+$/,
                                                message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                            }
                                        })
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.vTelefonoLead && errors.vTelefonoLead.message}
                                </Form.Control.Feedback>

                                <Button
                                    disabled={cargando ? true : false}
                                    className="btn btn-primary"
                                    size="sm"
                                    type="submit"

                                >
                                    Continuar
                                </Button>

                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Card>
            {cargando ?
                (<h5><Spinner className="text-inline mt-3" animation="border" variant="primary" /> Cargando...</h5>)
                :
                <>
                    <Row className="gx-3">
                        <Col>
                            <AdvanceTableWrapper
                                columns={columns}
                                data={contactos}

                                selectionColumnWidth={52}
                                sortable
                                pagination
                                rowCount={contactos.length}
                            >
                                <Card>
                                    <Card.Header className="px-0">


                                        <Row className="ms-1 me-1">


                                            <Col className="col-xxl-4 col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                                                <label className="text-muted ">Número de teléfono / Nombre Lead</label>
                                                <InputGroup className="fs--1 align-items-center">

                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        disabled={cargando ? true : false}
                                                        autoComplete="off"
                                                        value={Filter_NumTelNameLead}
                                                        onChange={
                                                            (e) => {
                                                                setFilter_NumTelNameLead(e.target.value);
                                                            }
                                                        }
                                                        onKeyDown={
                                                            (e) => {

                                                                if (e.key === 'Enter') {
                                                                    mostrarLeads(Filter_Tags, filtroEventosVencidos);
                                                                }

                                                                // console.log(e);

                                                            }
                                                        }
                                                    />


                                                    <Button
                                                        disabled={cargando ? true : false}
                                                        className="btn btn-primary"
                                                        size="sm"
                                                        type="submit"
                                                        onClick={
                                                            () => {
                                                                mostrarLeads(Filter_Tags, filtroEventosVencidos);
                                                            }
                                                        }

                                                    >
                                                        Filtrar
                                                    </Button>

                                                </InputGroup>

                                            </Col>
                                            <Col className="col-xxl-4 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12" >
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="text-muted" >Mostrar leads con eventos el dia de hoy</Form.Label>
                                                    <Row>
                                                        <Col md={2}>
                                                            <Form.Check // prettier-ignore
                                                                checked={filtroEventos}
                                                                type="switch"
                                                                onChange={(event) => {
                                                                    handleCheckedFiltroEventos(event); // Llama al manejador primero

                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>


                                                </Form.Group>
                                            </Col>
                                            <Col className="col-xxl-4 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="text-muted" >Mostrar leads con eventos vencidos</Form.Label>
                                                    <Row>
                                                        <Col md={2}>
                                                            <Form.Check
                                                                checked={filtroEventosVencidos}
                                                                type="switch"
                                                                onChange={(event) => {
                                                                    handleCheckedFiltroEventosVencidos(event);

                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>


                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-light text-800 align-middle"
                                            rowClassName="btn-reveal-trigger align-middle"
                                            tableProps={{
                                                size: 'sm',
                                                className: 'fs--2 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </Card.Body>
                                    <Card.Footer>
                                        <AdvanceTableFooter
                                            rowCount={contactos.length}
                                            table
                                            rowInfo
                                            rowsPerPageSelection
                                            navButtons
                                        />
                                    </Card.Footer>
                                </Card>
                            </AdvanceTableWrapper>
                        </Col>
                    </Row>
                </>
            }


            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />


            <ModalSeguimientoLead
                showModal={showModal}
                setShowModal={setShowModal}
                catEtapas={catEtapas}
                tags={tags}
                contactos={contactos}
                setContactos={setContactos}
                lista3LeadsLiberados={lista3LeadsLiberados}
                vendedor={vendedor}
                listaEventos={listaEventos}
                setListaEventos={setListaEventos}
                setProximoEvento={setProximoEvento}
                proximoEvento={proximoEvento}
                listaEventosPasados={listaEventosPasados}
                setListaEventosPasados={setListaEventosPasados}
                listaEventosHoy={listaEventosHoy}
                setListaEventosHoy={setListaEventosHoy}
                listaEventosFuturos={listaEventosFuturos}
                setListaEventosFuturos={setListaEventosFuturos}
                listaLeadsLiberados={listaLeadsLiberados}
                setListaLeadsLiberados={setListaLeadsLiberados}
                mostrarLeads={mostrarLeads}
                Filter_Tags={Filter_Tags}
                filtroEventosVencidos={filtroEventosVencidos}
                setMensajeError={setMensajeError}
                setShowMensajeError={setShowMensajeError}
                setMensajeInformativo={setMensajeInformativo}
                setShowMensajeInformativo={setShowMensajeInformativo}
                setEditarEtapa={setEditarEtapa}
                idLead={idLead}
                setIdLead={setIdLead}
                vendedores={vendedores}
            />
            

            { // Modal para mostrar los numeros con coincidencias
                showModalSimilarLeads &&
                <Modal
                    show={showModalSimilarLeads}
                    onHide={() => setshowModalSimilarLeads(false)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Header>
                        <Modal.Title>N&uacute;mero con coincidencias importantes {telefonoRegistrar}</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setshowModalSimilarLeads(false); }} />

                    </Modal.Header>
                    <Modal.Body>

                        {
                            procesandoModalSimilarLeads ? <Skeleton className="col-12" height={150} /> :
                                <AdvanceTableWrapper
                                    columns={columnsRepeatedLeads}
                                    data={dataRepeatedLeads}

                                    selectionColumnWidth={52}
                                    sortable
                                    pagination
                                    perPage={50}
                                    rowCount={dataRepeatedLeads.length}
                                >
                                    <Card>
                                        <Card.Header className="px-0">
                                            <div className="d-lg-flex ">
                                                <Row className="flex-between-center gy-2 px-x1">

                                                    <Col xs="ms-auto">
                                                        {!cargando &&
                                                            <AdvanceTableSearchBox table />
                                                        }

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="p-0">
                                            <AdvanceTable
                                                table
                                                headerClassName="bg-light text-800 align-middle"
                                                rowClassName="btn-reveal-trigger align-middle"
                                                tableProps={{
                                                    size: 'sm',
                                                    className: 'fs--2 mb-0 overflow-hidden'
                                                }}
                                            />
                                        </Card.Body>
                                        <Card.Footer>
                                            <AdvanceTableFooter
                                                rowCount={dataRepeatedLeads.length}
                                                table
                                                rowInfo
                                                navButtons
                                                rowsPerPageSelection
                                            />
                                        </Card.Footer>
                                    </Card>
                                </AdvanceTableWrapper>


                        }




                    </Modal.Body>
                    <Modal.Footer>
                        {esExactamenteIgual ?
                            <div>
                            </div>
                            :
                                (procesandoModalSimilarLeads ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                                :
                                <Button onClick={() => {
                                    GuardarLead(telefonoRegistrar); setshowModalSimilarLeads(false);
                                }} variant='primary' >Guardar igualmente</Button>
                            )
                        }

                        {
                            procesandoModalSimilarLeads ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                                :
                                <>
                                    <Button onClick={() => { setshowModalSimilarLeads(false); }} variant='danger' >Cerrar</Button>
                                </>
                        }
                    </Modal.Footer>

                </Modal>
            }

            {/* Modal para escribir un comentario de porque un cliente no esta interesado*/}
            <Modal
                show={showEtapaNoInteresadoModal}
                onHide={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">¿Por qué no estuvo interesado el Lead?</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={(event) => setMotivoNoInteresado(event.target.value)}
                            value={motivoNoInteresado}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}>Cancelar</Button>


                    {
                        habilitarBtnGuardar ?
                            <Button variant='success' onClick={() => { etapaNoInteresado(idLead); }}>Guardar Motivo</Button>
                            :
                            <>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                            </>
                    }

                </Modal.Footer>
            </Modal>

            {
                showModalEventosNoTerminados ?
                    <>
                        <Modal
                            show={showModalEventosNoTerminados}
                            onHide={() => { setShowModalEventosNoTerminados(false); }}
                            size="xl"

                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Background image={corner2} className="p-x1" style={{ zIndex: 0 }} />

                            <Modal.Header>

                                {
                                    <Modal.Title>
                                        <img src={circle} height="50" ></img> Eventos
                                    </Modal.Title>
                                }

                                <FalconCloseButton style={{ zIndex: 1 }}
                                    className="btn btn-circle btn-sm transition-base p-0"
                                    onClick={() => { setShowModalEventosNoTerminados(false); }} />
                            </Modal.Header>
                            <Modal.Body>

                                <Row>

                                    <Col className="ms-1">
                                        <Row>

                                            <Col className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

                                                <Row className="me-1">
                                                    <Col className="col-10 border-bottom m-2 border-3 border-danger h-100 mt-2 ms-1">
                                                        <h5>Vencidos</h5>
                                                    </Col>
                                                    {
                                                        cargandoModalEventos ?
                                                            <>
                                                                <Row>
                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>

                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            listaEventosPasados.length > 0 ?
                                                                <>
                                                                    {
                                                                        listaEventosPasados.map((evento, index) => {

                                                                            var newDate_ = new Date(evento.dFechaEvento).toLocaleDateString('es-MX', {
                                                                                year: 'numeric', month: 'short', day: '2-digit',
                                                                                hour: '2-digit', minute: '2-digit', hour12: true
                                                                            });

                                                                            newDate_ = newDate_.replace(",", "");
                                                                            evento.dFechaEventoReal = newDate_;
                                                                            
                                                                            return (
                                                                                
                                                                                <Col key={index} className="h-100 mb-1 fs--2 border-start border-2 border-danger col-12">

                                                                                        <Link to={"#"} onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>

                                                                                            <Row>

                                                                                                {
                                                                                                    evento.vNombreLead.length <= 20 ?
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead + " - " + evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead}
                                                                                                            </Col>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                }

                                                                                            </Row>
                                                                                        </Link>

                                                                                        <Row className="justify-content-center">

                                                                                            <Col className="col-3 col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-2 align-item-center align-self-center border-1">
                                                                                                <Card className="m-0 p-0 align-self-center">

                                                                                                    <Card.Header className="m-0 p-0 text-center text-light fw-bold" style={{ backgroundColor: "#e63757", fontSize: "0.8em" }}>
                                                                                                        {

                                                                                                            new Date(evento.dFechaEvento).getMonth() == 0 ? "ENE" :
                                                                                                                new Date(evento.dFechaEvento).getMonth() == 1 ? "FEB" :
                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 2 ? "MAR" :
                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 3 ? "ABR" :
                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 4 ? "MAY" :
                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 5 ? "JUN" :
                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 6 ? "JUL" :
                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 7 ? "AGO" :
                                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 8 ? "SEP" :
                                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 9 ? "OCT" :
                                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 10 ? "NOV" :
                                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 11 ? "DIC" : ''}
                                                                                                    </Card.Header>
                                                                                                    <Card.Body className="m-0 p-0 text-center border border-danger fw-bold text-muted" style={{ fontSize: "1.2em" }}>
                                                                                                        {new Date(evento.dFechaEvento).getDate()}
                                                                                                    </Card.Body>

                                                                                                </Card>

                                                                                            </Col>

                                                                                            <Col className="col-9 col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-10 p-0">

                                                                                                <Row>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <span className="fw-bold fs--2">
                                                                                                            <Link to="#" onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>
                                                                                                                <span className="">{evento.vTituloEvento}</span>
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                    </Col>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <img width={20} src="/img/reloj48.png"></img>
                                                                                                        {
                                                                                                            new Date(evento.dFechaEvento).toLocaleTimeString(
                                                                                                                'es-mx',
                                                                                                                {
                                                                                                                    hour12: true
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                    </Col>

                                                                                                </Row>

                                                                                            </Col>



                                                                                        </Row>

                                                                                        <br />
                                                                                    </Col>
 
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <Alert className="ms-1 col-10 fs--1" variant="secondary">
                                                                        No hay eventos <b className="text-danger" >vencidos</b>
                                                                    </Alert>
                                                                </>

                                                    }
                                                </Row>

                                            </Col>

                                            <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                                <Row className="me-1">
                                                    <Col className="col-10 border-bottom m-2 border-3 border-primary h-100 mt-2 ms-1">
                                                        <h5>Hoy</h5>
                                                    </Col>
                                                    {
                                                        cargandoModalEventos ?
                                                            <>
                                                                <Row>
                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>

                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            listaEventosHoy.length > 0 ?
                                                                <>

                                                                    {
                                                                        listaEventosHoy.map((evento, index) => {
                                                                            var newDate_ = new Date(evento.dFechaEvento).toLocaleDateString('es-MX', {
                                                                                year: 'numeric', month: 'short', day: '2-digit',
                                                                                hour: '2-digit', minute: '2-digit', hour12: true
                                                                            });

                                                                            newDate_ = newDate_.replace(",", "");
                                                                            evento.dFechaEventoReal = newDate_;

                                                                            return (
                                                                                
                                                                                <Col key={index} className="mb-2 h-100 fs--2 border-start border-2 border-primary col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                                                                        <Link to={"#"} onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>

                                                                                            <Row>

                                                                                                {
                                                                                                    evento.vNombreLead.length <= 20 ?
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead + " - " + evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead}
                                                                                                            </Col>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                }

                                                                                            </Row>
                                                                                        </Link>

                                                                                        <Row className="justify-content-center">

                                                                                            <Col className="col-3 col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-2 align-item-center align-self-center border-1">
                                                                                                <Card className="m-0 p-0 align-self-center">

                                                                                                    <Card.Header className="m-0 p-0 text-center text-light fw-bold" style={{ backgroundColor: "#e63757", fontSize: "0.8em" }}>
                                                                                                        {

                                                                                                            new Date(evento.dFechaEvento).getMonth() == 0 ? "ENE" :
                                                                                                                new Date(evento.dFechaEvento).getMonth() == 1 ? "FEB" :
                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 2 ? "MAR" :
                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 3 ? "ABR" :
                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 4 ? "MAY" :
                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 5 ? "JUN" :
                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 6 ? "JUL" :
                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 7 ? "AGO" :
                                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 8 ? "SEP" :
                                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 9 ? "OCT" :
                                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 10 ? "NOV" :
                                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 11 ? "DIC" : ''}
                                                                                                    </Card.Header>
                                                                                                    <Card.Body className="m-0 p-0 text-center border border-danger fw-bold text-muted" style={{ fontSize: "1.2em" }}>
                                                                                                        {new Date(evento.dFechaEvento).getDate()}
                                                                                                    </Card.Body>

                                                                                                </Card>

                                                                                            </Col>

                                                                                            <Col className="col-9 col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-10 p-0">

                                                                                                <Row>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <span className="fw-bold fs--2">
                                                                                                            <Link to="#" onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>
                                                                                                                <span className="">{evento.vTituloEvento}</span>
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                    </Col>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <img width={20} src="/img/reloj48.png"></img>
                                                                                                        {
                                                                                                            new Date(evento.dFechaEvento).toLocaleTimeString(
                                                                                                                'es-mx',
                                                                                                                {
                                                                                                                    hour12: true
                                                                                                                }
                                                                                                            )
                                                                                                        }

                                                                                                        {
                                                                                                            evento.bVencido ?
                                                                                                                <>
                                                                                                                    <SoftBadge
                                                                                                                        bg={"danger"}
                                                                                                                    >
                                                                                                                        {"Vencido"}
                                                                                                                    </SoftBadge>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>

                                                                                                                </>
                                                                                                        }


                                                                                                    </Col>

                                                                                                </Row>

                                                                                            </Col>



                                                                                        </Row>

                                                                                        <br />
                                                                                    </Col>

                                                                            )
                                                                        })
                                                                    }

                                                                </>
                                                                :
                                                                <>
                                                                    <Alert className="ms-1 col-10" variant="secondary">
                                                                        No hay eventos <b className="text-primary" >Para hoy</b>
                                                                    </Alert>
                                                                </>

                                                    }
                                                </Row>

                                            </Col>

                                            <Col className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

                                                <Row className="me-1">
                                                    <Col className="col-10 border-bottom m-2 border-3 border-success h-100 mt-2 ms-1">
                                                        <h5>Próximos</h5>
                                                    </Col>
                                                    {
                                                        cargandoModalEventos ?
                                                            <>
                                                                <Row>
                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>

                                                                    <Col className="col-5">
                                                                        <Skeleton height={60} />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            listaEventosFuturos.length > 0 ?
                                                                <>
                                                                    {
                                                                        listaEventosFuturos.map((evento, index) => {

                                                                            var newDate_ = new Date(evento.dFechaEvento).toLocaleDateString('es-MX', {
                                                                                year: 'numeric', month: 'short', day: '2-digit',
                                                                                hour: '2-digit', minute: '2-digit', hour12: true
                                                                            });

                                                                            newDate_ = newDate_.replace(",", "");
                                                                            evento.dFechaEventoReal = newDate_;

                                                                            return (
                                                                                
                                                                                <Col key={index} className="h-100 mb-2 fs--2 border-start border-2 border-success col-12">

                                                                                        <Link to={"#"} onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>

                                                                                            <Row>

                                                                                                {
                                                                                                    evento.vNombreLead.length <= 20 ?
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead + " - " + evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {"Lead " + evento.idLead}
                                                                                                            </Col>
                                                                                                            <Col className="col-12" style={{ fontSize: "0.8em" }} >
                                                                                                                {evento.vNombreLead}
                                                                                                            </Col>
                                                                                                        </>
                                                                                                }

                                                                                            </Row>
                                                                                        </Link>

                                                                                        <Row className="justify-content-center">

                                                                                            <Col className="col-3 col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-2 align-item-center align-self-center border-1">
                                                                                                <Card className="m-0 p-0 align-self-center">

                                                                                                    <Card.Header className="m-0 p-0 text-center text-light fw-bold" style={{ backgroundColor: "#e63757", fontSize: "0.8em" }}>
                                                                                                        {

                                                                                                            new Date(evento.dFechaEvento).getMonth() == 0 ? "ENE" :
                                                                                                                new Date(evento.dFechaEvento).getMonth() == 1 ? "FEB" :
                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 2 ? "MAR" :
                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 3 ? "ABR" :
                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 4 ? "MAY" :
                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 5 ? "JUN" :
                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 6 ? "JUL" :
                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 7 ? "AGO" :
                                                                                                                                            new Date(evento.dFechaEvento).getMonth() == 8 ? "SEP" :
                                                                                                                                                new Date(evento.dFechaEvento).getMonth() == 9 ? "OCT" :
                                                                                                                                                    new Date(evento.dFechaEvento).getMonth() == 10 ? "NOV" :
                                                                                                                                                        new Date(evento.dFechaEvento).getMonth() == 11 ? "DIC" : ''}
                                                                                                    </Card.Header>
                                                                                                    <Card.Body className="m-0 p-0 text-center border border-danger fw-bold text-muted" style={{ fontSize: "1.2em" }}>
                                                                                                        {new Date(evento.dFechaEvento).getDate()}
                                                                                                    </Card.Body>

                                                                                                </Card>

                                                                                            </Col>

                                                                                            <Col className="col-9 col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-10 p-0">

                                                                                                <Row>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <span className="fw-bold fs--2">
                                                                                                            <Link to="#" onClick={() => { setIdLead(evento.idLead); setShowModal(true); }}>
                                                                                                                <span className="">{evento.vTituloEvento}</span>
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                    </Col>

                                                                                                    <Col className="col-11 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-start col">
                                                                                                        <img width={20} src="/img/reloj48.png"></img>
                                                                                                        {
                                                                                                            new Date(evento.dFechaEvento).toLocaleTimeString(
                                                                                                                'es-mx',
                                                                                                                {
                                                                                                                    hour12: true
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                    </Col>

                                                                                                </Row>

                                                                                            </Col>



                                                                                        </Row>

                                                                                        <br />
                                                                                    </Col>
  
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <Alert className="ms-1 col-10 fs--1" variant="secondary">
                                                                        No hay eventos <b className="text-success" >próximos</b>
                                                                    </Alert>
                                                                </>

                                                    }
                                                </Row>

                                            </Col>

                                        </Row>
                                    </Col>


                                </Row>

                            </Modal.Body>
                            <Modal.Footer>



                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <>

                    </>
            }

            
            {/* Modal para mostrar todos los leads liberados*/
                showModalLeadsLiberados ?
                    <>
                        <Modal
                            show={showModalLeadsLiberados}
                            onHide={() => setShowModalLeadsLiberados(false)}
                            size="xl"

                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Background image={corner2} className="p-x1" style={{ zIndex: 0 }} />

                            <Modal.Header>

                                {
                                    <Modal.Title>
                                        <img src={circle} height="50" ></img> Leads Liberados
                                    </Modal.Title>
                                }

                                <FalconCloseButton style={{ zIndex: 1 }}
                                    className="btn btn-circle btn-sm transition-base p-0"
                                    onClick={() => setShowModalLeadsLiberados(false)} />
                            </Modal.Header>
                            <Modal.Body>

                                {
                                    cargandoModalLeads ?
                                        <>
                                            <Row>
                                                <Col className="col-5">
                                                    <Skeleton height={60} />
                                                </Col>

                                                <Col className="col-5">
                                                    <Skeleton height={60} />
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Col className="col-12">

                                                <Row className="me-1">

                                                    {
                                                        listaLeadsLiberados.length > 0 ?
                                                            <>
                                                                {
                                                                    listaLeadsLiberados.map((lead, index) => {

                                                                        return (
                                                                            <>
                                                                                <Col key={index} className="h-100 mt-2 fs--2 col-3">
                                                                                    <Link to={"#"} onClick={() => { setIdLead(lead.idLead); setShowModal(true); }}>
                                                                                        <Row>

                                                                                            <Col className="col-12 text-900 fw-semi-bold">
                                                                                                <img width="25" src="/img/cliente48.png"></img>&nbsp;
                                                                                                {"Lead " + lead.idLead}
                                                                                            </Col>
                                                                                            <Col className="col-12 fw-medium text-600 ms-2">
                                                                                                {lead.vNombreLead.length > 0 ? lead.vNombreLead : "Lead #" + lead.idLead}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Link>
                                                                                </Col>

                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <Alert className="ms-1 col-10 fs--1" variant="secondary">
                                                                    No hay Leads <b className="text-danger" >libres</b>
                                                                </Alert>
                                                            </>
                                                    }
                                                </Row>

                                            </Col>
                                        </>
                                }

                            </Modal.Body>

                        </Modal>
                    </>
                    :
                    <>

                    </>
            }

            {/* Modal para escoger un cliente y asignarlo a un lead, posiblemente cambiar la etapa o solo vincularlos*/

                <ModalClienteGanado
                    showEtapaClienteGanadoModal={showEtapaClienteGanadoModal}
                    setShowEtapaClienteGanadoModal={setShowEtapaClienteGanadoModal}
                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    idLead={idLead}
                    vNombreEtapa={vNombreEtapa}

                    contactos={contactos}
                    setContactos={setContactos}
                    listaJsonLeadsVinculados={listaJsonLeadsVinculados}
                    setEditarEtapa={setEditarEtapa}
                    GuardarComentario={GuardarComentario}
                    detallesLead={null}
                    setvNombreEtapa={null}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    setMensajeInformativo={setMensajeInformativo}
                    vendedor={vendedor}
                    crearObjAction={crearObjAction}
                    guardarNotificacion={guardarNotificacion}
                />
            }
            

        </>
    );
};

export default Contacts;
