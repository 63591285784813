import MensajeErrror from 'components/common/MensajeError';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const columns = [
    {
        accessor: 'idActivoFijo',
        Header: 'ID',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idActivoFijo } = rowData.row.original;
            return <Link to={"/contabilidad/activofijo/detalle/" + idActivoFijo}><span>{idActivoFijo}</span></Link>
        }
    },
    {
        accessor: 'iEstadoActivo',
        Header: 'ST',
        Cell: rowData => {
            const { iEstadoActivo } = rowData.row.original;
            return <span className="fs--2">{iEstadoActivo}</span>
        }
    },
    {
        accessor: 'idCategoria',
        Header: 'CT',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { idCategoria } = rowData.row.original;
            return (
                <span className="d-inline-block text-truncate">
                    {idCategoria}
                </span>
            );
        }
    },
    {
        accessor: 'dCostoAdquisicion',
        Header: 'Costo',
        Cell: rowData => {
            const { dCostoAdquisicion } = rowData.row.original;
            return <span className="fs--2">{dCostoAdquisicion}</span>
        }
    },
    {
        accessor: 'vNumeroRegistro',
        Header: 'Num. Reg.',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNumeroRegistro } = rowData.row.original;
            return <span> {vNumeroRegistro}</span>;
        }
    },
    {
        accessor: 'vNombreActivo',
        Header: 'Nombre de Activo',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombreActivo } = rowData.row.original;
            return <span className="d-inline-block text-truncate" style={{ maxWidth: '100px' }}> {vNombreActivo} </span>
        }
    },
    {
        accessor: 'vNumeroSerie',
        Header: 'Numero Serie',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNumeroSerie } = rowData.row.original;
            return <span> {vNumeroSerie}</span>;
        }
    },

    {
        accessor: 'iEmpleadoResponsable',
        Header: 'Responsable',
        Cell: rowData => {
            const { iEmpleadoResponsable } = rowData.row.original;
            return <>
                <span>{iEmpleadoResponsable}</span>
            </>
        }
    },
    {
        accessor: 'vUbicacionActivo',
        Header: 'Ubicacion Activo',
        Cell: rowData => {
            const { vUbicacionActivo } = rowData.row.original;
            return <>
                <span>{vUbicacionActivo}</span>
            </>
        }
    },
    {
        accessor: 'dFechaCompra',
        Header: 'dFechaCompra',
        Cell: rowData => {
            const { dFechaCompra } = rowData.row.original;
            return <span>{dFechaCompra}</span>;
        }
    },
    {
        accessor: 'dCreatedDate',
        Header: 'dCreatedDate',
        Cell: rowData => {
            const { dCreatedDate } = rowData.row.original;
            return <span>{dCreatedDate}</span>;
        }
    }
];

const ActivoFijo = () => {
    const [activoFijo, setActivoFijo] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [cargando, setCargando] = useState(true);
    const { token, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);

    const mostrarActivoFijo = async () => {
        setCargando(true);
        const response = await fetch("/api/activofijo/ObtenerActivoFijo",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log("=============== MOSTRAR DISTRIBUIDORES ===============");
            setActivoFijo(data['table1']);
            setEmpleados(data['table2']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        mostrarActivoFijo();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/cliente96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Activo Fijo
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="/contabilidad/activofijo/nuevo">
                            <Button size="sm"
                                variant="falcon-primary"
                            >Nuevo Activo Fijo</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={activoFijo}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header >
                        <Form >
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        onChange={() => { }}
                                    >
                                        <option value="">Todos los empleados</option>
                                        {empleados.map((empleado, index) => {
                                            return (<option key={index} value={empleado.idUsuario}>{empleado.vNombre} {empleado.vApellidos}</option>)
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col className="ms-auto" sm={8} lg={4} xs={12} >
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={activoFijo.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer />
        </>
    );
};

export default ActivoFijo;
