import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import MensajeErrror from 'components/common/MensajeError';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';

import akasiaLogo from "assets/img/illustrations/akasia.png";
//import FalconCloseButton from '../../components/common/FalconCloseButton';

const CatalogoOrdenes = () => {
    //AUTENTICACION
    const { token, setToken, idCatTipoUsuario, idUsuario } = useToken();

    //ColumnasOrdenes
    const columnsOrdenes = [
        {
            accessor: 'iNumPagosNoValidados',
            Header: '',
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { iNumPagosNoValidados, idOrden, bComentario, bIsActive, bIsArchivoAdjunto, dFechaOrden, dFechaPrimerPago } = rowData.row.original;
                return (
                    <>
                        {bIsActive == 0 &&
                            <img title="Orden Eliminada" src="/img/noactivo.png"></img>
                        }
                        {iNumPagosNoValidados > 0 &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay pagos sin validar..." src="/img/advertencia20.png"></img>
                            </Link>
                        }
                        {iNumPagosNoValidados == 0 &&
                            <img title="Validado" src="/img/activo.png"></img>
                        }
                        {bIsArchivoAdjunto == 1 &&
                            <img title="Archivo Adjunto" src="/img/archivo20.png"></img>
                        }
                        {bComentario == 1 &&
                            <img title="Hay comentarios" src="/img/comentario20.png"></img>
                        }
                        {dFechaPrimerPago < dFechaOrden &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay un error en este pago" src="/img/error20.png"></img>
                            </Link>
                        }

                    </>
                );
            }
        },
        {
            accessor: 'idOrden',
            Header: 'Orden',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idOrden, bIsActive } = rowData.row.original;
                return (
                    <>
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}># {idOrden}</strong>
                        </Link>

                    </>
                );
            }
        },
        {
            accessor: 'vNombreCliente',
            Header: 'Cliente',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idClienteAkasia, vNombreCliente, vEmail, bIsActive, bIsRecompra, bIsDistribucion } = rowData.row.original;
                return (
                    <>

                        <Link to={"/clientes/detalle/" + idClienteAkasia}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}>
                                {
                                    bIsDistribucion == null ? "" :
                                        bIsDistribucion == 1 ? "🎖️ " :
                                            bIsDistribucion == 0 ? <img width="15" src={akasiaLogo}></img> :
                                                ""


                                } {bIsRecompra && "♻ "} {vNombreCliente}</strong> <br />
                        </Link>
                        <span className={classNames({
                            "text-danger": bIsActive == false,
                            "text-lowercase": true
                        })} >{vEmail}</span>
                    </>
                );
            }
        },
        {
            accessor: 'dFechaVenta',
            Header: 'Fecha venta'
        },
        {
            accessor: 'vUsuario',
            Header: 'Vendedor',
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (
                    <>
                        {vUsuario}
                    </>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'fs--1'
            },
            Cell: rowData => {
                const { bIsPagada, bIsActive } = rowData.row.original;
                return (
                    <>

                        {bIsActive == 1 &&
                            <SoftBadge
                                pill
                                bg={classNames({
                                    success: bIsPagada === true,
                                    warning: bIsPagada === false,
                                })}
                                className="d-flex flex-center"
                            >
                                <p className="mb-0">
                                    {bIsPagada === true && 'Pagada'}
                                    {bIsPagada === false && 'No pagada'}
                                </p>
                                <FontAwesomeIcon
                                    icon={classNames({
                                        check: bIsPagada === true,
                                        redo: bIsPagada === 'processing',
                                        stream: bIsPagada === false,
                                        ban: bIsPagada === 'onhold'
                                    })}
                                    transform="shrink-2"
                                    className="ms-1"
                                />
                            </SoftBadge>}
                        {bIsActive == 0 &&
                            <SoftBadge
                                pill
                                bg="danger"
                                className="d-flex flex-center"
                            >
                                <p className="mb-0">
                                    Cancelada
                                </p>
                                <FontAwesomeIcon
                                    icon="ban"
                                    transform="shrink-2"
                                    className="ms-1"
                                />
                            </SoftBadge>}
                    </>
                );
            }
        },
        {
            accessor: 'dTotal',
            Header: 'Total',
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2">${parseFloat(dTotal).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'vMoneda',
            Header: 'Moneda',
            Cell: rowData => {
                const { vMoneda, dTipoCambio } = rowData.row.original;
                return (
                    <>
                        <span>

                            <span>{vMoneda}</span>
                            <span className="fw-bold">{(vMoneda == 'USD' ? " T.C. " + dTipoCambio : "")}</span>

                        </span>

                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Forma Pago',
            Cell: rowData => {
                const { vClave, vDescripcionFormaPago } = rowData.row.original;
                return (
                    <>
                        <span>{vClave}  {vDescripcionFormaPago}</span>
                    </>
                );
            }
        }

    ];

    //Columnas
    const columnsDetallesOrden = [
        {
            accessor: 'iNumPagosNoValidados',
            Header: '',
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { iNumPagosNoValidados, idOrden, bComentario, bIsActive } = rowData.row.original;
                return (
                    <>
                        {bIsActive == 0 &&
                            <img title="Hay comentarios" src="/img/noactivo.png"></img>
                        }
                        {iNumPagosNoValidados > 0 &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay pagos sin validar..." src="/img/advertencia20.png"></img>
                            </Link>
                        }
                        {iNumPagosNoValidados == 0 &&
                            <img title="Validado" src="/img/activo.png"></img>
                        }

                        {bComentario == 1 &&
                            <img title="Hay comentarios" src="/img/comentario20.png"></img>
                        }


                    </>
                );
            }
        }, {
            accessor: 'idOrden',
            Header: 'Orden',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idOrden, bIsActive } = rowData.row.original;
                return (
                    <>
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}># {idOrden}</strong>
                        </Link>

                    </>
                );
            }
        },
        {
            accessor: 'vNombreCliente',
            Header: 'Cliente',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idClienteAkasia, vNombreCliente, vEmail, bIsActive } = rowData.row.original;
                return (
                    <>

                        <Link to={"/clientes/detalle/" + idClienteAkasia}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}>{vNombreCliente}</strong> <br />
                        </Link>
                        <span className={classNames({
                            "text-danger": bIsActive == false,
                            "text-lowercase": true
                        })} >{vEmail}</span>
                    </>
                );
            }
        },
        {
            accessor: 'dFechaVenta',
            Header: 'Fecha venta'
        },
        {
            accessor: 'vUsuario',
            Header: 'Vendedor',
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (
                    <>
                        {vUsuario}
                    </>
                );
            }
        },
        {
            accessor: 'vCodigoBarras',
            Header: 'Codigo Barras',
            Cell: rowData => {
                const { vCodigoBarras } = rowData.row.original;
                return (
                    <>{vCodigoBarras == 'LIC-SERV' &&
                        <img src="/img/servidor48.png" width="32"></img>}
                        {vCodigoBarras == 'LIC-CAJ' &&
                            <img src="/img/caja48.png" width="32"></img>}
                        {vCodigoBarras == 'LIC-NUB' &&
                            <img src="/img/nube48.png" width="32"></img>}
                        {vCodigoBarras == 'POL-BAS' &&
                            <img src="/img/soporte48.png" width="32"></img>}
                        <strong>{vCodigoBarras}</strong>
                    </>
                );
            }
        },
        {
            accessor: 'vNombreProducto',
            Header: 'Nombre Producto',
            Cell: rowData => {
                const { vJsonLicencias, vJsonPolizas, vJsonRenovacion, vCodigoBarras, vNombreProducto, dCantidad } = rowData.row.original;

                //Verificando las licencias vinculadas
                const jsonLicencias = vJsonLicencias;

                const objLicencias = JSON.parse(jsonLicencias);
                let dNumeroLicencias = objLicencias.length;
                const dCantidadComprada = dCantidad;
                let dDiferencia = dCantidadComprada - dNumeroLicencias;

                //Verificando polizas vinculadas
                const jsonPolizas = vJsonPolizas;

                var objPolizas = [];

                objPolizas = JSON.parse(jsonPolizas);
                let dNumeroPolizas = objPolizas.length;
                const dCantidadCompradaPoliza = dCantidad;
                let dDiferenciaPoliza = dCantidadCompradaPoliza - dNumeroPolizas;

                //verificando renovaciones vinculadas
                const jsonLicRen = vJsonRenovacion;

                var objRenovacion = [];

                objRenovacion = JSON.parse(jsonLicRen);
                let dNumeroLicRen = objRenovacion.length;
                const dCantidadCompradaLicRen = dCantidad;
                let dDiferenciaLicRen = dCantidadCompradaLicRen - dNumeroLicRen;


                return (
                    <>
                        <Row>
                            <Col>
                                <span>{vNombreProducto}</span>
                            </Col>
                        </Row>
                        <Row>
                            {objLicencias.map(objLicencia => {
                                return (
                                    <Col key={objLicencia.idLicencia} className="col-6 pr-1 mb-2" style={{ cursor: 'pointer' }}>

                                        <Link to={"/licencias/detalle/" + objLicencia.idLicencia} target="_blank" className="stretched-link text-info position-relative">
                                            <img className="mr-2" src="/img/pcautorizada20.png"></img>
                                            {vCodigoBarras} #{objLicencia.idLicencia}

                                            {
                                                (objLicencia.Status == "NO ACTIVADA") ?
                                                    <>
                                                        <span className="ms-2 badge badge-soft-danger">{objLicencia.Status}</span>

                                                    </>
                                                    :
                                                    <>
                                                        <span className="ms-2 badge badge-soft-success">{objLicencia.Status}</span>
                                                    </>
                                            }

                                            {
                                                (objLicencia.bCancelado == 1) &&
                                                <>&nbsp;<span className="ms-2 badge badge-soft-danger">CANCELADA</span></>

                                            }


                                        </Link>

                                        {/*<span className="text-muted">Click para <strong>asignar licencia</strong></span>*/}

                                    </Col>
                                );
                            })
                            }
                            {dDiferencia > 0 && (vCodigoBarras == 'LIC-SERV' || vCodigoBarras == 'LIC-CAJ' || vCodigoBarras == 'LIC-NUB') &&
                                <Col><span className="ms-2 badge badge-soft-danger">FALTA ASIGNAR LICENCIAS</span></Col>}
                        </Row>
                        <Row>
                            {
                                objPolizas.map(
                                    (objPoliza, index) => {
                                        return (
                                            (objPoliza.idPoliza > 0) &&
                                            <Col key={index} xs={12} md={12} className="pr-1 mb-2" style={{ cursor: 'pointer' }}>
                                                <img className="mr-2" src="/img/activo.png"></img>

                                                <Link to={"/polizas/detalle/" + objPoliza.idPoliza} target="_blank" className="stretched-link text-info position-relative">

                                                    #{objPoliza.idPoliza}

                                                </Link>
                                                <span className="fs--2"> | {objPoliza.vNombrePoliza}</span>
                                            </Col>



                                        );

                                    }
                                )
                            }
                            {dDiferenciaPoliza > 0 && (vCodigoBarras == 'POL-BAS' || vCodigoBarras == 'POL-EXT') &&
                                <Col><span className="ms-2 badge badge-soft-danger">FALTA RENOVAR PÓLIZA</span>
                                   
                                </Col>}
                        </Row>
                        <Row>
                            {
                                objRenovacion.map(
                                    (licRen, index) => {
                                        return (
                                            (licRen.idLicencia > 0) &&
                                            <Col key={index} xs={12} md={6} className="pr-1 mb-2" style={{ cursor: 'pointer' }}>
                                                <img className="mr-2" src="/img/activo.png"></img> <b></b>&nbsp;
                                                <Link to={"/licencias/detalle/" + licRen.idLicencia} target="_blank" className="stretched-link text-info position-relative">
                                                    LIC #{licRen.idLicencia}
                                                </Link>
                                                &nbsp; x {licRen.iNumeroDias} días
                                            </Col>

                                        );

                                    }
                                )
                            }
                            {dDiferenciaLicRen > 0 && (vCodigoBarras == 'LIC-NUB-RENOV' || vCodigoBarras == 'LIC-NUB-MEN') &&
                                <Col><span className="ms-2 badge badge-soft-danger">FALTA RENOVAR LICENCIA</span></Col>}
                        </Row>
                    </>

                );
            }
        },
        {
            accessor: 'dCantidad',
            Header: 'CANT'
        },

        {
            accessor: 'dTotal',
            Header: 'Total',
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2">${parseFloat(dTotal).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'vMoneda',
            Header: 'Moneda',
            Cell: rowData => {
                const { vMoneda, dTipoCambio } = rowData.row.original;
                return (
                    <>
                        <span>

                            <span>{vMoneda}</span>
                            <span className="fw-bold">{(vMoneda == 'USD' ? " T.C. " + dTipoCambio : "")}</span>

                        </span>

                    </>
                );
            }
        }


    ];

    //datos
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [ordenes, setOrdenes] = useState([]);
    const [detallesOrdenes, setDetallesOrdenes] = useState([]);

    const [vendedores, setVendedores] = useState([]);
    const [vendedorID, setVendedorID] = useState(idUsuario)
    const [dateQuarter, setDateQuarter] = useState(new Date())
    const [cargando, setCargando] = useState(true);
    const [totalMXN, setTotalMXN] = useState(0);
    const [FilterEstatus, setFilterEstatus] = useState('-1');
    const [tipoReporte, setTipoReporte] = useState(0);


    //Obtiene las licencias por id
    const ObtenerDetallesOrden = async (idUsuario, date_) => {

        setDateQuarter(new Date(date_));
        var month = new Date(date_).getMonth();
        month = month + 1;
        var year = new Date(date_).getFullYear();
        setVendedorID(idUsuario)

        var iEstatus = FilterEstatus;

        setOrdenes([]);
        setCargando(true);
        const response = await fetch("/api/orden/ObtenerListaDetallesOrdenes/?idUsuario=" + idUsuario + "&month=" + month + "&year=" + year + "&iEstatus=" + iEstatus, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setDetallesOrdenes(data['table1']);

            setTotalMXN(data['table2'][0].totalMXN)
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    //Obtiene las licencias por id
    const ObtenerOrden = async (idUsuario, date_) => {

        setDateQuarter(new Date(date_));
        var month = new Date(date_).getMonth();
        month = month + 1;
        var year = new Date(date_).getFullYear();
        setVendedorID(idUsuario)

        var iEstatus = FilterEstatus;

        setOrdenes([]);
        setDetallesOrdenes([]);
        setCargando(true);
        const response = await fetch("/api/orden/ObtenerListaOrdenes/?idUsuario=" + idUsuario + "&month=" + month + "&year=" + year + "&iEstatus=" + iEstatus, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setOrdenes(data['table1']);
            setVendedores(data['table2'])
            setTotalMXN(data['table3'][0].totalMXN)
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {

        var date_ = sessionStorage.getItem("selectMes") == null ? dateQuarter : sessionStorage.getItem("selectMes");
        var idUsuario_ = sessionStorage.getItem("selectIDUsuario") == null ? idUsuario : sessionStorage.getItem("selectIDUsuario");
        if (tipoReporte == 0)
            ObtenerOrden(idUsuario_, date_);
        else
            ObtenerDetallesOrden(idUsuario_, date_);
    }, [FilterEstatus, tipoReporte, vendedorID]);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/ordenes96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Cat&aacute;logo de &oacute;rdenes
                        </h5>
                    </Col>

                    <Col sm="auto" className="ms-auto">
                        {idCatTipoUsuario == 1 && <>
                            <img src="/img/dinero48.png" width="32"></img>&nbsp;

                            <span className={
                                'font-sans-serif fs-1 ' + (totalMXN > 0 ? 'text-success' : 'text-danger')
                            }>
                                {cargando ? <Skeleton className="w-75" /> : <strong>${parseFloat(totalMXN).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })} MXN</strong>}
                            </span></>}

                    </Col>
                </Row>
            </Card>

            <AdvanceTableWrapper
                columns={ordenes.length > 0 ? columnsOrdenes : columnsDetallesOrden}
                data={ordenes.length > 0 ? ordenes : detallesOrdenes}
                sortable
                pagination
                perPage={30}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <Row>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <span><Form.Label className="text-700 m-0">Mes</Form.Label> <br /></span>
                                <DatePicker
                                    selected={dateQuarter}
                                    onChange={(date) => {
                                        console.log(date);
                                        sessionStorage.setItem("selectMes", date);
                                        //setDateQuarter(new Date(date));
                                        tipoReporte == 0 ? ObtenerOrden(vendedorID, date) : ObtenerDetallesOrden(vendedorID, date)
                                    }}
                                    dateFormat="MMMM yyyy - QQQ"
                                    className="form-control w-100 m-0"
                                    showMonthYearPicker
                                />
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3} className="align-items-center mb-1">
                                <Form.Label className="text-700 m-0">Vendedores</Form.Label>
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    className="form-control m-0"
                                    value={vendedorID}
                                    onChange={(e) => {
                                        setVendedorID(e.target.value);
                                        sessionStorage.setItem("selectIDUsuario", e.target.value)
                                        //ObtenerOrden(e.target.value, dateQuarter);
                                    }}
                                    disabled={idCatTipoUsuario != 1}
                                >
                                    <option value="">Todos los vendedores</option>
                                    {vendedores.map((vendedor, index) => {
                                        return (<option key={index} value={vendedor.idUsuario}>{vendedor.vNombre} {vendedor.vApellidos}</option>)
                                    })}

                                </Form.Select>

                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3} className="align-items-center mb-1">
                                <Form.Label className="text-700 m-0">Estatus</Form.Label>
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    className="form-control m-0"
                                    value={FilterEstatus}
                                    onChange={(e) => {
                                        setFilterEstatus(e.target.value);
                                    }}

                                >
                                    <option value="-1">TODOS</option>
                                    <option value="1">Pagadas</option>
                                    <option value="0">No Pagadas</option>

                                </Form.Select>

                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="align-items-center mb-1">
                                <Form.Label className="text-700 m-0">Tipo de reporte</Form.Label>
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    className="form-control m-0"
                                    value={tipoReporte}
                                    onChange={(e) => {
                                        setTipoReporte(e.target.value);
                                    }}

                                >
                                    <option value="0">&Oacute;RDENES</option>
                                    <option value="1">DETALLE DE &Oacute;RDEN</option>

                                </Form.Select>

                            </Col>
                            <Col sm={8} lg={4} xs={12} className="mt-2" >
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={ordenes.length > 0 ? ordenes.length : detallesOrdenes.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>            

            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
        </>
    );
};

export default CatalogoOrdenes;
