import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMap from 'akasia-components/map/GoogleMap';
import classNames from 'classnames';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Badge, Button, Card, Col, FloatingLabel, Form, ListGroup, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { FiFileText } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FeedCard from '../orden/comentarios/FeedCard';
import FeedProvider from '../orden/comentarios/FeedProvider';
import CopyButton from '../servicio/CopyButton';

const DetallesLicencia = () => {
    const { id } = useParams();
    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm();

    /**
     * DATA
     */
    const [licencias, setLicencias] = useState([]);
    const [nubes, setNubes] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [logs, setLogs] = useState([]);
    const [licenciasRelacionadas, setLicenciasRelacionadas] = useState([]);
    const [hardware, setHardware] = useState([]);
    const [tipoLicencia, setTipoLicencia] = useState([]);
    const [datosOrden, setdatosOrden] = useState({ isLoading: true, idOrden: null });

    /**
     * SPRINT_18
     */
    const [Sucursales, setSucursales] = useState([]);

    const [cicloFacturacion, setCicloFacturacion] = useState([]);
    const [tipoPolizas, setTipoPolizas] = useState([]);
    const [polizasRelacionadas, setPolizasRelacionadas] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [licenciasServidor, setLicenciasServidor] = useState([]);
    const [datosPCAutorizar, setDatosPCAutorizar] = useState([]);
    const [datosPCLogin, setDatosPCLogin] = useState([]);
    const [datosNube, setDatosNube] = useState([]);
    const [usuariosNube, setUsuariosNube] = useState([]);
    const [BDServer, setBDServer] = useState([]);
    const [logServicios, setLogServicios] = useState([]);
    const [ordenDetalleRenovacion, setOrdenDetalleRenovacion] = useState([]);


    /**
     * MENSAJES Y AVISOS
     */
    const [editar, setEditar] = useState(false);
    //const [editarConfiguracion, setEditarConfiguracion] = useState(false);

    const [editarIdentificador, setEditarIdentificador] = useState(false);
    const [editarUsuarioNube, setEditarUsuarioNube] = useState(false);
    const [editarContrasenaNube, setEditarContrasenaNube] = useState(false);
    const [editarServidorBD, setEditarServidorBD] = useState(false);

    const [showModalAgregarUsuarioNube, setShowModalAgregarUsuarioNube] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [showModalCancelacion, setShowModalCancelacion] = useState(false);
    const [showModalAsignarDias, setShowModalAsignarDias] = useState(false);
    const [showModalRelacionadas, setShowModalRelacionadas] = useState(false);
    const [showModalActivar, setShowModalActivar] = useState(false);
    const [showModalActivarNube, setShowModalActivarNube] = useState(false);
    const [showModalRenovar, setShowModalRenovar] = useState(false);
    const [showModalDesactivar, setShowModalDesactivar] = useState(false);
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [showModalCambiarCliente, setShowModalCambiarCliente] = useState(false);
    const [showCambiarPCAutorizada, setShowCambiarPCAutorizada] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [comentario, setComentario] = useState('');
    const [procesandoComentario, setProcesandoComentario] = useState(false);

    /**
     * AUTENTICACION
     */
    const { token, setToken, idCatTipoUsuario, idUsuario, nombreCompleto } = useToken();

    /**
     * Columnas Para Los clientes Que se Cambiaran
     */
    const columns = [
        {
            accessor: 'idClienteAkasia',
            Header: 'ID',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { idClienteAkasia } = rowData.row.original;
                return (<b>{idClienteAkasia}</b>)
            }
        },
        {
            accessor: 'vNombre',
            Header: 'Nombre Cliente',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vNombre } = rowData.row.original;
                return <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}> {vNombre}</span>
            }
        },
        {
            accessor: 'vPais',
            Header: 'Pa\u00EDs',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vPais } = rowData.row.original;
                return <span className="d-inline-block text-truncate" style={{ maxWidth: '150px' }}>{vPais}</span>
            }
        },
        {
            accessor: 'vEstadoDepProv',
            Header: 'vEstadoDepProv',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vEstadoDepProv } = rowData.row.original;
                return <span className="d-inline-block text-truncate" style={{ maxWidth: '150px' }}>{vEstadoDepProv}</span>
            }
        },

        {
            accessor: 'none',
            Header: '',
            Cell: rowData => {
                const { idClienteAkasia, vNombre } = rowData.row.original;
                return (
                    <Row className="flex-end-center">
                        <Col xs="auto" >
                            <Button variant="light" size="sm" onClick={() => { cambiarCliente(idClienteAkasia, vNombre) }} className="border-300 text-600">
                                Seleccionar
                            </Button>
                        </Col>
                    </Row>
                );
            }
        }
    ];

    /**
     * Columnas para los logs
     */
    const columnsLogs = [
        {
            accessor: 'dCreadtedDate',
            Header: 'Fecha movimiento',
            maxWidth: 100,
            minWidth: 100,
            width: 100,
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { dCreatedDate } = rowData.row.original;
                return (<span>{dCreatedDate}</span>)
            }
        },
        {
            accessor: 'vCreatedUser',
            Header: 'Usuario',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vCreatedUser, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? (<span className="text-danger text-truncate" style={{ maxWidth: '350px' }}>{vCreatedUser}</span>) : (<span className="text-truncate" style={{ maxWidth: '350px' }}> {vCreatedUser}</span>)
            }
        },
        {
            accessor: 'vNombreSucursal',
            Header: 'Nombre Sucursal',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vNombreSucursal, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? (<span className="text-danger text-truncate" style={{ maxWidth: '350px' }}>{vNombreSucursal}</span>) : (<span className="text-truncate" style={{ maxWidth: '350px' }}> {vNombreSucursal}</span>)
            }
        },
        {
            accessor: 'vVersion',
            Header: 'Version',
            cellProps: { className: 'ps-1 fs--1' },
            Cell: rowData => {
                const { vVersion } = rowData.row.original;
                return <code>
                    {vVersion}
                </code>
            }
        },
        {
            accessor: 'vMovimiento',
            Header: 'Movimiento',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vMovimiento, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? (<span className="text-danger d-inline-block text-truncate" >{vMovimiento}</span>) : (<span className="d-inline-block text-truncate" >{vMovimiento}</span>)
            }

        }
    ];

    /**
     * FUNCIONES
     * SPRINT_18
     * Obtener Sucursales De la empresa
     * @param {any} idLicencia
     */
    const obtenerSucursalesDeEmpresa = async (idLicencia) => {
        setSucursales([]);
        const response = await fetch("/api/licencia/ObtenerSucursalesEmpresa?idLicencia=" + idLicencia, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data != null) {
                setSucursales(data);
            }
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Obtiene las licencias por id
     */
    const getInformacionDetalladaLicenciaByID = async () => {

        setdatosOrden({ isLoading: true, idOrden: null });
        setCargando(true);
        setShowModalRelacionadas(false);
        const response = await fetch("/api/licencia/getinformaciondetalladalicenciabyid/?id=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {

            console.log("==== DETALLES LICENCIA ====");

            console.log(response);
            const data = await response.json();
            //detalle licencia
            console.log("detalle licencia: ", data['table1'][0]);
            setLicencias(data['table1'][0]);

            //detalles de nubes vinculadas
            console.log(data['table2']);
            setNubes(data['table2']);

            //detalle de logs
            console.log(data['table3']);
            setLogs(data['table3']);

            //detalles de hardware
            console.log(data['table4']);
            setHardware(data['table4']);

            //detalles de tipo licencia
            console.log(data['table5']);
            setTipoLicencia(data['table5']);

            //ciclo facturacion
            console.log(data['table6']);
            setCicloFacturacion(data['table6']);

            //detalles vendedores
            console.log(data['table7']);
            setVendedores(data['table7']);

            //detalles de polizas
            console.log(data['table8']);
            setTipoPolizas(data['table8']);

            //detalles de polizas
            console.log(data['table9']);
            setPolizasRelacionadas(data['table9']);

            //licencias servidores
            console.log(data['table10']);
            setLicenciasServidor(data['table10']);

            //Datos Configuracion Nube
            console.log(data['table11']);
            if (data['table11'].length > 0) {
                setDatosNube(data['table11'][0]);

                /**
                 * SPRINT_18
                 * Se comenta para que se libere hotfix DESCOMENTAR PARA LIBERAR SPRINT 18
                 * */
                obtenerSucursalesDeEmpresa(data['table1'][0].idLicencia);
            }
            else
                setDatosNube([]);

            //Datos Usuarios Nube
            console.log(data['table12']);
            setUsuariosNube(data['table12']);

            //Server y BD
            console.log(data["table13"]);
            setBDServer(data["table13"]);

            //Obener PC Logins
            console.log(data["table14"]);
            setDatosPCLogin(data["table14"]);

            //Logs
            console.log(data["table15"]);
            setLogServicios(data["table15"]);

            //Comentarios de la licencia
            console.log("Comentarios: ", data.table16);
            setComentarios(data.table16);


            //se cambia estado de cargando
            setCargando(false);

            if (licencias.idCatTipoLicencia == 3)
                setNubes([]);

            setdatosOrden({ isLoading: false, idOrden: data['table1'][0].idOrden });

        }
        else {
            setShowMensajeError(true);
            setCargando(false);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    /**
     * Este metodo cancela la licencia actual
     * @param {any} vMotivo
     */
    const cancelarLicencia = async (vMotivo) => {
        const response = await fetch("/api/licencia/cancelarlicencia/?id=" + id + "&vMotivo=" + encodeURIComponent(vMotivo), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            getInformacionDetalladaLicenciaByID();

            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setShowModalCancelacion(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Metodo que elimina una licencia
     */
    const eliminarLicencia = async () => {
        const response = await fetch("/api/licencia/EliminarLicencia/?key=" + id, {
            method: 'DELETE',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data[0].iResponseType == 1) {
                getInformacionDetalladaLicenciaByID();
                setShowMensajeInformativo(true);
                setMensajeInformativo(data[0].vMensaje);
                setShowModalEliminar(false)
            } else {
                setShowMensajeError(true);
                setMensajeError(data[0].vMensaje);
                setShowModalEliminar(false)
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Este metodo aumenta el numero de dias del periodo de prueba
     * @param {any} iNumeroDias
     */
    const asignarDiasLicenciaPrueba = async (iNumeroDias) => {
        const response = await fetch("/api/licencia/asignardiaslicenciaprueba/?id=" + id + "&iNumeroDias=" + iNumeroDias, {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            getInformacionDetalladaLicenciaByID();

            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalAsignarDias(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Este metodo aumenta el numero de dias del periodo de prueba
     * @param {any} idClienteAkasia
     * @param {any} vNombreClienteNuevo
     */
    const cambiarCliente = async (idClienteAkasia, vNombreClienteNuevo) => {
        const response = await fetch("/api/licencia/cambiarCliente/?id=" + id + "&idClienteAkasia=" + idClienteAkasia + "&vClienteNuevo=" + encodeURIComponent(vNombreClienteNuevo) + "&vClienteAnterior=" + encodeURIComponent(licencias.vNombre) + "_" + encodeURIComponent(licencias.vApellidos), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                detalles = detalles[0];
                console.log("detalles: ", detalles);

                licencias.idClienteAkasia = detalles.idClienteAkasia;
                licencias.vNombre = detalles.vNombre;
                licencias.vApellidos = detalles.vApellidos;
                licencias.vEmail = detalles.vEmail;
                licencias.vTelefonoPersonal = detalles.vTelefonoPersonal;
                licencias.vUpdatedUser = detalles.vUpdatedUser;

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
            }
            else {
                setMensajeError(data.vMensaje);
                setShowMensajeError(true);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalCambiarCliente(false);
        reset();
    };

    /**
     * Este metodo obtiene las licencias relacionadas a la Nube
     * @param {any} vUUIDEmpresa
     */
    const obtenerLicenciasRelacionadas = async (vUUIDEmpresa) => {
        console.log(vUUIDEmpresa);
        setShowModalRelacionadas(true);
        const response = await fetch("/api/licencia/obtenerlicenciasrelacionadas/?vUUIDEmpresa=" + vUUIDEmpresa, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            setLicenciasRelacionadas(data["table1"])

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Obtener Lista de Pc Login
     */
    const obtenerListaPCLogIn = async () => {
        const response = await fetch("/api/licencia/ObtenerListaPCsInicioSesion/?idLicencia=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setDatosPCAutorizar(data)
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Obtener Servidores VUUID
     */
    const obtenerListaClientes = async () => {
        const response = await fetch("/api/cliente/obtenerlistaclientes", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            setClientes(data["table1"])

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        reset();
    };

    /**
     * Enviar informacion
     * @param {any} values
     */
    const enviarInfoToServer = async (values) => {

        var jsonToSend = [];
        jsonToSend.push(JSON.parse(values));

        console.log("jsonToSend: ", jsonToSend);

        const response = await fetch("/api/licencia/EditarLicencia/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonToSend)
        })
            .catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {

            console.log("==== EDITAR DATOS LICENCIA ====");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                detalles = detalles[0];
                console.log("detalles: ", detalles);

                licencias.idCatTipoLicencia = detalles.idCatTipoLicencia;
                licencias.vNombreVendedor = detalles.vNombreVendedor;
                licencias.vUUIDEmpresa = detalles.vUUIDEmpresa;
                licencias.idCatCicloFacturacion = detalles.idCatCicloFacturacion;
                licencias.vComentarios = detalles.vComentarios;
                licencias.vLatLon = detalles.vLatLon;
                licencias.vDescripcionTipo = detalles.vDescripcionTipo;
                licencias.vDescripcionCiclo = detalles.vDescripcionCiclo;
                licencias.iNumeroDias = detalles.iNumeroDias;

                getInformacionDetalladaLicenciaByID();

                setMensajeInformativo(data.vMessage);
                setShowMensajeInformativo(true);

                setEditar(false);
            }
            else {
                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
        setProcesandoModal(false);

    };


    /**
     * Activar licencia
     * @param {any} values
     */
    const activarLicencia = async (values) => {
        console.log(values);
        const response = await fetch("/api/licencia/activarlicencia/?id=" + id + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            if (data["table1"][0].iResponseType == 1) {
                getInformacionDetalladaLicenciaByID();
                setShowMensajeInformativo(true);
                setMensajeInformativo(data["table1"][0].vMensaje);
            }
            else {
                setShowMensajeError(true);
                setMensajeError(data["table1"][0].vMensaje);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalActivar(false);
        setShowModalActivarNube(false);
        setProcesandoModal(false);
        reset();
    };

    //Renovar Poliza
    const ObtenerListaOrdenDetalleRenovacion = async () => {
        const response = await fetch("/api/licencia/ObtenerListaOrdenDetalleRenovacion/?idLicencia=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setOrdenDetalleRenovacion(data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    /**
     * Renovar Licencia
     * @param {any} values
     */
    const renovarLicencia = async (values) => {
        console.log(values);
        const response = await fetch("/api/licencia/renovarlicencia/?id=" + id + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            getInformacionDetalladaLicenciaByID();
            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalRenovar(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Desactivar Licencia
     */
    const desactivarLicencia = async () => {
        const response = await fetch("/api/licencia/desactivarlicencia/?id=" + id, {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            getInformacionDetalladaLicenciaByID();
            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalDesactivar(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Asingar PC Autorizada
     * @param {any} vDatosPCAutorizada
     */
    const asignarPCAutorizada = async (vDatosPCAutorizada) => {
        console.log(vDatosPCAutorizada);
        const response = await fetch("/api/licencia/asignarPCAutorizada/?id=" + id + "&vDatosPCAutorizada=" + encodeURIComponent(vDatosPCAutorizada), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            getInformacionDetalladaLicenciaByID();
            setShowMensajeInformativo(true);
            setMensajeInformativo(data[0].vMensaje);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowCambiarPCAutorizada(false);
        setProcesandoModal(false);
        reset();
    };


    //const EditarConfiguracionDatosNube = async (key, values) => {

    //    console.log(values);
    //    const response = await fetch("/api/licencia/EditarConfiguracionDatosNube/?key=" + key + "&values=" + encodeURIComponent(values), {
    //        method: 'PUT',
    //        headers:
    //        {
    //            'Authorization': 'Bearer ' + token,
    //            'Content-Type': 'application/json'
    //        }
    //    })
    //        .catch((error) => {
    //            setShowMensajeError(true);
    //            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
    //            console.log('Hubo un problema con la peticion Fetch:' + error);
    //            setCargando(false);
    //        });

    //    if (response.ok) {
    //        console.log(response);
    //        const data = await response.json();
    //        setCargando(false);
    //        setShowMensajeInformativo(true);
    //        setMensajeInformativo(data[0].vMensaje);
    //        setEditar(false);
    //        getInformacionDetalladaLicenciaByID();

    //    }
    //    else {
    //        setShowMensajeError(true);
    //        setMensajeError(response.status + ' ' + response.statusText);
    //        console.log(response);
    //        setCargando(false);
    //        if (response.status == 401) {
    //            setToken(null);
    //            location.href = "";
    //        }
    //    }
    //    setEditarConfiguracion(false);
    //    setProcesandoModal(false);
    //    reset();
    //};

    /**
     * Edita identificador de datos de la nube
     * @param {any} key
     * @param {any} values
     */
    const EditarIdentificadorDatosNube = async (key, values) => {
        console.log(values);
        const response = await fetch("/api/licencia/EditarIdentificadorDatosNube/?key=" + key + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {

            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                setCargando(false);
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
                setEditar(false);
                getInformacionDetalladaLicenciaByID();
            }
            else {
                setCargando(false);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
                setCargando(false);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setEditarIdentificador(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Edita datos de un usuario de la nube
     * @param {any} key
     * @param {any} values
     */
    const EditarUsuarioNubeDatosNube = async (key, values) => {
        console.log(values);
        const response = await fetch("/api/licencia/EditarUsuarioNubeDatosNube/?key=" + key + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {

            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                setCargando(false);
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
                setEditar(false);
                getInformacionDetalladaLicenciaByID();
            }
            else {
                setCargando(false);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
                setCargando(false);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setEditarUsuarioNube(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Edita la contrase�a de la nube
     * @param {any} key
     * @param {any} values
     */
    const EditarContrasenaNubeDatosNube = async (key, values) => {
        console.log(values);
        const response = await fetch("/api/licencia/EditarContrasenaNubeDatosNube/?key=" + key + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {

            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                setCargando(false);
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
                setEditar(false);
                getInformacionDetalladaLicenciaByID();
            }
            else {
                setCargando(false);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
                setCargando(false);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setEditarContrasenaNube(false);
        setProcesandoModal(false);
        reset();
    };

    /**
     * Edita servidor
     * @param {any} key
     * @param {any} values
     */
    const EditarServidorBDDatosNube = async (key, values) => {
        console.log(values);
        const response = await fetch("/api/licencia/EditarServidorBDDatosNube/?key=" + key + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {

            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                setCargando(false);
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
                setEditar(false);
                getInformacionDetalladaLicenciaByID();
            }
            else {
                setCargando(false);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
                setCargando(false);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setEditarServidorBD(false);
        setProcesandoModal(false);
        reset();
    };

    /**
    * Registrar usuario nuevo en Akasia
    * @param {any} values
    */
    const registrarUsuarioNubeAkasia = async (values) => {

        console.log(values);
        const response = await fetch("/api/usuarionubeakasia/RegistrarUsuarioNubeAkasia/?values=" + encodeURIComponent(values), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const data = await response.json();
            console.log(data);

            setMensajeInformativo(data.vMessage);
            setShowMensajeInformativo(true);

            getInformacionDetalladaLicenciaByID();

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

        }

        setShowModalAgregarUsuarioNube(false);
        setProcesandoModal(false);

        reset();

    };

    /**
     * Obtiene cadena al azar
     * @param {any} action
     */
    const ObtenerCadenaRandom = async (action) => {
        const response = await fetch("/api/licencia/ObtenerCadenaRandom", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'text/plain'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.text();
            console.log(data);

            if (action == 'Usuario')
                setValue("vUsuario", data);
            else if (action == 'Contrasena')
                setValue("vContrasena", data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Submit de PC Autorizada
     * @param {any} data
     */
    const onSubmitAsignarPCAutorizada = data => {
        console.log(data.vDatosPCAutorizada);
        asignarPCAutorizada(data.vDatosPCAutorizada);
    };

    /**
     * Eliminar Licencia
     */
    const onSubmitEliminar = () => {
        eliminarLicencia();
    };

    /**
     * Submit de cancelacion
     * @param {any} data
     */
    const onSubmitCancelacion = data => {
        setProcesandoModal(true);
        console.log(data.vMotivo);
        cancelarLicencia(data.vMotivo);
    };

    /**
     * Submit de asignar dias
     * @param {any} data
     */
    const onSubmitAsignarDias = data => {
        setProcesandoModal(true);
        console.log(data.iNumeroDias);
        asignarDiasLicenciaPrueba(data.iNumeroDias);
    };

    /**
     * Submit de activar
     * @param {any} data
     */
    const onSubmitActivar = data => {
        setProcesandoModal(true);
        if (data.idCatCicloFacturacion == 4 && licencias.idCatTipoLicencia == 3) {
            toast.error('No es posible asignar un ciclo de facturaci\u00F3n permanente a este tipo de licencia', {
                theme: 'colored',
                position: "bottom-left"
            });
            setProcesandoModal(false);
        }
        else
            activarLicencia(JSON.stringify(data));
    };

    /**
     * Submit de renovar
     * @param {any} data
     */
    const onSubmitRenovar = data => {
        setProcesandoModal(true);
        if (data.idCatCicloFacturacion == 4 && licencias.idCatTipoLicencia == 3) {
            toast.error('No es posible asignar un ciclo de facturaci\u00F3n permanente a este tipo de licencia', {
                theme: 'colored',
                position: "bottom-left"
            });
            setProcesandoModal(false);
        }
        else
            renovarLicencia(JSON.stringify(data));

    };

    /**
     * Submit de desactivar
     * @param {any} data
     */
    const onSubmitDesactivar = data => {
        setProcesandoModal(true);
        desactivarLicencia(JSON.stringify(data));

    };

    /**
     * Submit de guardar
     * @param {any} data
     */
    const onSubmit = data => {
        console.log("data: ", data);

        setProcesandoModal(true);
        var vConfiguracion = {
            vIdentificador: data.vIdentificador,
            vUsuario: data.vUsuario,
            vContrasena: data.vContrasena,
            vBD: data.vBD
        }
        data.vConfiguracion = JSON.stringify(vConfiguracion);
        if (data.vConfiguracion == '{}')
            data.vConfiguracion = '';
        enviarInfoToServer(JSON.stringify(data, null, 2));
    };

    //const onSubmitDatosConfiguracion = data => {
    //    setProcesandoModal(true);
    //    console.log(data);

    //    EditarConfiguracionDatosNube(data.idSyncCredenciales, JSON.stringify(data, null, 2));
    //};

    /**
     * Submit de identificador
     * @param {any} data
     */
    const onSubmitIdentificador = data => {
        setProcesandoModal(true);
        console.log(data);

        EditarIdentificadorDatosNube(data.idSyncCredenciales, JSON.stringify(data, null, 2));
    };

    /**
     * Submit de usuario nube
     * @param {any} data
     */
    const onSubmitUsuarioNube = data => {
        setProcesandoModal(true);
        console.log(data);

        EditarUsuarioNubeDatosNube(data.idSyncCredenciales, JSON.stringify(data, null, 2));
    };

    /**
     * Submit de contrase�a
     * @param {any} data
     */
    const onSubmitContrasenaNube = data => {
        setProcesandoModal(true);
        console.log(data);

        EditarContrasenaNubeDatosNube(data.idSyncCredenciales, JSON.stringify(data, null, 2));
    };

    /**
     * Submit de servidor 
     * @param {any} data
     */
    const onSubmitServidorBD = data => {
        setProcesandoModal(true);
        console.log(data);

        EditarServidorBDDatosNube(data.idSyncCredenciales, JSON.stringify(data, null, 2));
    };

    /**
     * Submit de nuevo usuario nube
     * @param {any} data
     */
    const onSubmitNuevoUsuarioNube = data => {
        setProcesandoModal(true);
        registrarUsuarioNubeAkasia(JSON.stringify(data));
    };

    /**
     * verifica la orden de pago de la licencia
     * @param {any} tipolicencia
     */
    const verificarOrdenPagoLicencia = async (tipolicencia) => {

        //console.log(tipolicencia);
        var _tipoLicencia = tipolicencia;
        var objDataSend = {};
        objDataSend.idLicencia = id;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/licencia/VerificarOrdenPagoLicencia", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: jDataSend
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                if (_tipoLicencia == 3) {
                    setShowModalActivarNube(true);
                    //console.log(_tipoLicencia);

                } else {
                    setShowModalActivar(true);
                    //console.log("Entra si tipo licencia no es 3");
                }


            }

            if (data.iResponseType == 3) {

                toast.error(data.vMessage, {
                    theme: 'colored',
                    position: "bottom-right",
                    limit: 1
                });

            }

            if (data.iResponseType == 2) {
                setMensajeError('Ocurrio un error:' + data.vMessage);
                setShowMensajeError(true);


            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {//si no tiene autorizacion hay que setear com nulo el token
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    /**
     * Se ha recargado 
     */
    const navigate = useNavigate();

    /**
     * Guarda un comentario en la licencia
     * @param {any} e
     * @returns
     */
    const submitComment = async (e) => {

        e.preventDefault();
        if (comentario == '') {
            return;
        }
        setProcesandoComentario(true);

        var jDataSend = {}
        jDataSend.idLicencia = licencias.idLicencia;
        jDataSend.vComentario = comentario;
        jDataSend.idTipoComentario = 1;

        const response = await fetch("/api/licencia/RegistrarComentario/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== REGISTRAR COMENTARIO ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setComentarios([detalles.table2[0], ...comentarios]);

                var objToActionString = crearObjAction(2, "/licencias/detalle/" + licencias.idLicencia, '', '');

                var jsonData = {};
                jsonData.idLicencia = licencias.idLicencia;
                //jsonData.idUsuario = licencias.idUsuario;
                var jsonString = JSON.stringify(jsonData);

                guardarNotificacion(
                    "Se hizo un comentario en una licencia",
                    "<b> " + nombreCompleto + "</b> hizo un comentario en la licencia <b>#" + licencias.idLicencia + "</b> : <i> '" + comentario.substring(0, 3) + "...'</i>",
                    0,
                    objToActionString,
                    9,
                    jsonString
                );

                setProcesandoComentario(false);
                setComentario('');

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setProcesandoComentario(false);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            setProcesandoComentario(false);
            console.log(response);
        }

    };

    /**
     * Guarda una notificaci�n
     */
    const guardarNotificacion = async (vTitulo, vCuerpo, idUsuarioTarget, objToAction, idTipoNotificacion, jsonData = null) => {

        var jDataSend = {};
        jDataSend.vTitulo = vTitulo;
        jDataSend.vCuerpo = vCuerpo;
        jDataSend.idUsuarioTarget = idUsuarioTarget;
        jDataSend.objToAction = objToAction;
        jDataSend.idTipoNotificacion = idTipoNotificacion;
        jDataSend.jsonData = jsonData;

        const response = await fetch("/api/leads/guardarNotificacion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACI�N GUARDADA ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);
            console.log("objToAction: ", objToAction);
            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var notificacionesList = detalles.table2;
                console.log("notificacionesList: ", notificacionesList);

                var jDataSendNoti = {}
                jDataSendNoti.notificacionesList = notificacionesList;

                const responseNoti = await fetch("/api/account/EnviarNotificacionPush/",
                    {
                        method: 'POST',
                        headers:
                        {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(jDataSendNoti)
                    }).catch((error) => {

                        setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                        setShowMensajeError(true);
                        console.log('Hubo un problema con la peticion Fetch:' + error);
                    });

                if (responseNoti.ok) {
                    if (responseNoti.iResponseType == 1) {
                        console.log("Exito!");
                    }
                }


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    };


    /**
     * Crea un objeto de acci�n que especifica la acci�n a realizar al hacer clic en una notificaci�n,
     * ya sea abrir un modal o redireccionar a otra p�gina.
     * @param {any} TypeAction
     * @param {any} vURL
     * @param {any} idModalType
     * @param {any} idLead
     * @returns
     */
    const crearObjAction = (TypeAction, vURL, idModalType, idLead,) => {
        var objToAction = {};
        var objDetalles = {};
        var objData = {};

        objData.idLead = idLead;

        objDetalles.vUrl = vURL;
        objDetalles.idModalType = idModalType;
        objDetalles.data = objData

        objToAction.TypeAction = TypeAction;
        objToAction.detalles = objDetalles;

        var objToActionString = JSON.stringify(objToAction);
        console.log("objToAction: ", objToActionString);
        return objToActionString;
    };


    useEffect(() => {
        getInformacionDetalladaLicenciaByID();
    }, [id]);

    return (
        <>

            <PageHeader
                title={"Licencia " + id + " | " + licencias.vDescripcionTipo}
                className="mb-1"
            >

                {
                    datosOrden.isLoading ?
                        <Skeleton width={100} />
                        :
                        <>
                            {datosOrden.idOrden ?
                                <>
                                    Orden <Link to={"/ordenes/detalle/" + datosOrden.idOrden}>#{datosOrden.idOrden}</Link> Asignada
                                </>
                                :
                                <>
                                    <div className="badge badge-soft-warning rounded-pill">
                                        <BsFillExclamationTriangleFill className="mb-1 mr-1" />
                                        <span className="mb-0">&nbsp;Sin Orden Asignada</span>
                                    </div>

                                </>
                            }
                        </>
                }

            </PageHeader>
            {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)
                :
                (<>
                    <Card className="mb-3">
                        <Card.Header className="d-flex flex-between-center">
                            <IconButton
                                onClick={() => navigate(-1)}
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-left"
                            />
                            {licencias.bIsActive == 0 && <h1><Badge bg='danger' className='me-2'>LICENCIA ELIMINADA</Badge></h1>}
                            <Flex>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="print"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                    onClick={() => { window.print() }}
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Imprimir</span>
                                </IconButton>
                                {!licencias.bCancelado && (
                                    !licencias.bIsPeriodoPrueba && (
                                        !editar &&
                                        <>
                                            <IconButton
                                                variant="falcon-danger"
                                                size="sm"
                                                icon="ban"
                                                transform="shrink-2"
                                                iconAlign="middle"
                                                className="d-none d-sm-block me-2"
                                                onClick={() => { setShowModalCancelacion(true) }}
                                            >
                                                <span className="d-none d-xl-inline-block ms-1">Cancelar definitivamente</span>
                                            </IconButton>
                                        </>
                                    )
                                )}
                                {!editar &&
                                    licencias.bIsActive &&
                                    <IconButton
                                        variant="falcon-danger"
                                        size="sm"
                                        icon="trash"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="d-none d-sm-block me-2"
                                        onClick={() => { setShowModalEliminar(true) }}
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Eliminar Licencia</span>
                                    </IconButton>
                                }


                            </Flex>
                        </Card.Header>
                    </Card>
                    <Row className="g-3 mb-3">
                        <Col lg={6}>
                            <Card className="fs--1">
                                <Card.Header>
                                    <Row className="align-items-center">
                                        <Col xs={2} sm={3} md={3} lg={2}>
                                            {licencias.idCatTipoLicencia == 1 &&
                                                <img src="/img/servidor48.png"></img>}
                                            {licencias.idCatTipoLicencia == 2 &&
                                                <img src="/img/caja48.png"></img>}
                                            {licencias.idCatTipoLicencia == 3 &&
                                                <img src="/img/nube48.png"></img>}
                                        </Col>
                                        <Col>

                                            <h5 className="mb-0">Datos de licencia</h5>
                                        </Col>
                                        <Col xs="auto">
                                            {licencias.bIsActive &&
                                                !licencias.bCancelado &&
                                                !editar &&
                                                <IconButton
                                                    onClick={() => { setEditar(true) }}
                                                    iconClassName="fs--2 me-1"
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="pencil-alt"
                                                >
                                                    Editar licencia
                                                </IconButton>

                                            }

                                            {editar &&
                                                <IconButton
                                                    onClick={() => { setEditar(false); reset() }}
                                                    iconClassName="fs--2 me-1"
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon={faWindowClose}
                                                >
                                                    Cancelar edici&oacute;n
                                                </IconButton>
                                            }
                                        </Col>
                                    </Row>
                                </Card.Header>

                                <Card.Body className="border-top">
                                    {
                                        editar ? (
                                            <>
                                                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Vendedor</Form.Label>
                                                        <Form.Select
                                                            aria-label="Default select"
                                                            defaultValue={JSON.stringify({ vUsuario: licencias.vNombreVendedor, idUsuario: licencias.idUsuario })}
                                                            isInvalid={!!errors.vNombreVendedor}
                                                            {...register("vNombreVendedor",
                                                                {
                                                                    required: true
                                                                })
                                                            }
                                                        >
                                                            <option value="">Selecciona un vendedor</option>
                                                            {vendedores.map((vendedor, index) => {
                                                                const value = JSON.stringify({ vUsuario: vendedor.vUsuario, idUsuario: vendedor.idUsuario });//json con 2 values, idUsuario y vUsuario
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={value}>
                                                                        {vendedor.vUsuario} - {vendedor.vNombre} {vendedor.vApellidos}
                                                                    </option>)
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>UUID Empresa</Form.Label>
                                                        <Form.Control
                                                            type="hidden"
                                                            defaultValue={licencias.idLicencia}
                                                            {...register("idLicencia")}
                                                        />
                                                        <Form.Control
                                                            autoComplete="off"
                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                            type="text"
                                                            defaultValue={licencias.vUUIDEmpresa}
                                                            placeholder="vUUIDEmpresa"
                                                            isInvalid={!!errors.vUUIDEmpresa}
                                                            {...register("vUUIDEmpresa",
                                                                {
                                                                    required: 'El vUUIDEmpresa es requerido',
                                                                    pattern: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
                                                                    maxLength: 36,
                                                                    minLength: 36
                                                                })
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.vUUIDEmpresa && errors.vUUIDEmpresa.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    {/*{licencias.idCatTipoLicencia != 3 &&*/}
                                                    <>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Tipo Licencia</Form.Label>
                                                            <Form.Select
                                                                aria-label="Default select"
                                                                defaultValue={licencias.idCatTipoLicencia}
                                                                isInvalid={!!errors.idCatTipoLicencia}
                                                                {...register("idCatTipoLicencia",
                                                                    {
                                                                        required: true
                                                                    })
                                                                }
                                                            >

                                                                {tipoLicencia.map((licencia, index) => {
                                                                    if (licencias.idCatTipoLicencia == 1 || licencias.idCatTipoLicencia == 2)
                                                                        return (<option key={index} value={licencia.idCatTipoLicencia}>{licencia.vDescripcionTipo}</option>)

                                                                })}

                                                                {
                                                                    licencias.idCatTipoLicencia != 1 && licencias.idCatTipoLicencia != 2 &&
                                                                    <>
                                                                        <option value={licencias.idCatTipoLicencia}>{licencias.vDescripcionTipo}</option>
                                                                    </>

                                                                }

                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.idCatTipoLicencia && errors.idCatTipoLicencia.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </>
                                                    {/*}*/}
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Ciclo facturaci&oacute;n</Form.Label>
                                                        <Form.Select
                                                            aria-label="Default select"
                                                            defaultValue={licencias.idCatCicloFacturacion}
                                                            isInvalid={!!errors.idCatCicloFacturacion}
                                                            {...register("idCatCicloFacturacion",
                                                                {
                                                                    required: true
                                                                })
                                                            }
                                                        >
                                                            {cicloFacturacion.map((ciclo, index) => {
                                                                return (<option key={index} value={ciclo.idCatCicloFacturacion}>{ciclo.vDescripcionCiclo} - {ciclo.iNumeroDias} d&iacute;as</option>)
                                                            })}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.idCatCicloFacturacion && errors.idCatCicloFacturacion.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    {licencias.idCatTipoLicencia != 3 &&
                                                        <>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>vLatLon {licencias.vLatLonSistema && <a href="#" onClick={() => setValue("vLatLon", licencias.vLatLonSistema)}>{licencias.vLatLonSistema}</a>} </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    defaultValue={licencias.vLatLon}
                                                                    placeholder="vLatLon"
                                                                    isInvalid={!!errors.vLatLon}
                                                                    {...register("vLatLon",
                                                                        {
                                                                            required: 'El vLatLon es requerido',
                                                                            pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vLatLon && errors.vLatLon.message}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </>
                                                    }
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Label>Agregar un mensaje para el cliente</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            autoComplete="off"
                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                            defaultValue={licencias.vComentarios}
                                                            placeholder="Comentario"
                                                            isInvalid={!!errors.vComentarios}
                                                            {...register("vComentarios",
                                                                {
                                                                    maxLength: 1000
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vComentarios && errors.vComentarios.message}
                                                    </Form.Control.Feedback>


                                                    <Row>
                                                        <Col>
                                                            &nbsp;
                                                        </Col>
                                                        <Col xs="auto">
                                                            {editar &&
                                                                <IconButton
                                                                    className="ms-2"
                                                                    iconClassName="fs--2 me-1"
                                                                    variant="falcon-default"
                                                                    size="sm"
                                                                    icon="check"
                                                                    type="submit"
                                                                >
                                                                    Guardar Cambios
                                                                </IconButton>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </>
                                        ) : (
                                            <>
                                                <Table responsive striped hover size="sm" >
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>VENDEDOR:</strong></td>
                                                            <td><img src="/img/vendedor35.png"></img>&nbsp;&nbsp;{licencias.vNombreVendedor}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>UUID LICENCIA:</strong></td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {licencias.vUUIDLicencia}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={licencias.vUUIDLicencia} />
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><strong>UUID EMPRESA:</strong></td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {licencias.vUUIDEmpresa}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={licencias.vUUIDEmpresa} />
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><strong>COPIAR CLAVE DE VALIDACI&Oacute;N:</strong></td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        ***************
                                                                        ***************
                                                                        ***************
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={licencias.vClaveValidacion} />
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>TIPO LICENCIA:</strong></td>
                                                            <td> {licencias.vDescripcionTipo} </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>CICLO FACTURACI&Oacute;N:</strong></td>
                                                            <td>{licencias.vDescripcionCiclo} - {licencias.iNumeroDias} d&iacute;as</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>ESTATUS:</strong></td>
                                                            <td>
                                                                {licencias.bCancelado ? (
                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            <Badge bg='danger' className='me-2'>C A N C E L A D A </Badge>
                                                                            <Alert variant="danger">
                                                                                <p className="fs--1"><b>MOTIVO:</b> {licencias.vMotivoCancelacion}</p>
                                                                                <p className="fs--1"><b>CANCEL&Oacute;:</b> {licencias.vUsuarioCancelador}</p>
                                                                            </Alert>
                                                                        </Col>

                                                                    </Row>
                                                                ) : (licencias.bIsPeriodoPrueba ?
                                                                    (<Row className="align-items-center">
                                                                        <Col>
                                                                            <img src="/img/noactivo.png"></img>
                                                                            &nbsp;&nbsp;<Badge bg='danger' className='me-2'> {!licencias.idOrden ? "P R U E B A" : "NO ACTIVADA"} </Badge>

                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            {licencias.bIsActive &&
                                                                                <>
                                                                                    {licencias.idCatTipoLicencia == 1 &&
                                                                                        <Button
                                                                                            size="sm"
                                                                                            onClick={() => { verificarOrdenPagoLicencia(1); }}
                                                                                            variant='falcon-success'
                                                                                            className='me-2 mb-1'>Activar</Button>
                                                                                    }
                                                                                    {licencias.idCatTipoLicencia == 2 &&
                                                                                        <Button
                                                                                            size="sm"
                                                                                            onClick={() => { verificarOrdenPagoLicencia(2); }}
                                                                                            variant='falcon-success'
                                                                                            className='me-2 mb-1'>Activar</Button>
                                                                                    }

                                                                                    {licencias.idCatTipoLicencia == 3 &&
                                                                                        <Button
                                                                                            size="sm"
                                                                                            onClick={() => { verificarOrdenPagoLicencia(3); }}
                                                                                            variant='falcon-success'
                                                                                            className='me-2 mb-1'>Activar</Button>
                                                                                    }
                                                                                    {licencias.idCatTipoLicencia == 4 &&
                                                                                        <Button
                                                                                            size="sm"
                                                                                            onClick={() => { verificarOrdenPagoLicencia(4); }}
                                                                                            variant='falcon-success'
                                                                                            className='me-2 mb-1'>Activar</Button>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    ) : (licencias.dDiasFaltantes > 0 ?
                                                                        <Row>
                                                                            <Col>
                                                                                {licencias.idCatTipoLicencia == 3 ?
                                                                                    <img src="/img/nubeActiva.png"></img> : <img src="/img/activo.png"></img>}
                                                                                &nbsp;&nbsp;<Badge bg='success' className='me-2'>A C T I V A</Badge>
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Button
                                                                                    onClick={() => { setShowModalDesactivar(true) }}
                                                                                    variant='falcon-danger'>Desactivar</Button>
                                                                                {licencias.idCatTipoLicencia == 3 &&
                                                                                    <>
                                                                                        &nbsp;&nbsp;<Button
                                                                                            onClick={() => { setShowModalRenovar(true); ObtenerListaOrdenDetalleRenovacion(); }}
                                                                                            variant='falcon-success'>Renovar</Button>
                                                                                    </>}
                                                                            </Col>
                                                                        </Row>
                                                                        :
                                                                        <Row>
                                                                            <Col>
                                                                                {licencias.idCatTipoLicencia == 3 ?
                                                                                    <img src="/img/nubeVencida.png"></img> : <img src="/img/noactivo.png"></img>}

                                                                                &nbsp;&nbsp;<Badge bg='danger' className='me-2'>V E N C I D A</Badge>
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Button
                                                                                    onClick={() => { setShowModalRenovar(true); ObtenerListaOrdenDetalleRenovacion(); }}
                                                                                    variant='falcon-success'>Renovar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>INICIO LICENCIA:</strong></td>
                                                            <td>
                                                                {licencias.dInicioLicenciaUso}&nbsp;
                                                                <SoftBadge pill bg='primary' className='me-2'>hace {licencias.dDiasTranscurridos} d&iacute;as  <FontAwesomeIcon icon="clock" className="ms-2" /></SoftBadge>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td> <strong> FIN LICENCIA: </strong> </td>
                                                            <td>
                                                                <Row className="align-items-center">
                                                                    <Col >
                                                                        {licencias.dFinLicenciaUso}  <SoftBadge pill bg="warning">
                                                                            restan  {licencias.dDiasFaltantes} d&iacute;as
                                                                            <FontAwesomeIcon icon="stream" className="ms-2" /></SoftBadge>&nbsp;
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        {licencias.bIsActive &&
                                                                            licencias.bIsPeriodoPrueba &&
                                                                            <IconButton
                                                                                onClick={() => { setShowModalAsignarDias(true) }}
                                                                                iconClassName="fs--2"
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="add"
                                                                            >M&aacute;s d&iacute;as</IconButton>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>&Uacute;LTIMA CONEXI&Oacute;N:</strong></td>
                                                            <td>{licencias.dUltimaConexion}</td>
                                                        </tr>
                                                        {licencias.vLatLon &&
                                                            <tr>
                                                                <td><strong>LAT. LONG.:</strong></td>
                                                                <td><a href={"https://www.google.com/maps/place/" + licencias.vLatLon} target="_blank" rel="noreferrer">{licencias.vLatLon}</a></td>
                                                            </tr>
                                                        }
                                                        {licencias.vLatLonSistema &&
                                                            <tr>
                                                                <td><strong>LAT. LONG. SISTEMA:</strong></td>
                                                                <td><a href={"https://www.google.com/maps/place/" + licencias.vLatLonSistema} target="_blank" rel="noreferrer">{licencias.vLatLonSistema}</a></td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td><strong>UBICACI&Oacute;N:</strong></td>
                                                            <td><a href={"https://www.google.com/maps/search/" + licencias.vEstadoDepProv + "+" + licencias.vPais} target="_blank" rel="noreferrer"> {licencias.vEstadoDepProv}, {licencias.vPais}</a> </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <hr></hr>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        {licencias.vComentarios &&
                                                            <IconAlert className="mb-0" variant="warning">
                                                                <p className="mb-0">{licencias.vComentarios}</p>
                                                            </IconAlert>
                                                        }

                                                    </Col>
                                                </Row>
                                                <hr></hr>

                                                {/*licencias.vConfiguracion &&
                                                    <>
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <h5 className="mb-0">Informaci&oacute;n adicional</h5>
                                                            </Col>
                                                        </Row>
                                                        <hr></hr>
                                                        <Table responsive striped hover size="sm" >
                                                            <tbody>
                                                                {licencias.vConfiguracion && Object.keys(JSON.parse(licencias.vConfiguracion)).map((key, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{key}</td>
                                                                            <td>{Object.values(JSON.parse(licencias.vConfiguracion))[i]}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </>
                                                */}


                                                {
                                                    /*Si esta vinculada a un servidor*/
                                                    licencias.vConfiguracion ? (
                                                        <>
                                                            <Row className="align-items-center">
                                                                <Col>
                                                                    <h5 className="mb-0">Informaci&oacute;n adicional</h5>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            <Table responsive striped hover size="sm">
                                                                <tbody>
                                                                    {Object.entries(JSON.parse(licencias.vConfiguracion)).map(([key, value], index) => (
                                                                        <tr key={index}>
                                                                            <td>LICENCIA SERVIDOR: </td>
                                                                            <td id={key}>{value}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                    )
                                                        :
                                                        /*Si no esta vinculada a un servidor y es una LIC CAJA*/
                                                        licencias.idCatTipoLicencia === 2 ? (
                                                            <>
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <h5 className="mb-0">Informaci&oacute;n adicional</h5>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-center" >
                                                                    <Col>
                                                                        <span className="badge bg-danger" style={{ fontSize: "0.85rem" }}>
                                                                            No se encuentra vinculada a ningún servidor
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : (
                                                            <>

                                                            </>
                                                        )
                                                }




                                            </>

                                        )
                                    }

                                </Card.Body>
                                <Card.Footer className="border-top">
                                    <ListGroup variant="flush">
                                        {licencias.idCatTipoLicencia != 3 &&
                                            <ListGroup.Item>
                                                {!showCambiarPCAutorizada &&
                                                    <>
                                                        <p>PC Autorizada:  {licencias.vDatosPCAutorizada ?
                                                            <>
                                                                <img src="/img/pcautorizada25.png"></img> {licencias.vDatosPCAutorizada}</>
                                                            : <SoftBadge bg='danger' className='me-2'>NO ASIGNADA A PC</SoftBadge>}
                                                        </p>

                                                        {licencias.bIsActive &&
                                                            <IconButton
                                                                onClick={() => { setShowCambiarPCAutorizada(true); obtenerListaPCLogIn() }}
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="pencil-alt"
                                                            >
                                                                Cambiar PC Autorizada
                                                            </IconButton>
                                                        }
                                                    </>
                                                }

                                                {showCambiarPCAutorizada &&
                                                    <>
                                                        <Form noValidate onSubmit={handleSubmit(onSubmitAsignarPCAutorizada)}>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>PC Disponibles</Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select"
                                                                    defaultValue={licencias.vDatosPCAutorizada}
                                                                    isInvalid={!!errors.vDatosPCAutorizada}
                                                                    {...register("vDatosPCAutorizada")
                                                                    }
                                                                >
                                                                    <option value="">Dejar en blanco</option>
                                                                    {datosPCAutorizar.map((pc, index) => {
                                                                        return (<option key={index} value={pc.vDatosPcLogIn}>{pc.vDatosPcLogIn} </option>)
                                                                    })}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vDatosPCAutorizada && errors.vDatosPCAutorizada.message}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <IconButton
                                                                onClick={() => { setShowCambiarPCAutorizada(false); reset() }}
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon={faWindowClose}
                                                            >
                                                                Cancelar edici&oacute;n
                                                            </IconButton>
                                                            &nbsp;&nbsp;
                                                            <IconButton
                                                                type="submit"
                                                                iconClassName="fs--2 me-1 "
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="check"
                                                            >
                                                                Guardar
                                                            </IconButton>
                                                        </Form>
                                                    </>
                                                }
                                            </ListGroup.Item>
                                        }
                                    </ListGroup>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card className="mb-3 fs--1">
                                <Card.Header>
                                    <Row>
                                        <Col xs="auto" className="mt-2">
                                            <h6 className="text-uppercase text-600">
                                                CLIENTE
                                                <FontAwesomeIcon icon="user" className="ms-2" />
                                            </h6>
                                            {licencias.bIsActive &&
                                                <IconButton
                                                    onClick={() => { setShowModalCambiarCliente(true); obtenerListaClientes() }}
                                                    iconClassName="fs--2 me-1"
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="pencil-alt"
                                                >
                                                    Cambiar
                                                </IconButton>
                                            }
                                        </Col>
                                        <Col>
                                            <h5 className="mb-2">
                                                <Link to={"/clientes/detalle/" + licencias.idClienteAkasia}> {licencias.vNombre}</Link>
                                            </h5>
                                            <h5 className="mb-2">
                                                <Link to={"/clientes/detalle/" + licencias.idClienteAkasia}> {licencias.vApellidos}</Link>
                                            </h5>
                                            <h6>
                                                <a href={"mailto:" + licencias.vEmail}>{licencias.vEmail}</a>
                                            </h6>
                                            <h6>
                                                <a href={"https://wa.me/" + licencias.vTelefonoPersonal} target="_blank" rel="noreferrer">
                                                    <img title="Enviar WhatsApp" src="/img/whatsapp20.png"></img > {licencias.vTelefonoPersonal}
                                                </a>
                                            </h6>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className="border-top">

                                    <Table responsive striped hover size="sm" >
                                        <tbody>
                                            {licencias.idCatTipoLicencia != 3 &&
                                                <>
                                                    <tr>
                                                        <td>NOMBRE DE EMPRESA:</td>
                                                        <td>{licencias.vNombreEmpresa}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GIRO COMERCIAL:</td>
                                                        <td>{licencias.vGiroComercial}</td>
                                                    </tr>
                                                </>
                                            }
                                            <tr>
                                                <td>REGISTRADA POR:</td>
                                                <td>
                                                    <SoftBadge bg='success' className='me-2'>{licencias.vCreatedUser}</SoftBadge>
                                                </td>
                                            </tr>
                                            {licencias.vUpdatedUser && (
                                                <tr>
                                                    <td>&Uacute;LTIMA MODIFICACI&Oacute;N:</td>
                                                    <td> <SoftBadge bg='success' className='me-2'>{licencias.vUpdatedUser}</SoftBadge> </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>

                                    <ListGroup variant="flush">

                                        {hardware.length > 1 && (
                                            <ListGroup.Item>
                                                <Accordion defaultActiveKey={['0']} >
                                                    <Accordion.Item >
                                                        <Accordion.Header>
                                                            <Badge bg="danger" className="me-2 dark__bg-dark"> {hardware.length} </Badge> Violaci&oacute;n de uso
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <ListGroup variant="flush" className="fs--3">
                                                                <ListGroup.Item>
                                                                    <Alert variant="danger"> <span>Se detect&oacute; que esta licencia ha sido instalada en al menos {hardware.length} dispositivos diferentes. De acuerdo a nuestros t&eacute;rminos y condiciones, solo es posible usar esta licencia en 1 PC.</span> </Alert>

                                                                    <ListGroup variant="flush">

                                                                        {hardware.map((hw, index) => {
                                                                            return <ListGroup.Item key={index} className="border-top" >
                                                                                <Badge bg="warning" className="me-2 dark__bg-dark"> {hw.NUM} pings</Badge>{hw.vDatosPcLogIn}
                                                                            </ListGroup.Item>
                                                                        })}

                                                                    </ListGroup>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </ListGroup.Item>
                                        )}

                                        {licencias.idCatTipoLicencia == 3 &&
                                            <>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <h5 className="mb-0">Configuraci&oacute;n Datos Nube</h5>
                                                    </Col>

                                                    {/*<Col xs="auto">*/}
                                                    {/*    <IconButton*/}
                                                    {/*        onClick={() => { setEditarConfiguracion(true) }}*/}
                                                    {/*        iconClassName="fs--2 me-1"*/}
                                                    {/*        variant="falcon-default"*/}
                                                    {/*        size="sm"*/}
                                                    {/*        icon="pencil-alt"*/}
                                                    {/*    >*/}
                                                    {/*        Editar configuraci&oacute;n*/}
                                                    {/*</IconButton>*/}
                                                    {/*</Col>*/}
                                                </Row>
                                                <hr></hr>



                                                <Table responsive striped hover size="sm" >
                                                    <tbody>
                                                        <tr>
                                                            <td>Identificador</td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {datosNube.vIdentificador}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={datosNube.vIdentificador} />
                                                                    </Col>
                                                                    {licencias.bIsActive && idCatTipoUsuario == 1 &&
                                                                        <Col xs="auto">
                                                                            <IconButton
                                                                                onClick={() => { setEditarIdentificador(true) }}
                                                                                variant="falcon-default"
                                                                                size="md"
                                                                                icon="pencil-alt"
                                                                            >
                                                                            </IconButton>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Usuario</td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {datosNube.vUsuario}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={datosNube.vUsuario} />
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <IconButton
                                                                            onClick={() => { setEditarUsuarioNube(true) }}
                                                                            variant="falcon-default"
                                                                            size="md"
                                                                            icon="pencil-alt"
                                                                        >
                                                                        </IconButton>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Contrase&ntilde;a</td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {datosNube.vContrasena}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <CopyButton code={datosNube.vContrasena} />
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <IconButton
                                                                            onClick={() => { setEditarContrasenaNube(true) }}
                                                                            variant="falcon-default"
                                                                            size="md"
                                                                            icon="pencil-alt"
                                                                        >
                                                                        </IconButton>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Servidor / BD</td>
                                                            <td>
                                                                <Row>
                                                                    <Col>{datosNube.vBD}</Col>
                                                                    {licencias.bIsActive && idCatTipoUsuario == 1 &&

                                                                        <Col xs="auto">
                                                                            <IconButton
                                                                                onClick={() => { setEditarServidorBD(true) }}
                                                                                variant="falcon-default"
                                                                                size="md"
                                                                                icon="pencil-alt"
                                                                            >
                                                                            </IconButton>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <h5 className="mb-0">Usuarios Nube Akasia</h5>
                                                    </Col>
                                                    <Col xs="auto">
                                                        {licencias.bIsActive &&
                                                            <IconButton
                                                                onClick={() => { setShowModalAgregarUsuarioNube(true) }}
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="pencil-alt"
                                                            >
                                                                Nuevo Usuario Nube
                                                            </IconButton>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr></hr>

                                                {usuariosNube.map((u, index) => {
                                                    return <ListGroup.Item key={index} className="border-top fs--1"  >
                                                        {/*<Row className="flex-center">*/}
                                                        {/*    <Col xs="auto" >*/}
                                                        {/*        <Link to={"/servicios/usuarios-nube-akasia/editar/" + u.idUsuarioNubeAkasia}>*/}
                                                        {/*            <FontAwesomeIcon icon="edit" />*/}

                                                        {/*        </Link>*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}

                                                        <Col xs="auto">
                                                            <FontAwesomeIcon icon="user" className="ms-2" />&nbsp;&nbsp;{u.vNombre}&nbsp;&nbsp;
                                                            <IconButton
                                                                onClick={() => { window.location.href = `/servicios/usuarios-nube-akasia/editar/${u.idUsuarioNubeAkasia}` }}
                                                                variant="falcon-default"
                                                                size="md"
                                                                icon="pencil-alt"
                                                            >
                                                            </IconButton>
                                                        </Col>

                                                    </ListGroup.Item>
                                                })}

                                                <hr></hr>
                                                {licencias.bIsActive &&
                                                    <IconButton
                                                        variant="link"
                                                        iconClassName="fs--2 ms-1"
                                                        icon="chevron-right"
                                                        className="d-block w-100"
                                                        iconAlign="right"
                                                        onClick={() => { obtenerLicenciasRelacionadas(licencias.vUUIDEmpresa); }}
                                                    >
                                                        Ver licencias relacionadas con la Nube
                                                    </IconButton>
                                                }
                                            </>
                                        }
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            {licencias.idCatTipoLicencia != 3 &&
                                <Card className="fs--1">
                                    <Card.Header>
                                        <h5>&Uacute;ltimos inicios de sesi&oacute;n</h5>
                                    </Card.Header>
                                    <Card.Body className="border-top">

                                        {datosPCLogin.map((log, index) => (
                                            <Row
                                                key={index}
                                                className="g-0 align-items-center border-bottom px-3" >
                                                <Col md="auto" className="pe-3">
                                                    <span className={classNames('badge rounded-pill', 'badge-soft-success')}>
                                                        {log.vIpPublica}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    {log.vMachineName}
                                                </Col>
                                                <Col md>
                                                    <code>
                                                        {log.vVersion}
                                                    </code>
                                                </Col>
                                                <Col>
                                                    &nbsp;<OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                <strong>{log.vDatosPcLogIn}</strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img src="/img/pcautorizada25.png"></img>
                                                    </OverlayTrigger>
                                                    &nbsp;<OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                <strong>{log.vConectionString}</strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img src="/img/servidor25.png"></img>
                                                    </OverlayTrigger>
                                                    &nbsp;{log.vLatLonSistema && <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                <strong>{log.vLatLonSistema}</strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <a href={"https://www.google.com/maps/place/" + log.vLatLonSistema} target="_blank" rel="noreferrer"><img src="/img/ubicacion25.png"></img></a>
                                                    </OverlayTrigger>}
                                                </Col>
                                                <Col md="auto">
                                                    {log.dCreatedDate}
                                                </Col>
                                            </Row>
                                        ))}
                                    </Card.Body>

                                </Card>
                            }
                        </Col>
                    </Row>
                    <Row className="g-3 mb-3">
                        <Col lg={12}>
                            <Card className="mt-3 fs--1">
                                <Card.Header className="border-bottom border-200">
                                    <h5 className="mb-0">Servicios Adicionales Vinculados</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-3">
                                        {polizasRelacionadas.map((poliza, index) => {
                                            return (
                                                <Col key={index} md={6} xxl={4}>
                                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                                        <div
                                                            className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-success`}
                                                        >
                                                            <FiFileText className="text-info fs-2" />
                                                        </div>
                                                        <h5 className="mt-3 mb-2">
                                                            <Link to={"/polizas/detalle/" + poliza.idPoliza} className="text-900 hover-primary stretched-link">
                                                                {poliza.vNombreTipoPoliza}
                                                            </Link>
                                                        </h5>
                                                        <h5 className="w-75 mx-auto text-600 mb-x1"> P&oacute;liza {poliza.idPoliza}</h5>
                                                        <h6>{poliza.dDiasFaltantes <= 0 &&
                                                            <>
                                                                <img src="/img/polizaVencida.png"></img >
                                                                <Badge bg="danger" className="me-2 dark__bg-dark"> VENCIDA </Badge>
                                                            </>}
                                                            {poliza.dDiasFaltantes > 60 &&
                                                                <>
                                                                    <img src="/img/polizaActiva.png"></img >
                                                                    <SoftBadge bg="success" className="me-2 dark__bg-dark"> ACTIVA (restan {poliza.dDiasFaltantes} d&iacute;as)</SoftBadge>

                                                                </>}
                                                            {poliza.dDiasFaltantes <= 60 && poliza.dDiasFaltantes > 0 &&
                                                                <>
                                                                    <img src="/img/polizaPorVencer.png"></img >
                                                                    <SoftBadge bg="warning" className="me-2 dark__bg-dark"> ACTIVA (restan {poliza.dDiasFaltantes} d&iacute;as)</SoftBadge>

                                                                </>
                                                            }</h6>
                                                        <h6><span>Vencimiento: {poliza.dFechaVencimiento}</span></h6>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                        {nubes.map((nube, index) => {
                                            return (
                                                <Col key={index} md={6} xxl={4}>
                                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                                        <div
                                                            className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-success`}
                                                        >
                                                            <FiFileText className="text-info fs-2" />
                                                        </div>
                                                        <h5 className="mt-3 mb-2">
                                                            <Link to={"/licencias/detalle/" + nube.idLicencia} className="text-900 hover-primary stretched-link">
                                                                Nube Akasia
                                                            </Link>
                                                        </h5>
                                                        <h5 className="w-75 mx-auto text-600 mb-x1"> {nube.idLicencia}</h5>
                                                        <h6>{nube.dDiasFaltantes <= 0 ?
                                                            (
                                                                <>
                                                                    <img src="/img/nubeVencida.png"></img >
                                                                    <Badge bg="danger" className="me-2 dark__bg-dark"> VENCIDA </Badge>
                                                                </>

                                                            ) : (
                                                                <>
                                                                    <img src="/img/nubeActiva.png"></img >
                                                                    <SoftBadge bg="success" className="me-2 dark__bg-dark"> ACTIVA (restan {nube.dDiasFaltantes} d&iacute;as)</SoftBadge>
                                                                </>

                                                            )}</h6>
                                                        <h6><span>Vencimiento: {nube.dFinLicenciaUso}</span></h6>
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="g-3 mb-3">
                        <Col lg={12}>
                            <Card className="mb-3">
                                <Card.Header>
                                    <h5 className="mb-0">&Uacute;ltimos Logs del licenciatario</h5>
                                </Card.Header>
                                <Card.Body className="border-top p-0">
                                    <AdvanceTableWrapper
                                        columns={columnsLogs}
                                        data={logServicios}

                                        sortable
                                        pagination
                                        perPage={15}
                                    >
                                        <Card className="px-3">
                                            <Card.Header>

                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <AdvanceTable
                                                    table
                                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                                    rowClassName="align-middle white-space-nowrap"
                                                    tableProps={{
                                                        size: 'sm',
                                                        striped: true,
                                                        hover: true,
                                                        className: 'fs--2 mb-0 overflow-hidden'
                                                    }}
                                                />

                                            </Card.Body>
                                            <Card.Footer>
                                                <AdvanceTableFooter
                                                    rowCount={logServicios.length}
                                                    table
                                                    rowInfo
                                                    navButtons
                                                    rowsPerPageSelection
                                                />
                                            </Card.Footer>
                                        </Card>
                                    </AdvanceTableWrapper>
                                </Card.Body>
                                <Card.Footer className="bg-light p-0">

                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                    {licencias.idCatTipoLicencia != 3 &&
                        <Row className="g-3 mb-3">
                            <Col lg={12}>
                                <Card className="mb-3">
                                    <Card.Header>
                                        <h5 className="mb-0">Mapa - Ubicaci&oacute;n de la licencia</h5>
                                    </Card.Header>
                                    <Card.Body className="border-top p-0">
                                        {cargando ? (
                                            <h3>
                                                <Spinner className="text-inline" animation="border" variant="primary" /> Cargando...
                                            </h3>
                                        ) : (
                                            licencias.vLatLon &&
                                            <GoogleMap
                                                initialCenter={{
                                                    lat: parseFloat(licencias.vLatLon.split(',')[0]),
                                                    lng: parseFloat(licencias.vLatLon.split(',')[1])
                                                }}
                                                mapStyle="Default"
                                                darkStyle="Default"
                                                className="vh-50"
                                            >

                                                <p className="fs--1 mb-0">
                                                    {licencias.vLatLon}
                                                </p>
                                            </GoogleMap>
                                        )}
                                    </Card.Body>
                                    <Card.Footer className="bg-light p-0">

                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row className="g-3 mb-3">
                        <Col lg={12}>
                            <Card className="mb-3 fs--1">
                                <Card.Header>
                                    <h5 className="mb-0">Historial de movimientos administrativos de la licencia</h5>
                                </Card.Header>
                                <Card.Body className="border-top p-0">
                                    {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>) : (
                                        logs.map((log, index) => (
                                            <Row
                                                key={index}
                                                className="g-0 align-items-center border-bottom px-3" >
                                                <Col lg={1} className="pe-3">
                                                    <span className={classNames('badge rounded-pill', 'badge-soft-success')}   >
                                                        {log.vIP}
                                                    </span>
                                                </Col>
                                                <Col lg={6} className="fs--1">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                <strong>{log.vJSON}</strong>
                                                            </Tooltip>
                                                        }>
                                                        <img src="/img/json25.png"></img>
                                                    </OverlayTrigger>
                                                    {log.vMovimiento}
                                                </Col>
                                                <Col lg={3} >
                                                    <code className="fs--1">
                                                        {log.vCreatedUser}
                                                    </code>
                                                </Col>
                                                <Col log={2} md="auto">
                                                    {log.dCreatedDate}

                                                </Col>
                                            </Row>
                                        ))
                                    )}
                                </Card.Body>
                                <Card.Footer className="bg-light p-0">
                                    <IconButton
                                        variant="link"
                                        iconClassName="fs--2 ms-1"
                                        icon="chevron-right"
                                        className="d-block w-100"
                                        iconAlign="right"
                                        to="#!"
                                    >
                                        Ver m&aacute;s movimientos
                                    </IconButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>






                    {showModalCancelacion &&
                        <Modal
                            show={showModalCancelacion}
                            onHide={() => setShowModalCancelacion(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitCancelacion)}>
                                <Modal.Header>
                                    <Modal.Title >Cancelar Licencia</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalCancelacion(false); reset() }} />
                                </Modal.Header>
                                <Modal.Body>
                                    <h4>&iquest;Est&aacute; seguro que desea cancelar la licencia?</h4>
                                    <Row>
                                        <Col md={12}>
                                            Este proceso har&aacute; que la licencia se cambie a un estado de  <SoftBadge bg='danger' className='me-2'>NO ACTIVO</SoftBadge>.
                                            Esto significa que el usuario no podr&aacute; usar la licencia a&uacute;n cuando se encuentre en MODO PRUEBA. Este proceso <SoftBadge bg='danger' className='me-2'>no podr&aacute; ser revertido.</SoftBadge> Si la licencia a cancelar es una licencia tipo Servidor, se cancelar&aacute;n las p&oacute;lizas y licencias Tipo caja Adicional vinculadas.
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <hr></hr>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Motivo de cancelaci&oacute;n:</Form.Label>
                                                <Form.Control
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    as="textarea"
                                                    name="vMotivo"
                                                    autoComplete="off"
                                                    placeholder="Escribe el motivo de cancelaci&oacute;n"
                                                    isInvalid={!!errors.vMotivo}
                                                    {...register('vMotivo', {
                                                        required: 'Es necesario escribir el motivo'
                                                    })}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vMotivo && errors.vMotivo.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalCancelacion(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> : <Button type="submit" variant='danger' >Cancelar licencia</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {showModalAsignarDias &&
                        <Modal
                            show={showModalAsignarDias}
                            onHide={() => setShowModalAsignarDias(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitAsignarDias)}>
                                <Modal.Header>
                                    <Modal.Title>Asignar d&iacute;as de Prueba</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalAsignarDias(false); reset() }} />

                                </Modal.Header>
                                <Modal.Body>
                                    El n&uacute;mero de d&iacute;as que desea asignar a la licencia por
                                    <SoftBadge bg='danger' className='me-2'>PERIODO DE PRUEBA</SoftBadge>
                                    Importante mencionar que esta cantidad se toma en cuenta apartir del d&iacute;a de hoy.

                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel
                                                    label="Numero de d&iacute;as"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        name="iNumeroDias"
                                                        type="number"
                                                        defaultValue="5"
                                                        isInvalid={!!errors.iNumeroDias}
                                                        {...register('iNumeroDias', {
                                                            required: 'Este campo solo acepta numeros',
                                                            maxLength: 2,
                                                            minLength: 1
                                                        })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.iNumeroDias && errors.iNumeroDias.message}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalAsignarDias(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> :
                                        <Button type="submit" variant='success' >Asignar d&iacute;as</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {showModalRelacionadas &&
                        <Modal
                            show={showModalRelacionadas}
                            onHide={() => setShowModalRelacionadas(false)}
                            size="lg"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Licencias relacionadas por UUID Empresa. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalRelacionadas(false); reset() }} />

                            </Modal.Header>
                            <Modal.Body>
                                <p>Se enlistan las licencias que est&aacute;n relacionadas con el n&uacute;mero UUID Empresa.</p>
                                <p>
                                    <strong>
                                        UUID EMPRESA: {licencias.vUUIDEmpresa}
                                    </strong>
                                </p>
                                <Table responsive striped hover size="sm">
                                    <tbody>
                                        <tr>
                                            <td>ID</td>
                                            <td>TIPO</td>
                                            <td>ESTADO</td>
                                            <td>LICENCIA</td>
                                            <td>&Uacute;LTIMA CONEXI&Oacute;N</td>
                                        </tr>
                                        {licenciasRelacionadas.map((relacion, index) => {
                                            return (
                                                <tr key={index} >
                                                    <td>{relacion.idLicencia}</td>
                                                    <td>
                                                        {relacion.vDescripcionTipo}
                                                    </td>
                                                    <td>
                                                        {relacion.bCancelado ?
                                                            (
                                                                <Badge bg="danger" className="me-2 dark__bg-dark"> CANCELADA </Badge>
                                                            ) : (
                                                                relacion.bIsPeriodoPrueba ?
                                                                    (
                                                                        <Badge bg="danger" className="me-2 dark__bg-dark"> PRUEBA </Badge>

                                                                    ) : (

                                                                        <Badge bg="success" className="me-2 dark__bg-dark"> ACTIVA </Badge>
                                                                    )
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link to={"/licencias/detalle/" + relacion.idLicencia}>
                                                            {relacion.vUUIDLicencia}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {relacion.dUltimaConexion}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { setShowModalRelacionadas(false); reset() }} variant='primary' >Cerrar</Button>
                            </Modal.Footer>

                        </Modal>
                    }

                    {showModalActivar &&
                        <Modal
                            show={showModalActivar}
                            onHide={() => setShowModalActivar(false)}
                            size="lg"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitActivar)}>
                                <Modal.Header>
                                    <Modal.Title>Activar Licencia {licencias.vDescripcionTipo}</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalActivar(false); reset() }} />

                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col>
                                            Verifica y completa la siguiente informaci&oacute;n para activar la licencia.
                                            <hr></hr>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Fecha Inicio</Form.Label>

                                                <Form.Control
                                                    type="date"
                                                    {...register("dInicioLicenciaUso",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                    isInvalid={!!errors.dInicioLicenciaUso} />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.dInicioLicenciaUso && errors.dInicioLicenciaUso.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Ciclo facturaci&oacute;n</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    defaultValue={licencias.idCatCicloFacturacion}
                                                    isInvalid={!!errors.idCatCicloFacturacion}
                                                    {...register("idCatCicloFacturacion",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    {cicloFacturacion.map((ciclo, index) => {
                                                        return (<option key={index} value={ciclo.idCatCicloFacturacion}>{ciclo.vDescripcionCiclo} - {ciclo.iNumeroDias} d&iacute;as</option>)
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idCatCicloFacturacion && errors.idCatCicloFacturacion.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {licencias.idCatTipoLicencia == 2 || licencias.idCatTipoLicencia == 4 ?
                                                (
                                                    <>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Elije el Servidor al que se vincula la Caja</Form.Label>
                                                            <Form.Select
                                                                aria-label="Default select"
                                                                isInvalid={!!errors.vUUIDLicenciaServidor}
                                                                {...register("vUUIDLicenciaServidor", {
                                                                    required: 'La licencia Servidor es requerida',
                                                                })
                                                                }
                                                            >
                                                                <option value="">Elige Licencia Servidor</option>
                                                                {licenciasServidor.map((servidor, index) => {
                                                                    return (<option key={index} value={servidor.vUUIDLicencia}> {servidor.bIsPeriodoPrueba && 'pp <<<'} {servidor.vUUIDLicencia}</option>)
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.vUUIDLicenciaServidor && errors.vUUIDLicenciaServidor.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>vLatLon <a href="https://www.google.com.mx/maps" rel="noreferrer" target="_blank">Abrir Google Maps</a></Form.Label>
                                                            <Form.Control
                                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                type="text"
                                                                autoComplete="off"
                                                                defaultValue={licencias.vLatLon}
                                                                placeholder="vLatLon"
                                                                isInvalid={!!errors.vLatLon}
                                                                {...register("vLatLon",
                                                                    {
                                                                        required: 'El vLatLon es requerido',
                                                                        pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                                                                    })
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.vLatLon && errors.vLatLon.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>P&oacute;liza Soporte Gratis</Form.Label>
                                                            <Form.Select
                                                                aria-label="Default select"
                                                                isInvalid={!!errors.idCatPolizaSoporte}
                                                                {...register("idCatPolizaSoporte", {
                                                                    required: 'La poliza es requerida',
                                                                })
                                                                }
                                                            >
                                                                <option value="">Elige una p&oacute;liza</option>
                                                                {tipoPolizas.map((polizaType, index) => {
                                                                    return polizaType.idCatTipoPoliza == 1 && (<option key={index} value={polizaType.idCatPoliza}>{polizaType.vNombrePoliza} - {polizaType.iNumeroDias} d&iacute;as</option>)
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.idCatPolizaSoporte && errors.idCatPolizaSoporte.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>P&oacute;liza Actualizaciones Gratis</Form.Label>
                                                            <Form.Select
                                                                aria-label="Default select"
                                                                isInvalid={!!errors.idCatPolizaActualizaciones}
                                                                {...register("idCatPolizaActualizaciones", {
                                                                    required: 'La poliza es requerida',
                                                                })
                                                                }
                                                            >
                                                                <option value="">Elige una p&oacute;liza</option>
                                                                {tipoPolizas.map((polizaType, index) => {
                                                                    return polizaType.idCatTipoPoliza == 2 && (<option key={index} value={polizaType.idCatPoliza}>{polizaType.vNombrePoliza} - {polizaType.iNumeroDias} d&iacute;as</option>)
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.idCatPolizaActualizaciones && errors.idCatPolizaActualizaciones.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalActivar(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> : <Button type="submit" variant='success' >A C T I V A R</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {showModalActivarNube &&
                        <Modal
                            show={showModalActivarNube}
                            onHide={() => setShowModalActivarNube(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitActivar)}>
                                <Modal.Header>
                                    <Modal.Title>Activar Licencia {licencias.vDescripcionTipo}</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalActivarNube(false); reset() }} />

                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col>
                                            Verifica y completa la siguiente informaci&oacute;n.
                                            <hr></hr>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={12} xs={12} sm="{12}">
                                            <Form.Label>Fecha Inicio de Licencia</Form.Label>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="date"
                                                    {...register("dInicioLicenciaUso",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                    isInvalid={!!errors.dInicioLicenciaUso} />
                                                {/*
                                                <DatePicker
                                                    {...register("dInicioLicenciaUso",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                    md={12}
                                                    selected={date}
                                                    onChange={(date) => { setValue("dInicioLicenciaUso", date); setDate(date) }}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    placeholderText="Selecciona Fecha Inicio Licencia Uso"
                                                    dateFormat="dd/MMM/yyyy"
                                                    maxDate={new Date()}
                                                    isInvalid={!!errors.dInicioLicenciaUso}

                                                />*/}
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.dInicioLicenciaUso && errors.dInicioLicenciaUso.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Ciclo facturaci&oacute;n</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    defaultValue={licencias.idCatCicloFacturacion}
                                                    isInvalid={!!errors.idCatCicloFacturacion}
                                                    {...register("idCatCicloFacturacion",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    {cicloFacturacion.map((ciclo, index) => {
                                                        return (<option key={index} value={ciclo.idCatCicloFacturacion}>{ciclo.vDescripcionCiclo} - {ciclo.iNumeroDias} d&iacute;as</option>)
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idCatCicloFacturacion && errors.idCatCicloFacturacion.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalActivarNube(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> : <Button type="submit" variant='success' >A C T I V A R</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {showModalRenovar &&
                        <Modal
                            show={showModalRenovar}
                            onHide={() => setShowModalRenovar(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitRenovar)}>
                                <Modal.Header>
                                    <Modal.Title>Renovar Licencia {licencias.vDescripcionTipo}</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalRenovar(false); reset() }} />

                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col>
                                            Verifica y completa la siguiente informaci&oacute;n.
                                            <hr></hr>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Ciclo facturaci&oacute;n</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    defaultValue={licencias.idCatCicloFacturacion}
                                                    isInvalid={!!errors.idCatCicloFacturacion}
                                                    {...register("idCatCicloFacturacion",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    {cicloFacturacion.map((ciclo, index) => {
                                                        return (<option key={index} value={ciclo.idCatCicloFacturacion}>{ciclo.vDescripcionCiclo} - {ciclo.iNumeroDias} d&iacute;as</option>)
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idCatCicloFacturacion && errors.idCatCicloFacturacion.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Selecciona la orden del pago de la p&oacute;liza</Form.Label>
                                                <Form.Label className="fs--1">Solo podrán aplicarse renovaciones con órdenes con fecha de pago de máximo 3 días.</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idOrdenDetalle}
                                                    {...register("idOrdenDetalle",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona la orden</option>
                                                    {ordenDetalleRenovacion.map((od, index) => {
                                                        return (<option key={index} value={od.idOrdenDetalle}>{od.vCodigoBarras} - Odn #{od.idOrden} - {od.dFechaVenta} - {parseFloat(od.dPrecioUnitarioImpuestos).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                            style: 'currency', currency: 'MXN'
                                                        })} {od.vMoneda}</option>)
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idOrdenDetalle && errors.idOrdenDetalle.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalRenovar(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> : <Button type="submit" variant='success' >R E N O V A R</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {showModalDesactivar &&
                        <Modal
                            show={showModalDesactivar}
                            onHide={() => setShowModalDesactivar(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitDesactivar)}>
                                <Modal.Header>
                                    <Modal.Title>Desactivar Licencia {licencias.vDescripcionTipo}</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalDesactivar(false); reset() }} />

                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <h5>&#191;Est&aacute; seguro que desea desactivar la licencia?</h5>
                                        <p>La licencia cambiar&aacute; a un estado de <SoftBadge pill bg='danger' className='me-2'>PERIODO DE PRUEBA</SoftBadge> Ser&aacute; posible activarla nuevamente pero debes considerar que las p&oacute;lizas vinculadas a esta licencia se cancelar&aacute;n. </p>
                                    </Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Escribe: DESACTIVAR</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            isInvalid={!!errors.vConfirmacion}
                                            {...register("vConfirmacion",
                                                {
                                                    required: 'Es necesario que escribas: DESACTIVAR',
                                                    pattern: /DESACTIVAR$/
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.vConfirmacion && errors.vConfirmacion.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalDesactivar(false); reset() }} variant='primary' >Cerrar</Button>
                                    {procesandoModal ? <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button> : <Button type="submit" variant='danger' >D E S A C T I V A R</Button>}
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }

                    {//editarConfiguracion &&
                        //    <Modal
                        //        show={editarConfiguracion}
                        //        onHide={() => setEditarConfiguracion(false)}
                        //        size="md"
                        //        backdrop="static"
                        //        aria-labelledby="contained-modal-title-vcenter"
                        //        centered
                        //    >
                        //        <Modal.Header>
                        //            <Modal.Title>Editar Configuraci&oacute;n Nube. </Modal.Title>
                        //            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setEditarConfiguracion(false); reset() }} />

                        //        </Modal.Header>
                        //        <Modal.Body >
                        //            <Form noValidate onSubmit={handleSubmit(onSubmitDatosConfiguracion)}>
                        //                <>
                        //                    <Form.Control
                        //                        type="hidden"
                        //                        defaultValue={datosNube.idSyncCredenciales}
                        //                        isInvalid={!!errors.idSyncCredenciales}
                        //                        {...register("idSyncCredenciales")
                        //                        }
                        //                    />
                        //                    {idCatTipoUsuario == 1 &&
                        //                        <Form.Group className="mb-3" >
                        //                            <Form.Label>Identificador </Form.Label>
                        //                            <Form.Control
                        //                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                        //                                type="text"
                        //                                autoComplete="off"
                        //                                defaultValue={datosNube.vIdentificador}
                        //                                isInvalid={!!errors.vIdentificador}
                        //                                {...register("vIdentificador",
                        //                                    {
                        //                                        required: 'El Identificador es requerido',
                        //                                        pattern: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
                        //                                        maxLength: 36,
                        //                                        minLength: 36
                        //                                    })
                        //                                }
                        //                            />
                        //                            <Form.Control.Feedback type="invalid">
                        //                                {errors.vIdentificador && errors.vIdentificador.message}
                        //                            </Form.Control.Feedback>
                        //                        </Form.Group>
                        //                    }
                        //                    <Form.Group className="mb-3" >
                        //                        <Form.Label>Usuario</Form.Label>
                        //                        <Form.Control
                        //                            type="text"
                        //                            autoComplete="off"
                        //                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                        //                            defaultValue={datosNube.vUsuario}
                        //                            placeholder="vUsuario"
                        //                            isInvalid={!!errors.vUsuario}
                        //                            {...register("vUsuario",
                        //                                {
                        //                                    required: 'El vUsuario es requerido'
                        //                                })
                        //                            }
                        //                        />
                        //                        <Form.Control.Feedback type="invalid">
                        //                            {errors.vUsuario && errors.vUsuario.message}
                        //                        </Form.Control.Feedback>
                        //                    </Form.Group>
                        //                    <Form.Group className="mb-3" >
                        //                        <Form.Label>Contrase&ntilde;a</Form.Label>
                        //                        <Form.Control
                        //                            autoComplete="off"
                        //                            type="text"
                        //                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                        //                            defaultValue={datosNube.vContrasena}
                        //                            placeholder="vContrasena"
                        //                            isInvalid={!!errors.vContrasena}
                        //                            {...register("vContrasena",
                        //                                {
                        //                                    required: 'El vContrasena es requerido'
                        //                                })
                        //                            }
                        //                        />
                        //                        <Form.Control.Feedback type="invalid">
                        //                            {errors.vContrasena && errors.vContrasena.message}
                        //                        </Form.Control.Feedback>
                        //                    </Form.Group>
                        //                    <Form.Group className="mb-3">
                        //                        <Form.Label>BD / Server</Form.Label>
                        //                        <Form.Select
                        //                            aria-label="Default select"
                        //                            defaultValue={datosNube.vBD}
                        //                            isInvalid={!!errors.vBD}
                        //                            {...register("vBD",
                        //                                {
                        //                                    required: true
                        //                                })
                        //                            }
                        //                        >
                        //                            <option value="">Selecciona Servidor BD</option>
                        //                            {BDServer.map((bd, index) => {
                        //                                return (<option key={index} value={bd.Value}>{bd.Text}</option>)
                        //                            })}
                        //                        </Form.Select>
                        //                    </Form.Group>
                        //                </>
                        //                <Modal.Footer>
                        //                    <Button onClick={() => { setEditarConfiguracion(false); reset() }} variant='primary' >Cerrar</Button>
                        //                    {procesandoModal ? <Button variant="primary" disabled>
                        //                        <Spinner
                        //                            as="span"
                        //                            animation="grow"
                        //                            size="sm"
                        //                            role="status"
                        //                            aria-hidden="true"
                        //                        />
                        //                        Procesando...
                        //                    </Button> : <Button type="submit" variant='success' >Guardar cambios</Button>}
                        //                </Modal.Footer>
                        //            </Form>
                        //        </Modal.Body>
                        //    </Modal>
                    }

                    {showModalAgregarUsuarioNube &&
                        <Modal
                            show={showModalAgregarUsuarioNube}
                            onHide={() => setShowModalAgregarUsuarioNube(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Registrar Usuario Nube Akasia. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalAgregarUsuarioNube(false); reset() }} />
                            </Modal.Header>
                            <Modal.Body >
                                <Form noValidate onSubmit={handleSubmit(onSubmitNuevoUsuarioNube)}>
                                    <Row className="g-3 mb-3">
                                        {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)
                                            :
                                            (
                                                <>
                                                    <Col lg={12}>
                                                        <>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Usuario</Form.Label>
                                                                <IconButton
                                                                    className="ms-2"
                                                                    iconClassName="fs--2 me-1"
                                                                    variant="falcon-primary"
                                                                    size="sm"
                                                                    icon="circle"
                                                                    onClick={() => { ObtenerCadenaRandom('Usuario') }}
                                                                >
                                                                    Generar
                                                                </IconButton>
                                                                <Form.Control
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                    isInvalid={!!errors.vUsuario}
                                                                    {...register("vUsuario",
                                                                        {
                                                                            required: 'El vUsuario es requerido',
                                                                            maxLength: 40
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vUsuario && errors.vUsuario.message}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">

                                                                <Form.Label>Contrase&ntilde;a</Form.Label>

                                                                <IconButton
                                                                    className="ms-2"
                                                                    iconClassName="fs--2 me-1"
                                                                    variant="falcon-primary"
                                                                    size="sm"
                                                                    icon="circle"
                                                                    onClick={() => { ObtenerCadenaRandom('Contrasena') }}

                                                                >
                                                                    Generar
                                                                </IconButton>

                                                                <Form.Control
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                    isInvalid={!!errors.vContrasena}
                                                                    {...register("vContrasena",
                                                                        {
                                                                            required: 'El vContrasena es requerido',
                                                                            maxLength: 40
                                                                        })
                                                                    }
                                                                />

                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vContrasena && errors.vContrasena.message}
                                                                </Form.Control.Feedback>

                                                            </Form.Group>

                                                            <Form.Group className="mb-3" >
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Control
                                                                        type="hidden"
                                                                        defaultValue={licencias.idClienteAkasia}
                                                                        {...register("idClienteAkasia")
                                                                        }
                                                                    >
                                                                    </Form.Control>

                                                                </Form.Group>
                                                            </Form.Group>


                                                            <Form.Group className="mb-3" >
                                                                <Form.Control
                                                                    type="hidden"
                                                                    defaultValue={id}
                                                                    {...register("idLicencia")
                                                                    }
                                                                >
                                                                </Form.Control>
                                                            </Form.Group>


                                                            {/*SPRINT_18*/}
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>Sucursal Asignada</Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select"
                                                                    isInvalid={!!errors.idSucursal}
                                                                    {...register("idSucursal",
                                                                        {
                                                                            required: true
                                                                        })
                                                                    }
                                                                >
                                                                    {Sucursales.map((sucursal, index) => {
                                                                        return (<option key={index} value={sucursal.idSucursal}>{sucursal.idSucursal} - {sucursal.vNombreSucursal}</option>)
                                                                    })}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.idSucursal && errors.idSucursal.message}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>


                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>Nombre y apellidos del usuario</Form.Label>
                                                                <Form.Control
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                    placeholder="vNombre"
                                                                    isInvalid={!!errors.vNombre}
                                                                    {...register("vNombre",
                                                                        {
                                                                            required: 'El nombre del cliente es requerido',
                                                                            maxLength: 200,
                                                                            minLength: 1
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vNombre && errors.vNombre.message}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>


                                                            <Row>
                                                                <Col>
                                                                    &nbsp;
                                                                </Col>
                                                                <Col xs="auto">
                                                                    {procesandoModal ?
                                                                        <Button variant="primary" disabled>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                            Procesando...
                                                                        </Button>
                                                                        :
                                                                        <IconButton
                                                                            className="ms-2"
                                                                            iconClassName="fs--2 me-1"
                                                                            variant="falcon-default"
                                                                            size="sm"
                                                                            icon="check"
                                                                            type="submit"
                                                                        >
                                                                            Guardar Cambios
                                                                        </IconButton>}
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    </Col>
                                                </>
                                            )}
                                    </Row>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    }

                    {showModalCambiarCliente &&
                        <Modal
                            show={showModalCambiarCliente}
                            onHide={() => setShowModalCambiarCliente(false)}
                            size="lg"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Cambiar cliente Akasia. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalCambiarCliente(false); reset() }} />
                            </Modal.Header>
                            <Modal.Body style={{ padding: "0px" }}>
                                <AdvanceTableWrapper
                                    columns={columns}
                                    data={clientes}
                                    sortable
                                    pagination
                                    perPage={10}
                                >
                                    <Card>
                                        <Card.Header>
                                        </Card.Header>
                                        <Card.Body className="p-0">
                                            <Row className="flex-end-center mb-3">
                                                <Col sm={8} lg={4}>
                                                    <AdvanceTableSearchBox table />
                                                </Col>
                                            </Row>
                                            <AdvanceTable
                                                table
                                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                                rowClassName="align-middle white-space-nowrap"
                                                tableProps={{
                                                    size: 'sm',
                                                    striped: true,
                                                    hover: true,
                                                    className: 'fs--1 mb-0 overflow-hidden'
                                                }}
                                            />

                                        </Card.Body>
                                        <Card.Footer>

                                            <AdvanceTableFooter
                                                rowCount={licencias.length}
                                                table
                                                rowInfo
                                                navButtons
                                                rowsPerPageSelection
                                            />
                                        </Card.Footer>
                                    </Card>

                                </AdvanceTableWrapper>
                            </Modal.Body>

                        </Modal>
                    }

                    {showModalEliminar &&
                        <Modal
                            show={showModalEliminar}
                            onHide={() => setShowModalEliminar(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitEliminar)}>
                                <Modal.Header>
                                    <Modal.Title >Eliminar Licencia</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalEliminar(false); reset() }} />
                                </Modal.Header>
                                <Modal.Body>
                                    <h4>&iquest;Est&aacute; seguro que desea eliminar la licencia?</h4>
                                    <Row>
                                        <Col md={12}>
                                            Es importante que contemple que al eliminar una licencia de uso tipo Servidor, se eliminar&aacute;n tambi&eacute;n las p&oacute;lizas que se encuentran vinculadas.
                                        </Col>
                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalEliminar(false); reset() }} variant='primary' >Cerrar</Button>
                                    <Button type="submit" variant='danger' >Eliminar licencia</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }


                    {/*Modales Configuracion Datos Nube*/}
                    {editarIdentificador &&
                        <Modal
                            show={editarIdentificador}
                            onHide={() => setEditarIdentificador(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Editar Identificador. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setEditarIdentificador(false); reset() }} />

                            </Modal.Header>
                            <Modal.Body >
                                <Form noValidate onSubmit={handleSubmit(onSubmitIdentificador)}>
                                    <>
                                        <Form.Control
                                            type="hidden"
                                            defaultValue={datosNube.idSyncCredenciales}
                                            isInvalid={!!errors.idSyncCredenciales}
                                            {...register("idSyncCredenciales")
                                            }
                                        />
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Identificador </Form.Label>
                                            <Form.Control
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                type="text"
                                                autoComplete="off"
                                                defaultValue={datosNube.vIdentificador}
                                                isInvalid={!!errors.vIdentificador}
                                                {...register("vIdentificador",
                                                    {
                                                        required: 'El Identificador es requerido',
                                                        //pattern: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
                                                        maxLength: 36,
                                                        minLength: 36
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vIdentificador && errors.vIdentificador.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </>
                                    <Modal.Footer>
                                        <Button onClick={() => { setEditarIdentificador(false); reset() }} variant='primary' >Cerrar</Button>
                                        {procesandoModal ? <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button> : <Button type="submit" variant='success' >Guardar cambios</Button>}
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    }

                    {editarUsuarioNube &&
                        <Modal
                            show={editarUsuarioNube}
                            onHide={() => setEditarUsuarioNube(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Editar Usuario. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setEditarUsuarioNube(false); reset() }} />

                            </Modal.Header>
                            <Modal.Body >
                                <Form noValidate onSubmit={handleSubmit(onSubmitUsuarioNube)}>
                                    <>
                                        <Form.Control
                                            type="hidden"
                                            defaultValue={datosNube.idSyncCredenciales}
                                            isInvalid={!!errors.idSyncCredenciales}
                                            {...register("idSyncCredenciales")
                                            }
                                        />
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Usuario</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                defaultValue={datosNube.vUsuario}
                                                placeholder="vUsuario"
                                                isInvalid={!!errors.vUsuario}
                                                {...register("vUsuario",
                                                    {
                                                        required: 'El vUsuario es requerido'
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vUsuario && errors.vUsuario.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                    <Modal.Footer>
                                        <Button onClick={() => { setEditarUsuarioNube(false); reset() }} variant='primary' >Cerrar</Button>
                                        {procesandoModal ? <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button> : <Button type="submit" variant='success' >Guardar cambios</Button>}
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    }

                    {editarContrasenaNube &&
                        <Modal
                            show={editarContrasenaNube}
                            onHide={() => setEditarContrasenaNube(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Editar Contrase&ntilde;a. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setEditarContrasenaNube(false); reset() }} />

                            </Modal.Header>
                            <Modal.Body >
                                <Form noValidate onSubmit={handleSubmit(onSubmitContrasenaNube)}>
                                    <>
                                        <Form.Control
                                            type="hidden"
                                            defaultValue={datosNube.idSyncCredenciales}
                                            isInvalid={!!errors.idSyncCredenciales}
                                            {...register("idSyncCredenciales")
                                            }
                                        />
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Contrase&ntilde;a</Form.Label>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                defaultValue={datosNube.vContrasena}
                                                placeholder="vContrasena"
                                                isInvalid={!!errors.vContrasena}
                                                {...register("vContrasena",
                                                    {
                                                        required: 'El vContrasena es requerido'
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vContrasena && errors.vContrasena.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </>
                                    <Modal.Footer>
                                        <Button onClick={() => { setEditarContrasenaNube(false); reset() }} variant='primary' >Cerrar</Button>
                                        {procesandoModal ? <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button> : <Button type="submit" variant='success' >Guardar cambios</Button>}
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    }



                    {editarServidorBD &&
                        <Modal
                            show={editarServidorBD}
                            onHide={() => setEditarServidorBD(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>Editar Servidor / BD. </Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setEditarServidorBD(false); reset() }} />

                            </Modal.Header>
                            <Modal.Body >
                                <Form noValidate onSubmit={handleSubmit(onSubmitServidorBD)}>
                                    <>
                                        <Form.Control
                                            type="hidden"
                                            defaultValue={datosNube.idSyncCredenciales}
                                            isInvalid={!!errors.idSyncCredenciales}
                                            {...register("idSyncCredenciales")
                                            }
                                        />
                                        <Form.Group className="mb-3">
                                            <Form.Label>BD / Server</Form.Label>
                                            <Form.Select
                                                aria-label="Default select"
                                                defaultValue={datosNube.vBD}
                                                isInvalid={!!errors.vBD}
                                                {...register("vBD",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona Servidor BD</option>
                                                {BDServer.map((bd, index) => {
                                                    return (<option key={index} value={bd.Value}>{bd.Text}</option>)
                                                })}
                                            </Form.Select>
                                        </Form.Group>

                                    </>
                                    <Modal.Footer>
                                        <Button onClick={() => { setEditarServidorBD(false); reset() }} variant='primary' >Cerrar</Button>
                                        {procesandoModal ? <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button> : <Button type="submit" variant='success' >Guardar cambios</Button>}
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    }

                </>)
            }

            <FeedProvider>
                {
                    cargando ?
                        <></>
                        :
                        <FeedCard
                            comentarios={comentarios}
                            procesandoComentario={procesandoComentario}
                            setComentario={setComentario}
                            comentario={comentario}
                            submitComment={submitComment}
                            idUsuario={Number(idUsuario)}
                            className='mb-2 mb-lg-0'
                        />

                }
            </FeedProvider>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />

        </>
    );
};

export default DetallesLicencia;
