
/**
 * 19 Dic 2024      Javier Calderon     Se Modulariza
 * 
 */

import React, { useEffect, useState } from 'react';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Col, Row, Button, Modal, CloseButton, Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';


const ModalPagoStripe = (
    {
        showPagoModal,
        setShowPagoModal,
        orden,
        setLinksPagos,

        setGenerandoLink,

        GuardarComentario,

        setShowMensajeError,
        setMensajeError,

        setShowMensajeInformativo,
        setMensajeInformativo
    }
) => {

    const { token, setToken, nombreCompleto } = useToken();

    const [cargando] = useState(false);
    const [dTotalLinkPago, setdTotalLinkPago] = useState('0.0000');





    useEffect(
        () => {
            setdTotalLinkPago(orden.dSaldoInsoluto);
        }
        , []);


    /**
       * Genera el link del pago
       * @param {any} totalLinkPago
       */
    const generarLinkPagoStripe = async (totalLinkPago) => {

        setShowPagoModal(false);
        setGenerandoLink(true);

        var jDataSend =
        {
            idOrden: orden.idOrden, // ID de la orden proporcionada como parámetro
            idCatFormaPago: orden.idCatFormaPago,
            dSaldoAnterior: orden.dSaldoInsoluto,
            dMonto: totalLinkPago, // Monto en centavos (por ejemplo, $100.00)
            vMoneda: orden.vMoneda // Moneda (ejemplo: dólares estadounidenses)

        };



        //jDataSend.idOrden = idOrden;
        //jDataSend.dDate = dTotal;

        console.log("DataSend -> ", jDataSend);

        const response = await fetch("/api/orden/stripe_GeneraLinkPago", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jDataSend)
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log("==== Links Pagos Stripe ====");
            console.log(response);
            const data = await response.json();
            console.log("Response Link de pagos Stripe -> ", data);

            if (data.iResponseType == 1) {
                let vUrl = (data.detalles);
                console.log("Response Link de pagos Stripe -> ", vUrl);

                //Se coloca el link
                //setLinkPago(vUrl); // Actualiza el estado con el enlace
                setLinksPagos((prevLinks) => [

                    {
                        idLinkPago: data.vCode, // El enlace
                        vLink: vUrl, // El enlace
                        //vDescripcion: "Descripción",
                        vMoneda: orden.vMoneda, // Moneda
                        iStatus: 0, // iStatus
                        vSistemaPago: "Stripe", // vSistemaPago
                        dTotalPago: totalLinkPago, // Total del pago
                        dCreatedDate: new Date().toISOString()// Fecha de cracion
                    },
                    ...prevLinks
                ]);

                var totalMoneda = parseFloat(totalLinkPago).toLocaleString(
                    orden.vMoneda == "MXN" ? "es-MX" : "en-US",
                    { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: orden.vMoneda }
                ) + " " + orden.vMoneda;
               
                
                GuardarComentario("<b>" + nombreCompleto + "</b> <b class='badge badge-soft-success'>generó </b> un Link de pago <b> # " + data.vCode + " de " + totalMoneda + "</b> <b class='badge' style='background-color: #625bff; color: white;'> stripe </b>  ", 2);

                //console.log("Links de pago:");
                //console.table(linksPagos);

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);


            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



            setGenerandoLink(false);
        }
        else {

            setGenerandoLink(false);

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }


    };


    return (
        <>
            <Modal
                show={showPagoModal}
                onHide={() => { setShowPagoModal(false); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Escribe el total del pago</Modal.Title>
                    {cargando ?
                        <Skeleton />
                        :
                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => { setShowPagoModal(false); }}
                        />
                    }
                </Modal.Header>
                <Modal.Body>

                    <Row className="gx-3">
                        <Col>
                            {cargando ? (
                                <Skeleton />
                            ) : (
                                <Form.Group className="mb-3">
                                    {
                                        orden.bIsPagada ? (
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="me-3 text-break"
                                                >
                                                    <strong>VENTA TOTALMENTE PAGADA</strong>
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <Form.Label>Total a pagar</Form.Label>
                                                <div className="d-flex align-items-center">
                                                    <CurrencyInput
                                                        className="form-control me-2"
                                                        defaultValue={orden.dSaldoInsoluto}
                                                        decimalsLimit={4}
                                                        prefix="$ "
                                                        onValueChange={(value) => setdTotalLinkPago(value)}
                                                        disabled={orden.idCatFormaPago !== 22}
                                                    />
                                                    <Button
                                                        onClick={() => generarLinkPagoStripe(dTotalLinkPago)}
                                                        className="btn btn-falcon-primary btn-sm"
                                                    >
                                                        Generar
                                                    </Button>
                                                </div>
                                            </>
                                        )
                                    }
                                </Form.Group>

                            )}
                        </Col>
                    </Row>


                </Modal.Body>



            </Modal>
        </>

    );
};

ModalPagoStripe.propTypes = {

    showPagoModal: PropTypes.bool,
    setShowPagoModal: PropTypes.func,
    orden: PropTypes.array,
    setLinksPagos: PropTypes.func,

    setGenerandoLink: PropTypes.func,

    GuardarComentario: PropTypes.func,

    setShowMensajeError: PropTypes.func,
    setMensajeError: PropTypes.func,

    setShowMensajeInformativo: PropTypes.func,
    setMensajeInformativo: PropTypes.func

};

export default ModalPagoStripe;
