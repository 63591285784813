
/**
 * 18 Dic 2024 Javier Calderon Modificaciones, Optimizaciones & se modulariza
 * 
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Card, Button, InputGroup, Spinner } from 'react-bootstrap';

//Modal pregunta por la cantidad de dinero para hacer el link
import ModalPagoStripe from './Modales/ModalPagoStripe';
//Modal para ver todos los links de pago
import ModalLinksPagoCatalogo from './Modales/ModalLinksPagoCatalogo';
import LinkPago from './LinkPago';
import LinksPagoSeccionSkeleton from './LinksPagoSeccionSkeleton';



const LinksPagosSeccion = (
    {
        linksPagos,
        setLinksPagos,
        numeroLinks,
        orden,

        loadingInfoDetallesOrden,

        GuardarComentario,

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }
) => {

    const [generandoLink, setGenerandoLink] = useState(false);
    const [showPagoModal, setShowPagoModal] = useState(false);
    const [showLinskPagoCatalogoModal, setShowLinskPagoCatalogoModal] = useState(false);


    return (
        <>
            {
                loadingInfoDetallesOrden ?
                    <>

                       <LinksPagoSeccionSkeleton/>
                    </>
                    :
                    <>
                        <Card className="mb-3">
                            <Card.Header className="pb-1">
                                <InputGroup className="fs--1 align-items-center">
                                    <h5 className="me-3 mt-1">Links para pagos</h5>
                                    {
                                        generandoLink ?
                                            <>
                                                <Button
                                                    disabled
                                                    className="btn btn-falcon-primary btn-sm  text-dark"
                                                >
                                                    <Spinner className="text-inline" animation="border" size="sm" />&nbsp; Generando Link...
                                                </Button>
                                            </>
                                            :
                                            <>
                                                {
                                                    !orden.bIsPagada &&
                                                        <Button
                                                            onClick={() => { setShowPagoModal(true) }}
                                                            className="btn btn-falcon-primary btn-sm"
                                                        >
                                                            Generar
                                                        </Button>
                                                }
                                               
                                            </>


                                    }
                                </InputGroup>
                            </Card.Header>
                            <Card.Body className="pt-2">
                                <Row>
                                    <Col>
                                        {
                                            <>
                                                {
                                                    linksPagos && linksPagos.length > 0 ?
                                                        <>
                                                            <Row>
                                                                {
                                                                    linksPagos.slice(0, 4).map(
                                                                        (link, index) => (//Se hace un map limitando a que solo muestre los primeros 4

                                                                            <LinkPago
                                                                                key={index}
                                                                                linkPago={link}
                                                                                setLinksPagos={setLinksPagos}
                                                                                index={index}
                                                                                className="mb-2 col-xxl-6 col-xl-6 col-md-6 col-sm-12"

                                                                                GuardarComentario={GuardarComentario }

                                                                                setShowMensajeError={setShowMensajeError}
                                                                                setMensajeError={setMensajeError}

                                                                                setShowMensajeInformativo={setShowMensajeInformativo}
                                                                                setMensajeInformativo={setMensajeInformativo}
                                                                            />
                                                                        )
                                                                    )
                                                                }
                                                            </Row>
                                                            {
                                                                numeroLinks > 0 &&
                                                                <>
                                                                    <Row className="d-flex align-items-center m-0">

                                                                        <Col xs={12} className="text-center">
                                                                            <Button
                                                                                size="sm"
                                                                                className='me-2 mb-2'
                                                                                onClick={() => { setShowLinskPagoCatalogoModal(true); }}
                                                                                variant="falcon-primary"
                                                                            >
                                                                                Ver más
                                                                            </Button>
                                                                        </Col>

                                                                    </Row>

                                                                </>


                                                            }
                                                        </>

                                                        :
                                                        <>
                                                            <span className="text-muted">No se han generado enlaces de pago.</span>
                                                        </>
                                                }

                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card >

                        {showPagoModal &&
                            <ModalPagoStripe
                                showPagoModal={showPagoModal}
                                setShowPagoModal={setShowPagoModal}
                                orden={orden}
                                setLinksPagos={setLinksPagos}

                                setGenerandoLink={setGenerandoLink}

                                GuardarComentario={GuardarComentario }

                                setShowMensajeError={setShowMensajeError}
                                setMensajeError={setMensajeError}

                                setShowMensajeInformativo={setShowMensajeInformativo}
                                setMensajeInformativo={setMensajeInformativo}
                            />
                        }

                        {showLinskPagoCatalogoModal &&
                            <ModalLinksPagoCatalogo
                                showLinskPagoCatalogoModal={showLinskPagoCatalogoModal}
                                setShowLinskPagoCatalogoModal={setShowLinskPagoCatalogoModal}
                                orden={orden}

                                setShowMensajeError={setShowMensajeError}
                                setMensajeError={setMensajeError}

                                setShowMensajeInformativo={setShowMensajeInformativo}
                                setMensajeInformativo={setMensajeInformativo}
                            />
                        }
                    </>
            }
            

        </>

    );
};

LinksPagosSeccion.propTypes = {
    linksPagos: PropTypes.array,
    setLinksPagos: PropTypes.func, 
    numeroLinks: PropTypes.number,
    orden: PropTypes.object,

    loadingInfoDetallesOrden: PropTypes.bool,

    GuardarComentario: PropTypes.func,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func
};

export default LinksPagosSeccion;
