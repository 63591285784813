
import React, { useEffect, useState } from 'react';

import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';

import { Col, Row } from 'react-bootstrap';

import Greetings from './Greeting/Greetings';
import ListaGastos from './ListaGastos';


const Gastos = () => {

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    const [showModalRegistrarGasto, setShowModalRegistrarGasto] = useState(false);
    const [showModalNuevo, setShowModalNuevo] = useState(false);

    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {



    }, []);


    return (
        <>

            <Row>
                <Col className="col-12">
                    <Greetings
                        setShowModalRegistrarGasto={setShowModalRegistrarGasto}
                        setShowModalNuevo={setShowModalNuevo}/>

                </Col>
                <Col className="col-12">
                    <ListaGastos
                        setShowModalRegistrarGasto={setShowModalRegistrarGasto}
                        showModalRegistrarGasto={showModalRegistrarGasto}
                        setShowModalNuevo={setShowModalNuevo}
                        showModalNuevo={showModalNuevo}

                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                        setMensajeInformativo={setMensajeInformativo}
                        setShowMensajeInformativo={setShowMensajeInformativo}/>
                </Col>

            </Row>


            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />


        </>
    );
};

export default Gastos;