import MensajeErrror from 'components/common/MensajeError';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Empleados = () => {
    const [empleados, setEmpleados] = useState([]);
    const [cargando, setCargando] = useState(true);
    const { token, idCatTipoUsuario, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const navigate = useNavigate();
    const columns = [
        {
            accessor: 'idUsuario',
            Header: 'ID',
            headerProps: { className: 'pe-1' },
            cellProps: { className: 'py-2' },
            Cell: rowData => {
                const { idUsuario } = rowData.row.original;
                return <>
                    <Link to={"/contabilidad/empleado/detalle/" + idUsuario}><img title="Ver informaci&oacute;n" src="../img/ver20.png"></img>&nbsp;<span>{idUsuario}</span></Link>

                </>
            }
        },
        {
            accessor: 'idCatTipoUsuario',
            Header: 'TU',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idCatTipoUsuario } = rowData.row.original;
                return (
                    <span className="d-inline-block text-truncate">
                        {idCatTipoUsuario}
                    </span>
                );
            }
        },
        {

            accessor: 'vNombre',
            Header: 'Nombre',
            Cell: rowData => {
                const { vNombre, vApellidos } = rowData.row.original;
                return <span className="fs--2">{vApellidos} {vNombre}</span>
            }
        },
        {
            accessor: 'iCategoria',
            Header: 'Categoria',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { iCategoria, bIsActive } = rowData.row.original;
                return (
                    <>
                        {iCategoria == 1 ? <Badge bg="primary">Empleado</Badge> : iCategoria == 2 ? <Badge bg="danger">Hon. Prof.</Badge> : iCategoria == 3 ? <Badge bg="warning" text="dark">Residente</Badge> : iCategoria == 4 ? <Badge bg="success">Becario</Badge> : <Badge bg="light" text="dark"></Badge>}
                        &nbsp;{bIsActive == 1 ? <Badge bg="success">Activo</Badge> : <Badge bg="danger">Baja</Badge>}
                    </>
                );
            }
        },
        {
            accessor: 'vCURP',
            Header: 'C.U.R.P.',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vCURP } = rowData.row.original;
                return <span className="d-inline-block text-truncate" style={{ maxWidth: '100px' }}> {vCURP} </span>
            }
        },
        {
            accessor: 'dFechaNacimiento',
            Header: 'Fecha Nac.',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { dFechaNacimiento } = rowData.row.original;
                return <span> {dFechaNacimiento}</span>;
            }
        },
        {
            accessor: 'vDatosContactoPersonal',
            Header: 'Tel. Personal',
            Cell: rowData => {
                const { vDatosContactoPersonal } = rowData.row.original;
                return <>
                    <span>{vDatosContactoPersonal}</span>
                </>
            }
        },
        {
            accessor: 'vTelefonoTrabajo',
            Header: 'Tel. Trabajo',
            Cell: rowData => {
                const { vTelefonoTrabajo } = rowData.row.original;
                return <>
                    <span>{vTelefonoTrabajo}</span>
                </>
            }
        },
        {
            accessor: 'vEmailTrabajo',
            Header: 'Email Trabajo',
            Cell: rowData => {
                const { vEmailTrabajo } = rowData.row.original;
                return <span>{vEmailTrabajo}</span>;
            }
        },
        {
            accessor: 'dFechaIngreso',
            Header: 'Fecha Ingreso',
            Cell: rowData => {
                const { dFechaIngreso } = rowData.row.original;
                return <span>{dFechaIngreso}</span>;
            }
        }
    ];

    const mostrarEmpleados = async () => {
        setCargando(true);
        const response = await fetch("/api/usuario/GetEmpleados",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log("=============== MOSTRAR EMPLEADOS ===============");
            console.log(data['table1'])
            setEmpleados(data['table1']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        if (idCatTipoUsuario != 1) {
            navigate("/");
        }
        mostrarEmpleados();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/vendedor35.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Empleados / Usuarios del sistema
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="/contabilidad/activofijo/nuevo">
                            <Button size="sm"
                                variant="falcon-primary"
                            >Nuevo Usuario</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={empleados}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header >
                        <Form >
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">

                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col className="ms-auto" sm={8} lg={4} xs={12} >
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={empleados.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer />
        </>
    );
};

export default Empleados;
