
import React, { useEffect, useState } from 'react';

import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';

import { Col, Row } from 'react-bootstrap';

import Greetings from './greeting/Greetings';
import TablaCuentas from './TablaCuentas';





const Cuentas = () => {

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);


    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {
        

    }, []);


    return (
        <>

            <Row>
                <Col className="col-12">
                    <Greetings/>
                </Col>
                <Col className="col-12">
                   
                    <TablaCuentas
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                        setMensajeInformativo={setMensajeInformativo}
                        setShowMensajeInformativo={setShowMensajeInformativo} />
                </Col>

            </Row>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />


        </>
    );
};

export default Cuentas;