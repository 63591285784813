
/**
 * 18 Dic 2024 Javier Calderon Modificaciones, Optimizaciones & se modulariza
 * 
 */

import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const LinksPagoSeccionSkeleton = () => {

    return (
        <>
            <Card className="mb-3">
                <Card.Header className="pb-1">
                    <Row>
                        <Col className="col-3">
                            <Skeleton />
                        </Col>
                        <Col className="col-1">
                            <Skeleton />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="pt-2">
                    <Row>
                        <Col className="col-6">
                            <Row>
                                <Col className="col-1 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-8 ms-1 ps-0 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-1 ms-1 ps-0"><Skeleton /></Col>
                            </Row>
                            <Row>
                                <Col className="col-1 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-4 ms-1 ps-0 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-3 ms-1 ps-0"><Skeleton /></Col>
                            </Row>
                        </Col>
                        <Col className="col-6">
                            <Row>
                                <Col className="col-1 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-8 ms-1 ps-0 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-1 ms-1 ps-0"><Skeleton /></Col>
                            </Row>
                            <Row>
                                <Col className="col-1 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-4 ms-1 ps-0 me-0 pe-0"><Skeleton /></Col>
                                <Col className="col-3 ms-1 ps-0"><Skeleton /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card >
        </>

    );
};



export default LinksPagoSeccionSkeleton;
