

export const dashboardRoutes = {
    label: 'Dashboard',
    labelDisable: true,
    children: [
        {
            name: 'Dashboard',
            active: true,
            icon: 'chart-pie',
            children: [
                {
                    name: 'General',
                    to: '/',
                    exact: true,
                    active: true
                },
                /* 
                  {
                    name: 'CRM',
                    to: '/dashboard/crm',
                      active: true,
                      badge: {
                          type: 'warning',
                          text: 'Prueba'
                      }
                  },
              
                  {
                    name: 'SaaS',
                    to: '/dashboard/saas',
                    active: true
                  },
                 
                  }*/
            ]
        }
    ]
};

var contabilidad = null;


contabilidad = {
    name: 'Contabilidad',
    icon: 'book',
    active: true,
    children: [
        {
            name: 'Conciliaci\u00F3n',
            active: true,
            children: [
                {
                    name: 'Pagos',
                    to: '/contabilidad/conciliacion/pagos',
                    active: true
                },
                {
                    name: 'Cuentas',
                    to: '/Contabilidad/Configuracion/Cuentas',
                    active: true
                }
               
            ]
        },
        {
            name: 'Facturaci\u00F3n',
            active: true,
            children: [
                {
                    name: 'Ajustes',
                    to: '/contabilidad/Facturacion/Ajustes',
                    active: true
                },
                {
                    name: 'Fact. Recibidas',
                    to: '/contabilidad/recibidas',
                    active: true
                },
                {
                    name: 'Fact. Emitidas',
                    to: '/contabilidad/emitidas',
                    active: true
                },
                {
                    name: 'R. E. Pagos',
                    to: '/contabilidad/complemento-pagos',
                    active: true
                },
                {
                    name: 'Notas de cr\u00E9dito',
                    to: '/contabilidad/nota-credito',
                    active: true
                },
                {
                    name: 'Anticipos',
                    to: '/contabilidad/anticipos',
                    active: true
                },
                {
                    name: 'N\u00F3mina',
                    to: '/contabilidad/nomina',
                    active: true
                },
                {
                    name: 'Nueva Factura',
                    to: '/contabilidad/nueva-factura',
                    active: true
                },
                {
                    name: 'Impuestos',
                    to: '/contabilidad/impuestos',
                    active: true
                },

            ]
        }, {
            name: 'Activo Fijo',
            to: '/contabilidad/activofijo',
            active: true
        },
        {
            name: 'Empleados',
            to: '/contabilidad/empleados',
            active: true
        },
        {
            name: 'Productos',
            to: '/contabilidad/productos',
            active: true
        },
        {
            name: 'Gastos / Egresos',
            to: '/contabilidad/gastos',
            active: true
        },
        /*
                {
                    name: 'N\u00F3mina',
                    to: '/clientes/nuevo',
                    active: true,
                    children: [
                        {
                            name: 'Ordinaria',
                            to: '/contabilidad/conciliacion',
                            active: true
                        },
                        {
                            name: 'Extraordinaria',
                            to: '/contabilidad/conciliacion',
                            active: true
                        },
                        {
                            name: 'Recibos',
                            to: '/contabilidad/conciliacion',
                            active: true
                        },
                        {
                            name: 'Empleados',
                            to: '/contabilidad/conciliacion',
                            active: true
                        },
                        {
                            name: 'Ajustes',
                            to: '/contabilidad/conciliacion',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Impuestos',
                    to: '/ordenes/',
                    active: true
                }*/
    ]
};

export var appRoutes = {
    label: 'app',
    children: [
        /* {
           name: 'Calendario',
           icon: 'calendar-alt',
           to: '/calendario',
           active: true
         },*/
        {
            name: 'Administradores',
            icon: 'book',
            active: true,
            children: [
                {
                    name: 'Estadistica',
                    active: true,
                    to: 'administradores/administradoresEstadisticas'
                    /*children: [
                        {
                            name: 'Pagos',
                            to: '/contabilidad/conciliacion/pagos',
                            active: true
                        }
                    ]*/
                },
            ]
        },
        {
            name: 'Contactos/Leads',
            icon: 'user',
            active: true,
            to: 'contactos'
        },
        contabilidad,
        {
            name: 'Clientes',
            icon: 'shopping-cart',
            active: true,
            children: [
                {
                    name: 'Cat\u00E1logo clientes',
                    to: '/clientes/',
                    active: true
                },
                {
                    name: 'Nuevo cliente',
                    to: '/clientes/nuevo',
                    active: true
                },
                {
                    name: 'Lista de \u00F3rdenes',
                    to: '/ordenes/',
                    active: true
                },
                {
                    name: 'Distribuidores',
                    to: '/distribuidores/',
                    active: true
                }
            ]
        },
        {
            name: 'Licencias',
            icon: 'file',
            active: true,
            children: [
                {
                    name: 'Cat\u00E1logo licencias',
                    to: '/licencias/',
                    active: true
                },
                {
                    name: 'Nueva Licencia',
                    to: '/licencias/nueva',
                    active: true
                },
                {
                    name: 'Usuarios Nube Akasia',
                    to: '/servicios/usuarios-nube-akasia',
                    active: true
                }
                ,
                {
                    name: 'Logs',
                    to: '/logservicios/logs',
                    active: true
                }
            ]
        },
        {
            name: 'Soporte',
            icon: 'ticket-alt',
            active: true,
            children: [

                {
                    name: 'Tickets',
                    to: '/soporte-tecnico',
                    active: true
                }
                /*  {
                      name: 'Detalle contacto',
                      to: '/support-desk/contact-details',
                      active: true
                  },
                  {
                      name: 'Previsualiza ticket',
                      to: '/support-desk/tickets-preview',
                      active: true
                  },
                  {
                      name: 'Links',
                      to: '/support-desk/quick-links',
                      active: true
                  },
                  {
                      name: 'Reportes',
                      to: '/support-desk/reports',
                      active: true
                  }*/
            ]
        },
        {
            name: 'M\u00f3dulos',
            icon: 'ticket-alt',
            active: true,
            children: [
                {
                    name: 'POS Fact. Guatemala',
                    to: '/servicios/facturacion-guatemala',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. Ecuador',
                    to: '/servicios/facturacion-ecuador',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. Panam\u00e1',
                    to: '/servicios/facturacion-Panama',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. Per\u00fa',
                    to: '/servicios/facturacion-Peru',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. Bolivia',
                    to: '/servicios/facturacion-Bolivia',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. El Salvador',
                    to: '/servicios/facturacion-elsalvador',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. Costa Rica',
                    to: '/servicios/facturacion-costarica',
                    active: true
                }
                ,
                {
                    name: 'POS Fact. M\u00e9xico',
                    to: 'http://facturacion.pade.mx/',
                    active: true
                }
            ]
        }
    ]
};




export const pagesRoutes = {
    label: 'M\u00f3dulos',
    children: [
        {
            name: 'POS Registro TAECEL',
            to: '/servicios/registro-taecel',
            active: true
        },
        {
            name: 'POS NIF Adicional',
            to: '/servicios/NIFAdicional',
            active: true
        }

    ]
};


export default [
    dashboardRoutes,
    appRoutes,
    pagesRoutes
];
