import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FalconCloseButton from 'components/common/FalconCloseButton';
import { Link } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import CurrencyInput from 'react-currency-input-field';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';

const ModalConciliarPago = ({
    showModalConciliar,
    setShowModalConciliar,
    setShowMensajeError,
    setMensajeError,
    setShowMensajeInformativo,
    setMensajeInformativo,
    idPago
}) => {
    const { token, setToken, idCatTipoUsuario } = useToken();
    const [Modal_idCatMedioPago, setModal_idCatMedioPago] = useState('');
    const [Modal_dTipoCambio, setModal_dTipoCambio] = useState('1.0000');
    const [Modal_idCatFormaPago, setModal_idCatFormaPago] = useState('');
    const [Modal_idCatCuentaBancaria, setModal_idCatCuentaBancaria] = useState('');
    const [Modal_vReferencia, setModal_vReferencia] = useState('');
    const [CargandoModalConciliar, setCargandoModalConciliar] = useState(true);
    const [StateEditarModal, setStateEditarModal] = useState(false);
    const [EsConciliado, setEsConciliado] = useState(false);
    const [monedaEditada, setMonedaEditada] = useState('');


    const [ordenObj, setOrdenObj] = useState({});
    const [FechaPago, setFechaPago] = useState('');
    const [NotasInputText, setNotasInputText] = useState('');

    const [cuentas, setCuentas] = useState([]);
    const [mediosPago, setMediosPago] = useState([]);
    const [formasPago, setFormasPago] = useState([]);
    

    /**
    * Obtiene el pago por Identificador numerico
    */
    const obtenerPagoByIDPago = async () => {
        setStateEditarModal(false);
        setCargandoModalConciliar(true);
        const response = await fetch("/api/conciliacion/obtenerPagoPorID/?idPago=" + idPago,
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {
                const data = JSON.parse(responseType.detalles);

                console.log(data);
                var pago_ = data['table1'][0];
                pago_.objDataExtra = JSON.parse(pago_.vJsonDetallesLinkPago);

                setOrdenObj(pago_);
                setMonedaEditada(data['table1'][0].vMoneda);
                setModal_idCatMedioPago(data['table1'][0].idCatMedioPago);
                setModal_idCatFormaPago(data['table1'][0].idCatFormaPago);
                setModal_idCatCuentaBancaria(data['table1'][0].idCatCuentaBancaria);
                setModal_vReferencia(data['table1'][0].vReferencia);

                setModal_dTipoCambio(data['table1'][0].dTipoCambio);

                setFechaPago(new Date(data['table1'][0].dFechaPago));
                setNotasInputText(data['table1'][0].vNotasConciliacion);
                setEsConciliado(data['table1'][0].bIsFacturado);

                setCuentas(data['table2']);
                setMediosPago(data['table3']);
                setFormasPago(data['table4']);

            } else {

                setShowModalConciliar(false);
                setShowMensajeError(true);
                setMensajeError(responseType.vMessage);
                //console.log(response);
            }

        }
        else {
            setShowModalConciliar(false);

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargandoModalConciliar(false);


    }

    /**
     * Gaurda la conciliacion
     */
    const onSubmitConciliacion = async (flagConciliar) => {

        var data = {};

        data.Notas = (NotasInputText == null) ? "" : NotasInputText;
        //data.dFechaPago = FechaPago;
        data.dFechaPago = new Date(FechaPago).toISOString();
        data.idPago = ordenObj.idPago;

        console.log(JSON.stringify(data));

        setCargandoModalConciliar(true);

        const response = await fetch("/api/conciliacion/Conciliar/?notas=" + data.Notas + "&fechaPago=" + data.dFechaPago + "&idPago=" + data.idPago + "&flagConciliar=" + flagConciliar, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {

                setMensajeError('' + error.message);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                obtenerPagoByIDPago(ordenObj.idPago);
                setShowMensajeInformativo(true);
                setMensajeInformativo("El pago #" + ordenObj.idPago + " ha sido " + (flagConciliar == "1" ? "Conciliado" : "Desconciliado"));

                //ObtenerPagos();

            } else {

                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);

            }

            setCargandoModalConciliar(false);
        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setCargandoModalConciliar(false);

        }

    };

    /**
     * Maneja el cambio de las notas
     * @param {any} event
     */
    const handleNotasChange = event => {
        setNotasInputText(event.target.value);
    };

    /**
     * Maneja la función para activar el pago si esta desactivado
     */
    const handdleActivarPago = async (data) => {
        setShowModalConciliar(true);
        setCargandoModalConciliar(true);

        const response = await fetch("/api/conciliacion/activaPago/?idPago=" + ordenObj.idPago + "&bIsActive=" + data, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                setShowMensajeInformativo(true);
                setMensajeInformativo("El pago #" + ordenObj.idPago + " ha sido " + ((data == "1") ? "Activado" : "Cancelado"));

                /*var date_ = sessionStorage.getItem("selectMes") == null ? dateQuarter : sessionStorage.getItem("selectMes");
                var ctaBancaria = sessionStorage.getItem("ctaBancariaConciliacion") == null ? '' : sessionStorage.getItem("ctaBancariaConciliacion");
                var idMedioPago = sessionStorage.getItem("idMedioPago") == null ? '' : sessionStorage.getItem("idMedioPago");*/

                //ObtenerPagos();
                obtenerPagoByIDPago(ordenObj.idPago);

                setCargandoModalConciliar(false);

            } else {

                setShowMensajeError(true);
                setMensajeError(responseType.vMessage);
                setCargandoModalConciliar(false);
            }
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setCargandoModalConciliar(false);
        }

    }


    /**
     * Edita caracteristicas del pago
     * @param {any} vMoneda_
     * @param {any} dTipoCambio_
     * @param {any} idCatMedioPago_
     * @param {any} idCatFormaPago_
     * @param {any} idCatCuentaBancaria_
     * @param {any} FechaPago_
     * @param {any} vReferencia_
     */
    const handdleEditarPago = async (vMoneda_, dTipoCambio_, idCatMedioPago_, idCatFormaPago_, idCatCuentaBancaria_, FechaPago_, vReferencia_) => {
        var data = {};

        data.idPago = ordenObj.idPago;
        data.vMoneda = (vMoneda_ == null) ? "" : vMoneda_;
        data.dTipoCambio = dTipoCambio_;
        data.idCatMedioPago = (idCatMedioPago_ == 0 || idCatMedioPago_ == null) ? "" : idCatMedioPago_;
        data.idCatFormaPago = (idCatFormaPago_ == 0 || idCatFormaPago_ == null) ? "" : idCatFormaPago_;
        data.idCatCuentaBancaria = (idCatCuentaBancaria_ == 0 || idCatCuentaBancaria_ == null) ? "0" : idCatCuentaBancaria_;
        data.vReferencia = (vReferencia_ == null) ? "" : vReferencia_;
        data.dFechaPago = new Date(FechaPago_).toISOString();

        console.log(JSON.stringify(data));

        setCargandoModalConciliar(true);

        const response = await fetch("/api/conciliacion/editarPago/?idPago=" + data.idPago + "&vMoneda=" + data.vMoneda +
            "&dTipoCambio=" + data.dTipoCambio + "&idCatMedioPago=" + data.idCatMedioPago + "&idCatFormaPago=" + data.idCatFormaPago +
            "&dFechaPago=" + data.dFechaPago + "&idCatCuentaBancaria=" + data.idCatCuentaBancaria + "&vReferencia=" + data.vReferencia, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {
                //setStateEditarMedioPago(false);
                obtenerPagoByIDPago(ordenObj.idPago);

                setMensajeInformativo("El pago #" + ordenObj.idPago + " ha sido editado el pago");
                setShowMensajeInformativo(true);

                //ObtenerPagos();

            } else {

                setShowMensajeError(true);
                setMensajeError(responseType.vMessage);
            }

            setCargandoModalConciliar(false);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setCargandoModalConciliar(false);

        }

    }

    useEffect(() => {
        obtenerPagoByIDPago();
    },[])

    /**
     * Hace reset a los estados
     */
    const reset = async () => {
        setOrdenObj({});
        setNotasInputText('');
        setFechaPago('');
        setEsConciliado(false);
    }


    return (
    <Modal
        show={showModalConciliar}
        onHide={() => { setShowModalConciliar(false); reset() }}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Form noValidate>
            {(!ordenObj.bIsActive && !CargandoModalConciliar) &&
                <Row>
                    <Col>
                        <Alert key={"danger"} variant={"danger"}>
                            El pago esta <strong>Cancelado 🚫</strong>

                            {
                                idCatTipoUsuario == 1 &&
                                <Button variant='primary' className="fs--1" onClick={() => { handdleActivarPago("1") }}>Activar Pago</Button>
                            }

                        </Alert>
                    </Col>
                </Row>
            }

            <Modal.Header>
                <Modal.Title>
                    {
                        CargandoModalConciliar ?
                            (
                                <h5><Skeleton /></h5>
                            ) :
                            (
                                <span>
                                    <Row>
                                        <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                            Conciliación &nbsp;&nbsp; {ordenObj.bIsActive && idCatTipoUsuario == 1 && <Button variant='danger' className="fs--1" onClick={() => { handdleActivarPago("0") }}>Cancelar Pago</Button>}
                                        </Col>

                                    </Row>

                                </span>

                            )
                    }
                </Modal.Title>
                {CargandoModalConciliar ? <Skeleton /> :
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalConciliar(false); reset() }} />
                }

            </Modal.Header>

            <Modal.Body>

                {

                    <Row>

                        <Col>

                            <Row className="align-items-center">

                                <Col className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                                    <h5>
                                        {CargandoModalConciliar ? <Skeleton /> : "DETALLE DEL PAGO #" + ordenObj.idPago}

                                    </h5>
                                    <h5>
                                        {
                                            CargandoModalConciliar ?
                                                <Skeleton />
                                                :
                                                <span>
                                                    <span className="text-primary">
                                                        {ordenObj.dImportePago.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                    </span>
                                                    <span className="fs--2 m-0 p-0 text-700">
                                                        {" " + ordenObj.vMoneda} {ordenObj.vMoneda == "MXN" ? "" : " T. C. " + parseFloat(ordenObj.dTipoCambio).toFixed(4)}
                                                    </span>
                                                </span>

                                        }
                                    </h5>
                                    {CargandoModalConciliar ? <Skeleton /> :
                                        <p className="fs--2 m-0 p-0">{new Date(FechaPago).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })} |  Orden <Link to={"/ordenes/detalle/" + ordenObj.idOrden}>
                                            # {ordenObj.idOrden}

                                        </Link></p>
                                    }




                                </Col>


                                <Col className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">

                                    <Row className="mt-2 mb-2">
                                        <Col className="fs--2 text-700 col-12">
                                            {CargandoModalConciliar ? <Skeleton /> :
                                                "Vendedor"
                                            }
                                        </Col>
                                        <Col className="fs--2 text-700 fw-bold">
                                            {CargandoModalConciliar ? <Skeleton /> : ordenObj.vNombreVendedor}
                                        </Col>

                                    </Row>

                                    <Row>

                                        <Col className="col-6">
                                            {CargandoModalConciliar ? <Skeleton /> :
                                                <SoftBadge
                                                    pill
                                                    bg={ordenObj.bIsActive == 1 ? "success" : "danger"}
                                                    className="d-flex flex-center mt-1"
                                                >


                                                    <p className="mb-0">
                                                        {ordenObj.bIsActive == 1 ? "Activo ✅" : "Cancelado 🚫"}
                                                    </p>


                                                </SoftBadge>
                                            }
                                        </Col>

                                        <Col className="col-6">

                                            {CargandoModalConciliar ? <Skeleton /> :
                                                <SoftBadge
                                                    pill
                                                    bg={ordenObj.idCatCuentaBancaria > 0 ? "info" : "danger"}
                                                    className="d-flex flex-center mt-1"
                                                >

                                                    <p className="mb-0 " >
                                                        {ordenObj.idCatCuentaBancaria > 0 ? "Validado ✅" : "No Validado ❌"}
                                                    </p>


                                                </SoftBadge>
                                            }


                                        </Col>

                                    </Row>



                                </Col>



                            </Row>

                            <hr />

                            <Row className="align-items-center">

                                <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">

                                    <Row className="align-items-center">

                                        <Col>
                                            <Card className="mt-1 mb-2">
                                                {
                                                    idCatTipoUsuario == 1 &&

                                                    <Card.Header className="border-bottom border-200 text-dark fw-bold">
                                                        <Row>

                                                            <Col className="col-10">
                                                                {CargandoModalConciliar ? <Skeleton /> : "Editar"}
                                                            </Col>

                                                            {CargandoModalConciliar ? <Skeleton /> :
                                                                <Col className="col-2 text-end">
                                                                    {


                                                                        StateEditarModal ?
                                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><strong>¡Cancelar!</strong></Tooltip>}>
                                                                                <FontAwesomeIcon onClick={() => { setStateEditarModal(false); }} icon={icon({ name: 'rectangle-xmark', style: "solid" })} style={{ color: "#c32847", }} size="xl" />
                                                                            </OverlayTrigger>
                                                                            :
                                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><strong>¡Editar!</strong></Tooltip>}>
                                                                                <FontAwesomeIcon onClick={() => { setStateEditarModal(true); }} icon={icon({ name: 'pen-to-square', style: "solid" })} size="sm" />
                                                                            </OverlayTrigger>
                                                                    }

                                                                </Col>
                                                            }
                                                        </Row>


                                                    </Card.Header>
                                                }

                                                <Card.Body>
                                                    {
                                                        //(true) ?
                                                            <Row className="align-items-center">

                                                                <Col className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">

                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> : <Form.Label>Moneda</Form.Label>}

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Select

                                                                                aria-label="Default select"
                                                                                className="form-control"
                                                                                onChange={(e) => {
                                                                                    setMonedaEditada(e.target.value);
                                                                                }}
                                                                                value={monedaEditada}
                                                                                disabled={!StateEditarModal}
                                                                            >
                                                                                <option value="MXN">MXN</option>
                                                                                <option value="USD">USD</option>
                                                                            </Form.Select>
                                                                        }

                                                                    </Form.Group>

                                                                </Col>

                                                                {monedaEditada == "USD" &&

                                                                    <Col className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">

                                                                        <Row className="align-items-center">

                                                                            <Col className="col-12">
                                                                                <Form.Group className="mb-3">
                                                                                    {CargandoModalConciliar ? <Skeleton /> : <Form.Label>Tipo cambio</Form.Label>}

                                                                                    {CargandoModalConciliar ? <Skeleton /> :
                                                                                        <CurrencyInput
                                                                                            className="form-control"
                                                                                            defaultValue={ordenObj.dTipoCambio}
                                                                                            decimalsLimit={4}
                                                                                            prefix="$ "
                                                                                            onValueChange={(value) => setModal_dTipoCambio(value)}
                                                                                            disabled={!StateEditarModal}
                                                                                        />
                                                                                    }
                                                                                </Form.Group>

                                                                            </Col>

                                                                        </Row>



                                                                    </Col>

                                                                }
                                                                <Col className="col-xxl-6 col-xl-6 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> : <Form.Label>Medio de Pago</Form.Label>}

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Select
                                                                                aria-label="Default select"
                                                                                onChange={(e) => {
                                                                                    setModal_idCatMedioPago(e.target.value);
                                                                                }}
                                                                                value={Modal_idCatMedioPago}
                                                                                disabled={!StateEditarModal}
                                                                            >
                                                                                <option value="0">Sin medio de pago</option>
                                                                                {mediosPago.map((medioPago, i) => {
                                                                                    return <option key={i} value={medioPago.idCatMedioPago}>{medioPago.idCatMedioPago}-{medioPago.vMedioPago}</option>
                                                                                })}
                                                                            </Form.Select>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> : <Form.Label>Forma de Pago</Form.Label>}

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Select
                                                                                aria-label="Default select"
                                                                                onChange={(e) => {
                                                                                    setModal_idCatFormaPago(e.target.value);
                                                                                }}
                                                                                value={Modal_idCatFormaPago}
                                                                                disabled={!StateEditarModal}
                                                                            >
                                                                                {formasPago.map((fp, i) => {
                                                                                    return <option key={i} value={fp.idCatFormaPago}>{fp.idCatFormaPago} - [{fp.vClave}] {fp.vDescripcionFormaPago}</option>
                                                                                })}
                                                                            </Form.Select>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> : <Form.Label>Cuenta Bancaria</Form.Label>}

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Select
                                                                                aria-label="Default select"
                                                                                onChange={(e) => {
                                                                                    setModal_idCatCuentaBancaria(e.target.value);
                                                                                }}
                                                                                value={Modal_idCatCuentaBancaria}
                                                                                disabled={!StateEditarModal}
                                                                            >
                                                                                <option value="0">Sin cuenta bancaria</option>
                                                                                {cuentas.map((fp, i) => {
                                                                                    return <option key={i} value={fp.idCatCuentaBancaria}>[{fp.vNumCta}] [{fp.vNombreBanco}] {fp.vAlias}</option>
                                                                                })}
                                                                            </Form.Select>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>



                                                                <Col className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">

                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Label className="col-12">Referencia</Form.Label>
                                                                        }

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Control
                                                                                size="sm"
                                                                                type="text"
                                                                                placeholder="Referencia"
                                                                                value={Modal_vReferencia}
                                                                                onChange={(e) => {
                                                                                    //console.log(e.target.value);
                                                                                    setModal_vReferencia(e.target.value);
                                                                                }}
                                                                                disabled={!StateEditarModal}
                                                                            />
                                                                        }
                                                                    </Form.Group>

                                                                </Col>

                                                                <Col className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">

                                                                    <Form.Group className="mb-3">
                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <Form.Label className="col-12">Fecha del Pago</Form.Label>
                                                                        }

                                                                        {CargandoModalConciliar ? <Skeleton /> :
                                                                            <DatePicker

                                                                                selected={new Date(FechaPago)}
                                                                                onChange={(date) => setFechaPago(date)}
                                                                                id=""
                                                                                name="date"
                                                                                className="form-control fs--1 col-12 w-100"
                                                                                dateFormat="MMMM d, yyyy"

                                                                                disabled={!ordenObj.bIsActive || !StateEditarModal}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        }
                                                                    </Form.Group>

                                                                </Col>
                                                            </Row>
                                                            //:

                                                            //<Row className="align-items-center fs--1">

                                                            //    <Col className="col-12">
                                                            //        {CargandoModalConciliar ? <Skeleton /> :
                                                            //            <span>
                                                            //                Referencia <strong>{ordenObj.vReferencia == "" || ordenObj.vReferencia == null ? <span className="text-muted">No hay referencias</span> : ordenObj.vReferencia}</strong>
                                                            //            </span>
                                                            //        }

                                                            //    </Col>

                                                            //    <Col className="col-12">
                                                            //        {CargandoModalConciliar ? <Skeleton /> :
                                                            //            <span>
                                                            //                Moneda <strong>{ordenObj.vMoneda} {ordenObj.vMoneda == "MXN" ? "" : " T. C. " + parseFloat(ordenObj.dTipoCambio).toFixed(4)} </strong>
                                                            //            </span>
                                                            //        }

                                                            //    </Col>

                                                            //    <Col className="col-12">
                                                            //        {CargandoModalConciliar ? <Skeleton /> :
                                                            //            <span>
                                                            //                Medio de pago {ordenObj.vMedioPago == "" || ordenObj.vMedioPago == null ? <span className="text-muted">No hay Medio de pago</span> : <strong>{ordenObj.vMedioPago}</strong>}
                                                            //            </span>
                                                            //        }
                                                            //    </Col>

                                                            //    <Col className="col-12">
                                                            //        {CargandoModalConciliar ? <Skeleton /> :
                                                            //            <span>
                                                            //                Forma de pago <strong>{ordenObj.vDescripcionFormaPago}</strong>
                                                            //            </span>
                                                            //        }
                                                            //    </Col>

                                                            //    <Col className="col-12">
                                                            //        {CargandoModalConciliar ? <Skeleton /> :
                                                            //            (ordenObj.vNumCta == null || ordenObj.vNombreBanco == null) ?
                                                            //                <span>
                                                            //                    Cuenta Bancaria <strong>No asignada</strong>
                                                            //                </span>
                                                            //                :
                                                            //                <span>
                                                            //                    Cuenta Bancaria <strong>{'[' + ordenObj.vNumCta + '] [' + ordenObj.vNombreBanco + '] ' + ordenObj.vAlias + ''}</strong>
                                                            //                </span>
                                                            //        }
                                                            //    </Col>

                                                            //</Row>
                                                    }

                                                        <Row>
                                                            <Col>
                                                                {
                                                                    ordenObj.objDataExtra &&
                                                                    <>
                                                                        <Row className="fs--1 border-bottom fst-italic pb-2">
                                                                            <Col className="col-12 text-start align-content-center fw-bold">
                                                                                --- Datos extra del pago --- 
                                                                            </Col>
                                                                            <Col className="col-6">
                                                                                <Row className="justify-content-end">
                                                                                    <Col className="col-12 text-start ">
                                                                                        {ordenObj.objDataExtra.vClientName}
                                                                                    </Col>
                                                                                    <Col className="col-12 text-start ">
                                                                                        {ordenObj.objDataExtra.vClientMail}
                                                                                    </Col>
                                                                                    <Col className="col-12 text-start ">
                                                                                        {"**** **** **** " + ordenObj.objDataExtra.vLastFourCardDigits}
                                                                                    </Col>
                                                                                </Row>

                                                                            </Col>
                                                                            <Col className="col-6">
                                                                                <Row className="justify-content-end">
                                                                                    <Col className="col-12 text-start">
                                                                                        {
                                                                                            ordenObj.objDataExtra.vCardCompany + " " +
                                                                                            ordenObj.objDataExtra.vCardType + " card "
                                                                                        }
                                                                                    </Col>
                                                                                    <Col className="col-12 text-start">
                                                                                        {
                                                                                            ordenObj.objDataExtra.vBank == null ? "No bank data" : "Banco " + ordenObj.objDataExtra.vBank
                                                                                        }
                                                                                    </Col>
                                                                                </Row>

                                                                            </Col>
                                                                        </Row>
                                                                    </>

                                                                }


                                                            </Col>
                                                        </Row>



                                                </Card.Body>

                                                {
                                                    StateEditarModal &&

                                                    <Card.Footer className="border-top border-200">
                                                        <Row className="align-items-center">
                                                            {CargandoModalConciliar ?

                                                                <Col className="text-center">
                                                                    <Button variant="secondary" disabled className="mt-3 px-5 fs--1 align-items-center">
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            className="me-2"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Procesando...
                                                                    </Button>
                                                                </Col>

                                                                :

                                                                <Col className="col-12 text-center btn btn-primary fs--1 ml-2 mr-2" onClick={() => { handdleEditarPago(monedaEditada, Modal_dTipoCambio, Modal_idCatMedioPago, Modal_idCatFormaPago, Modal_idCatCuentaBancaria, FechaPago, Modal_vReferencia); }}>
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><strong>¡Guardar!</strong></Tooltip>}>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={icon({ name: 'floppy-disk', style: "solid" })} size="xl" /> Editar
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>

                                                            }

                                                        </Row>
                                                    </Card.Footer>
                                                }

                                            </Card>

                                        </Col>


                                    </Row>


                                </Col>

                            </Row>

                            {
                                !StateEditarModal ? //si se esta editando,se oculta la linea horizontal
                                    <hr />
                                    :
                                    <></>
                            }
                            

                            {
                                !StateEditarModal ?
                                    <Row>
                                        <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            {CargandoModalConciliar ? <Skeleton /> :
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="mb-0">Notas</Form.Label>
                                                    <br />
                                                    <Badge className='mb-1' bg={`${NotasInputText.length > 190 ? 'danger' : NotasInputText.length > 160 ? "warning" : 'primary'}`}>{NotasInputText.length}/{200}</Badge>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        disabled={EsConciliado || !ordenObj.bIsActive}
                                                        maxLength={200}
                                                        size="sm"
                                                        autoComplete="off"
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase(); setNotasInputText(event.target.value); }}
                                                        value={NotasInputText}
                                                        onChange={handleNotasChange}
                                                        isInvalid={(NotasInputText.length > 200)}
                                                    />
                                                </Form.Group>
                                            }

                                        </Col>
                                    </Row>
                                    :
                                    <></>
                                        
                            }

                            {EsConciliado &&
                                <Row>
                                    <Col>
                                        {CargandoModalConciliar ? <Skeleton /> :
                                            <Row className="justify-content-center fs--1 align-items-center">

                                                <Col className="col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-sm-7 col-7">

                                                    <Alert key={"success"} variant={"success"} className="m-0 p-1">
                                                        El pago está <strong>Conciliado</strong>.&nbsp;&nbsp; | &nbsp;&nbsp; Conciliado por <strong className="">{ordenObj.vUsuarioConciliador}</strong> el <strong className="">{new Date(ordenObj.dFechaConciliacion).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</strong>

                                                    </Alert>

                                                </Col>
                                                {
                                                    idCatTipoUsuario == 1 &&
                                                    <Col className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-5 col-5">

                                                        <Button variant='danger' className="fs--1 col-12" onClick={() => { onSubmitConciliacion("0"); }}>Desconciliar</Button>

                                                    </Col>
                                                }


                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            }

                        </Col>

                    </Row>

                }



            </Modal.Body>
            <Modal.Footer>

                {
                    !StateEditarModal ? //Si se esta editando, oculta el botón Conciliar
                        (ordenObj.bIsActive == 1 && idCatTipoUsuario == 1 && !EsConciliado && !CargandoModalConciliar) &&
                            <Button variant='success' onClick={() => { onSubmitConciliacion("1"); }}>Conciliar</Button>
                        :
                        <></>
                    
                }
                {
                    !StateEditarModal ?//Si se esta editando, oculta el botón Cerrar
                        CargandoModalConciliar ?
                            <Skeleton />
                            :
                            <Button onClick={() => { setShowModalConciliar(false); reset() }} variant='danger' >Cerrar</Button>
                        :
                        <></>
                }

            </Modal.Footer>
        </Form>
        </Modal>
    )
};

ModalConciliarPago.propTypes = {
    showModalConciliar: PropTypes.bool,
    setShowModalConciliar: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeError: PropTypes.bool,
    setShowMensajeInformativo: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    idPago: PropTypes.Digit
};
export default ModalConciliarPago;
