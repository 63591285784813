
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';


import { Row, Col, Card, Form, Table, Spinner, Button, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import IconButton from 'components/common/IconButton';






const FormularioDatosFacturacion = (
    {
        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }
) => {

    const { token, setToken } = useToken();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();

    const [Editar, setEditar] = useState(false);
    const [regimenesFiscales, setRegimenesFiscales] = useState([])
    const [datoFiscal, setDatoFiscal] = useState({})
    const [guardando, setGuardando] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [archivoCER, setArchivoCER] = useState({
        file: null,
        fileName: ''
    });
    const [archivoKEY, setArchivoKEY] = useState({
        file: null,
        fileName: ''
    });
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);



    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {

        obtenerRegimenesFiscales();
        obtenerDatosFiscales()
        

    }, []);


    /**
     * Muestra u oculta la password
     * @param {any} e
     */
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /**
     * Selecciona el archivo .key
     * @param {any} e
     */
    const seleccionarArchivoKEY = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validar extensión del archivo
            if (!file.name.endsWith('.key')) {
                console.error("Solo se permiten archivos .key.");
                setShowMensajeError(true);
                setMensajeError("Solo se permiten archivos .key.");
                return;
            }

            // Validar longitud del nombre del archivo
            if (file.name.length > 50) {
                console.error("El nombre del archivo excede los 50 caracteres.");
                setShowMensajeError(true);
                setMensajeError("El nombre del archivo no debe exceder los 50 caracteres.");
                return;
            }

            // Validar tamaño del archivo (1 MB = 1 * 1024 * 1024 bytes)
            if (file.size > 1 * 1024 * 1024) {
                console.error("El archivo excede el tamaño máximo permitido de 1 MB.");
                setShowMensajeError(true);
                setMensajeError("El archivo no debe pesar más de 1 MB.");
                return;
            }

            // Si todas las validaciones son correctas, procesar el archivo
            procesarArchivo(file, setArchivoKEY);
        }
    };

    /**
     * Selecciona el archivo .cer
     * @param {any} e
     */
    const seleccionarArchivoCER = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validar extensión del archivo
            if (!file.name.endsWith('.cer')) {
                console.error("Solo se permiten archivos .cer");
                setShowMensajeError(true);
                setMensajeError("Solo se permiten archivos .cer");
                return;
            }

            // Validar longitud del nombre del archivo
            if (file.name.length > 50) {
                console.error("El nombre del archivo excede los 50 caracteres.");
                setShowMensajeError(true);
                setMensajeError("El nombre del archivo no debe exceder los 50 caracteres.");
                return;
            }

            // Validar tamaño del archivo (1 MB = 1 * 1024 * 1024 bytes)
            if (file.size > 1 * 1024 * 1024) {
                console.error("El archivo excede el tamaño máximo permitido de 1 MB.");
                setShowMensajeError(true);
                setMensajeError("El archivo no debe pesar más de 1 MB.");
                return;
            }

            // Si todas las validaciones son correctas, procesar el archivo
            procesarArchivo(file, setArchivoCER);
        }
    };



    /**
    * Procesa el archivo
    */
    const procesarArchivo = (file, setArchivo) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const base64String = event.target.result.split(",")[1];
            setArchivo({
                fileName: file.name,
                file: base64String
            });
            console.log("ARCHIVO Y NOMBRE", { fileName: file.name, file: base64String }); // Para depuración
        };

        reader.onerror = () => {
            console.error("Error al leer el archivo.");
        };

        reader.readAsDataURL(file);
    };

    /**
     * Convertir Base64 a archivo
     */
    const base64ToFile = (base64String, fileName) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        return new File([blob], fileName, { type: blob.type });
    };


    /**
    * Descargar Archivo CER
    */
    const descargarArchivoCER = (file) => {

        console.log("FILE",file);

        // Crea un objeto Blob a partir del archivo
        const blob = new Blob([file], { type: file.type });

        // Crea un enlace de descarga
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.name;

        // Haz clic en el enlace para iniciar la descarga
        link.click();

        // Libera la URL del objeto después de la descarga
        URL.revokeObjectURL(link.href);

    };


    /**
     * Obtiene los régimenes fiscales
     */
    const obtenerRegimenesFiscales = async () => {

        const response = await fetch("/api/Contabilidad/ObtenerRegimenesFiscales",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER RÉGIMENES FISCALES ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataRF: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetallesRF:", ObjDetalles);

                setRegimenesFiscales(ObjDetalles.table2);

                console.log("REGIMENES:", ObjDetalles.table2);

                console.log("REGIMENES set:", regimenesFiscales);




            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

     /**
     * Guarda los datos fiscales
     */
    const guardarDatosFiscales = async (data) => {

        data.idDatoFiscal           = datoFiscal ? datoFiscal.idDatoFiscal : "0"
        data.vArchivoCER_Base64     = archivoCER.file;
        data.vArchivoCER_Name       = archivoCER.fileName;
        data.vArchivoKEY_Base64     = archivoKEY.file;
        data.vArchivoKEY_Name       = archivoKEY.fileName;
        data.vPassword              = password

        console.log("file nombreee", archivoCER.fileName)
        console.log("file nombreee", archivoCER.file)

        console.log("KEY nombreee", archivoKEY.fileName)
        console.log("KEY BASE", archivoKEY.file)


        console.log("Data parameter(guardarDatosFiscales) ", data)

        setGuardando(true);




        const response = await fetch("/api/Contabilidad/guardarDatosFiscales",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
    
            });

        if (response.ok) {
            console.log("==== GUARDA DATOS FISCALES ====");

            console.log("response (guardarDatosFiscales):", response);
            const data = await response.json();
            console.log("data OBJRESPONSE (guardarDatosFiscales): ", data);

            setGuardando(false)

            if (data.iResponseType == 1) {

                setEditar(false)

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);
                

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("Obj detalles (guardarDatosFiscales)", ObjDetalles)

                var file_CER = base64ToFile(ObjDetalles.table2[0].vArchivoCER_Base64, ObjDetalles.table2[0].vArchivoCER_Name);
                var file_KEY = base64ToFile(ObjDetalles.table2[0].vArchivoKEY_Base64, ObjDetalles.table2[0].vArchivoKEY_Name);


                let datoFiscal_ = ObjDetalles.table2[0];

                datoFiscal_.fileCER = file_CER;
                datoFiscal_.fileKEY = file_KEY;

                
                


                setDatoFiscal(ObjDetalles.table2[0])
                console.log("DATA FISCAL GUARDADA", datoFiscal)

                setPassword(ObjDetalles.table2[0].vPassword)

                console.log("TABLA 2(VER DATOS  guardar)", ObjDetalles.table2);


                

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }
            

        }
        else {
            setGuardando(false)
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Obtiene los datos fiscales
     */
    const obtenerDatosFiscales = async () => {


        setCargando(true)


        const response = await fetch("/api/Contabilidad/obtenerDatosFiscales",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {

            console.log("==== OBTENER DATOS FISCALES ====");

            const data = await response.json();

            if (data.iResponseType == 1) {


                var ObjDetalles = JSON.parse(data.detalles);

                console.log("obtenerDatosFiscales", ObjDetalles);

                if (ObjDetalles.table2[0]) {

                    var file_CER = base64ToFile(ObjDetalles.table2[0].vArchivoCER_Base64, ObjDetalles.table2[0].vArchivoCER_Name);
                    var file_KEY = base64ToFile(ObjDetalles.table2[0].vArchivoKEY_Base64, ObjDetalles.table2[0].vArchivoKEY_Name);


                    let datoFiscal_ = ObjDetalles.table2[0];

                    datoFiscal_.fileCER = file_CER;
                    datoFiscal_.fileKEY = file_KEY;


                    

                    setArchivoCER({
                        fileName: datoFiscal_.vArchivoCER_Name,
                        file: datoFiscal_.fileCER
                    });

                    console.log("TABLA 2(VER DATOS )", archivoCER);


                    setArchivoKEY({
                        fileName: datoFiscal_.vArchivoKEY_Name,
                        file: datoFiscal_.fileKEY
                    });



                    setDatoFiscal(datoFiscal_);

                    setPassword(datoFiscal_.vPassword);

                    console.log("TABLA 2(VER DATOS EXT )", datoFiscal);

                }


                


            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }
            setCargando(false);



        }
        else {
            setCargando(true);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };



    return (
        <>
            <Form onSubmit={handleSubmit(guardarDatosFiscales)}>
                <Card className=" shadow-none border mb-3">
                    <Card.Body className="py-0">
                        <Card.Title>
                            <Row className="justify-content-end pt-3">
                                {
                                    Editar ?
                                        <>
                                            
                                            <Col className="col-auto">
                                                {
                                                    guardando ?
                                                        <>
                                                            {/* mostrar boton como "cargando" */}
                                                            <IconButton
                                                                variant="falcon-default"
                                                                size="sm"
                                                                transform="shrink-2"
                                                                className="d-sm-block me-2"
                                                                disabled
                                                            >
                                                                <Spinner className="text-inline" animation="border" size="sm" variant="ligth" />&nbsp; Cargando...
                                                            </IconButton>
                                                        </>
                                                        :
                                                        <>
                                                            <IconButton
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="check"
                                                                transform="shrink-2"
                                                                className="d-sm-block me-2"
                                                                type="submit"
                                                            >
                                                                <span className="d-none d-xl-inline-block ms-1">Guardar</span>
                                                            </IconButton>
                                                        </>
                                                 }
                                                
                                            </Col>

                                            

                                            <Col className="col-auto">
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setEditar(false);
                                                        setArchivoCER({ ...archivoCER, fileName: datoFiscal.vArchivoCER_Name, file: datoFiscal.fileCER });
                                                        setArchivoKEY({ ...archivoKEY, fileName: datoFiscal.vArchivoKEY_Name, file: datoFiscal.fileKEY });
                                                        reset();
                                                    }}


                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="pencil-alt"
                                                    transform="shrink-2"
                                                    className="d-sm-block me-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Cancelar</span>
                                                </IconButton>
                                            </Col>  
                                        </>
                                        :
                                        <>
                                        
                                            <Col className="col-auto">
                                                <IconButton
                                                    onClick={(e) => { e.preventDefault(); setEditar(true) }}
                                                    
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="pencil-alt"
                                                    transform="shrink-2"
                                                    className="d-sm-block me-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Editar</span>
                                                </IconButton>
                                            </Col>
                                        </>
                                }
                            
                                
                            </Row>
                        </Card.Title>
                        <Row>
                            <Col className="col-12">
                                <Table striped>
                                    <tbody>
                                        <tr>
                                            <td>
                                                RFC
                                            </td>
                                            <td>
                                                {
                                                    Editar ? 
                                                        <>   
                                                            
                                                            <Form.Control
                                                                size="sm"
                                                                autoComplete="off"
                                                                type="text"
                                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                defaultValue={datoFiscal ? datoFiscal.vRFC : ''}
                                                                isInvalid={!!errors.vRfc}
                                                                {...register('vRFC',
                                                                    {
                                                                        required: 'El RFC es obligatorio',
                                                                        maxLength: 13,
                                                                        minLength: 12,
                                                                        pattern: /^[A-ZÑ&]{3,4}\d{6}(?:[A-Z\d]{3})?$/
                                                                    })
                                                                }
                                                                onChange={(event) => {
                                                                    let rfc = (event.target.value).trim().toUpperCase();
                                                                    if (rfc === "XEXX010101000" || rfc === "XAXX010101000") {
                                                                        setValue('vCp', '61770');
                                                                    }
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.vRfc && errors.vRfc.message}
                                                            </Form.Control.Feedback>
                                                        </>
                                                        : 
                                                        <>
                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton/>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {datoFiscal ? datoFiscal.vRFC : ''}
                                                                    </>

                                                            }
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Razón Social
                                            </td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                autoComplete="off"
                                                                type="text"
                                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                defaultValue={datoFiscal ? datoFiscal.vRazonSocial : ''}
                                                                isInvalid={!!errors.vRfc}
                                                                {...register('vRazonSocial',
                                                                    {
                                                                        required: 'La Raz\u00f3n Social es  obligatoria',
                                                                        maxLength: 300,
                                                                        minLength: 1,
                                                                        pattern: /[^|]{1,300}/
                                                                    })
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.vRfc && errors.vRfc.message}
                                                            </Form.Control.Feedback>
                                                        </>
                                                        :
                                                        <>  
                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton/>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {datoFiscal ? datoFiscal.vRazonSocial : ''}
                                                                    </>

                                                            }
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>R&eacute;gimen Fiscal</strong></td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <Form.Select
                                                                size="sm"
                                                                aria-label="Default select"
                                                                defaultValue={ datoFiscal? datoFiscal.vCodeRegimen : ''}
                                                                isInvalid={!!errors.idCatRegimenFiscal}
                                                                {...register("vCodeRegimen",
                                                                    {
                                                                        required: true
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Selecciona un r&eacute;gimen</option>
                                                                {regimenesFiscales.map((regimen, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={regimen.vCodigoRegimen}>
                                                                            {regimen.vCodigoRegimen} - {regimen.vDescripcionRegimenFiscal}
                                                                        </option>)
                                                                })}
                                                            </Form.Select>
                                                        </>
                                                        : 
                                                        <>
                                                            <span>
                                                                {
                                                                    cargando ?
                                                                        <>
                                                                            <Skeleton/>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {datoFiscal.vCodeRegimen ? (datoFiscal.vCodeRegimen + "-" + datoFiscal.vDescripcionRegimenFiscal) : ''}
                                                                        </>

                                                                }
                                                            </span>
                                                        </>
                                                    
                                                   }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Código Postal
                                            </td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                autoComplete="off"
                                                                type="text"
                                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                defaultValue={datoFiscal ? datoFiscal.vCP : ''}
                                                                isInvalid={!!errors.vRfc}
                                                                {...register('vCP',
                                                                    {
                                                                        required: 'El Codigo postal es obligatorio',
                                                                        maxLength: 6,
                                                                        minLength: 4
                                                                    
                                                                    })
                                                                }
                                                                onChange={(event) => {
                                                                    let rfc = (event.target.value).trim().toUpperCase();
                                                                    if (rfc === "XEXX010101000" || rfc === "XAXX010101000") {
                                                                        setValue('vCp', '61770');
                                                                    }
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.vRfc && errors.vRfc.message}
                                                            </Form.Control.Feedback>
                                                        </>
                                                        :
                                                        <>

                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {datoFiscal ? datoFiscal.vCP : ''}
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Archivo CER
                                            </td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <InputGroup>
                                                                <InputGroup.Text>{archivoCER.fileName}</InputGroup.Text>
                                                                <Form.Control
                                                                    type="file"
                                                                    onChange={seleccionarArchivoCER}
                                                                    style={{ display: "none" }}
                                                                    id="fileUpload_cer"
                                                                    className="mr-6"
                                                                />
                                                                <Button
                                                                    variant="light"
                                                                    onClick={() => document.getElementById("fileUpload_cer").click()}
                                                                >
                                                                    Seleccionar archivo
                                                                </Button>
                                                                
                                                            </InputGroup>



                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Link onClick={() => { descargarArchivoCER(datoFiscal.fileCER); }}>
                                                                            {datoFiscal.vArchivoCER_Name}
                                                                        </Link>
                                                                    </>
                                                            }  
                                                        </>
                                                }                                              
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Archivo KEY
                                            </td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <InputGroup>
                                                                <InputGroup.Text>{archivoKEY.fileName}</InputGroup.Text>
                                                                <Form.Control
                                                                    type="file"
                                                                    onChange={seleccionarArchivoKEY}
                                                                    style={{ display: "none" }}
                                                                    id="fileUpload_key"

                                                                />
                                                                <Button
                                                                    variant="light"
                                                                    onClick={() => document.getElementById("fileUpload_key").click()}

                                                                >
                                                                    Seleccionar archivo
                                                                </Button>

                                                            </InputGroup>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Link onClick={() => { descargarArchivoCER(datoFiscal.fileKEY); }}>
                                                                            {datoFiscal.vArchivoKEY_Name}
                                                                        </Link>                                                                        
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Contraseña
                                            </td>
                                            <td>
                                                {
                                                    Editar ?
                                                        <>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    defaultValue={datoFiscal.vPassword }
                                                                    onChange={(e) => {
                                                                        setPassword(e.target.value);
                                                                    }}
                                                                    placeholder="Enter your password"
                                                                    minLength={8} // Limita la longitud máxima
                                                                    maxLength={12} // Limita la longitud máxima
                                                                />
                                                                <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                                </Button>
                                                            </InputGroup>
                                                            
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                cargando ?
                                                                    <>
                                                                        <Skeleton />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {datoFiscal.vPassword ? '•'.repeat(datoFiscal.vPassword.length) : ''}                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
};


FormularioDatosFacturacion.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func

};

export default FormularioDatosFacturacion;