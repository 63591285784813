import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';
import Skeleton from 'react-loading-skeleton';
import { Col, Row, Modal, CloseButton, Button } from 'react-bootstrap';

import LinkPago from '../LinkPago';



const ModalLinksPagoCatalogo = (
    {
        showLinskPagoCatalogoModal,
        setShowLinskPagoCatalogoModal,
        orden,

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }
) => {

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(false);
    const [linksPagos, setLinksPagos] = useState([]);




    /**
     * Obtiene los links de pago por id
     */
    const obtenerLinksPagos = async () => {

        setCargando(true);

        var jDataSend =
        {
            idOrden: orden.idOrden // ID de la orden proporcionada como parámetro
        };
        const response = await fetch("/api/orden/getLinksPagos", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jDataSend)
        })
            .catch((error) => {

                setMensajeError('' + error.message);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error.message);

                setCargando(false);
            });

        if (response.ok) {
            const data = await response.json();

            if (data.iResponseType == 1) {

                const lp = JSON.parse(data.detalles);
                setLinksPagos(lp);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



            setCargando(false);
        }
        else {

            setCargando(false);

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

   
    useEffect(
        () => {
            obtenerLinksPagos();
        }
        , []);


    return (
        <>
            <Modal
                show={showLinskPagoCatalogoModal}
                onHide={() => { setShowLinskPagoCatalogoModal(false); }}
                size="md"
                scrollable
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Links de Pagos</Modal.Title>
                    {cargando ?
                        <Skeleton />
                        :
                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => { setShowLinskPagoCatalogoModal(false); }}
                        />
                    }
                </Modal.Header>
                <Modal.Body>

                    <Row className="gx-3">
                        <Col>
                            {
                                cargando ? 
                                    <>
                                        <Skeleton />
                                    </>
                                :

                                linksPagos && linksPagos.length > 0 ? 
                                    <>
                                        <Row>
                                                {linksPagos.map((link, index) => ( // No hay límite de elementos

                                                 <LinkPago
                                                        key={index }
                                                        linkPago={link}
                                                        setLinksPagos={setLinksPagos}
                                                        index={index}
                                                        className="mb-2 col-12"

                                                        setShowMensajeError={setShowMensajeError}
                                                        setMensajeError={setMensajeError}

                                                        setShowMensajeInformativo={setShowMensajeInformativo}
                                                        setMensajeInformativo={setMensajeInformativo}

                                                    />

                                            ))}
                                        </Row>

                                    </>
                                    : 
                                    <>
                                            <span className="text-muted">No se ha generado ningún enlace aún.</span>
                                    </>
                                    
                                


                            }
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-center">
                    {
                        cargando ?
                            <>
                                <Skeleton />
                            </>
                            :
                            <>
                                <Row className="d-flex align-items-center">
                                    <Button
                                        onClick={() => { setShowLinskPagoCatalogoModal(false); }}
                                        variant='danger'
                                    >
                                        Cerrar
                                    </Button>
                                </Row>
                            </> 
                    }
                </Modal.Footer>



            </Modal>
        </>

    );
};

ModalLinksPagoCatalogo.propTypes = {
    showLinskPagoCatalogoModal: PropTypes.bool,
    setShowLinskPagoCatalogoModal: PropTypes.func,
    orden: PropTypes.array,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func
};

export default ModalLinksPagoCatalogo;
