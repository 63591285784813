
import React, { useEffect, useState, forwardRef} from 'react';
import useToken from 'hooks/useToken';
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';


import { Modal, Button, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/common/IconButton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';



const ModalRegistroGasto = (
    {
       
        setGastos,

        setSelectedGasto,
        selectedGasto,

        showModalNuevo,

        showModalRegistrarGasto,
        setShowModalRegistrarGasto,

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo

    }



) => {

    const { token, setToken } = useToken();
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm();


    const [formasdePago, setFormasdePago] = useState([]);
    const [cuentasBancarias, setCuentasBancarias] = useState([]);
    const [responsableUsuario, setResponsableUsuario] = useState([]);
    const [tipoDeGasto, setTipoDeGasto] = useState([]);


    const [notaDeVenta, setNotaDeVenta] = useState({
        file: null,
        fileName: ''
    });

    const [cargando, setCargando] = useState(false);
    const [Editar, setEditar] = useState(false);

    const initialDate = selectedGasto && selectedGasto.dFechaGasto
        ? new Date(selectedGasto.dFechaGasto)
        : null;

    const [date, setDate] = useState(initialDate);

    const [moneda, setMoneda] = useState(selectedGasto?.idTipoMoneda ? String(selectedGasto.idTipoMoneda) : "1");

    const [tipoDeCambio, setTipoDeCambio] = useState({});

    const [tipoDeCambioTexto, setTipoDeCambioTexto] = useState("");


    // Watch values to calculate derived fields
    const cantidad = parseFloat(watch('dCantidad') || selectedGasto?.dCantidad || 0);
    const precioUnitario = parseFloat(watch('dPrecioUnitario') || selectedGasto?.dPrecioUnitario || 0);
    const descuento = parseFloat(watch('dDescuento') || selectedGasto?.dDescuento || 0);
    const iva = parseFloat(watch('dIva') || selectedGasto?.dIva || 0);


    // Calculate Importe and Total directly
    const importe = cantidad * precioUnitario;
    const total = importe - descuento + iva;



    




    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {
        obtenerTipoDeCambio()
        obtenerFormasdePago()
        obtenerCuentasBancarias()
        obtenerResponsableGasto()
        obtenerTipoDeGasto()
        

    }, []);




    // Obtención del tipo de cambio
    const obtenerTipoDeCambio = async () => {
        try {
            const response = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
            const data = await response.json();

            // Verificamos si rates.MXN existe antes de asignar el valor
            if (data && data.rates && data.rates.MXN) {
                // Si se obtiene correctamente el valor de MXN
                setTipoDeCambio({
                    USD: data.rates.MXN,  // 1 USD = X MXN
                    MXN: 1, // 1 MXN = X USD
                    USD_CAMBIADO: selectedGasto?.idTipoMoneda === 2 && selectedGasto?.vTipoCambio !== undefined && selectedGasto?.vTipoCambio !== null
                        ? selectedGasto.vTipoCambio // Si existe y la condición se cumple, usa este valor
                        : data.rates.MXN // Si no, usa el valor alternativo


                });
                setTipoDeCambioTexto(`1 USD = ${data.rates.MXN} MXN`); // Si es USD, muestra el tipo de cambio
            } else {
                throw new Error('Datos de tipo de cambio no disponibles');
            }
        } catch (error) {
            console.error("Error al obtener el tipo de cambio", error);
            // En caso de error, asignamos valores predeterminados razonables
            
        }

    };



  

    // Función para manejar el cambio de la moneda seleccionada
    const manejarCambioMoneda = (e) => {

        console.log("setmoneda", moneda)

        const nuevaMoneda = e.target.value;
        console.log("nuevaMoneda", nuevaMoneda)

        setMoneda(nuevaMoneda);

        

        if (moneda === "1") {
            setTipoDeCambioTexto(`1 USD = ${tipoDeCambio.USD} MXN`); // Si es USD, muestra el tipo de cambio
        } else if (moneda === "2") {
            setTipoDeCambioTexto("1 MXN = 1 MXN"); // Si es MXN, no hay cambio
        }
    };


    /**
     * Inserta la fecha al DatePicker
     */
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        setValue("dFechaGasto", selectedDate); // Actualiza el valor registrado en react-hook-form
    };

    /**
     * Procesa el archivo
     */
    const procesarArchivo = (file, setArchivo) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            // Generar la fecha actual en formato Día_Mes_Año
            const fechaActual = new Date();
            const opcionesFecha = { day: '2-digit', month: 'short', year: 'numeric' };
            const fechaFormateada = fechaActual.toLocaleDateString('es-ES', opcionesFecha)
                .replace(/\s+/g, '_'); // Cambiar espacios por guiones bajos (p.ej., "24 Dic 2024" -> "24_Dic_2024")

            // Formatear el total con el signo de pesos
            const totalFormateado = `$${total.toFixed(2)}`; // Usa la variable global total directamente

            // Generar un UUID único
            const uuid = uuidv4();

            // Crear el nuevo nombre del archivo
            const nuevoNombreArchivo = `Gasto_${fechaFormateada}_${totalFormateado}_${uuid}`;

            // Guardar el archivo en el estado con el nuevo nombre
            const base64String = event.target.result.split(",")[1];
            setArchivo({
                fileName: nuevoNombreArchivo,
                file: base64String
            });

            console.log("ARCHIVO Y NOMBRE", { fileName: nuevoNombreArchivo, file: base64String }); // Para depuración
        };

        reader.onerror = () => {
            console.error("Error al leer el archivo.");
        };

        reader.readAsDataURL(file);
    };


    

    /**
    * Descargar Archivo Nota
    */
    const descargarNota = (file) => {

        console.log("FILE", file);

        // Crea un objeto Blob a partir del archivo
        const blob = new Blob([file], { type: file.type });

        // Crea un enlace de descarga
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.name;

        // Haz clic en el enlace para iniciar la descarga
        link.click();

        // Libera la URL del objeto después de la descarga
        URL.revokeObjectURL(link.href);

    };

    /**
     * Selecciona la nota
     * @param {any} e
     */
    const seleccionarArchivoNotaPago = (e) => {
        const file = e.target.files[0];
        console.log(file)
        if (file) {
            // Validar extensión del archivo (solo permite .png, .jpg, .pdf)
            const allowedExtensions = ['.png', '.jpg', '.pdf'];
            const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                console.error("Solo se permiten archivos .png, .jpg o .pdf");
                setShowMensajeError(true);
                setMensajeError("Solo se permiten archivos con extensión .png, .jpg o .pdf");
                return;
            }

            // Validar longitud del nombre del archivo
            if (file.name.length > 50) {
                console.error("El nombre del archivo excede los 50 caracteres.");
                setShowMensajeError(true);
                setMensajeError("El nombre del archivo no debe exceder los 50 caracteres.");
                return;
            }

            // Validar tamaño del archivo (1 MB = 1 * 1024 * 1024 bytes)
            if (file.size > 1 * 1024 * 1024) {
                console.error("El archivo excede el tamaño máximo permitido de 1 MB.");
                setShowMensajeError(true);
                setMensajeError("El archivo no debe pesar más de 1 MB.");
                return;
            }

            // Si todas las validaciones pasan, procesa el archivo
            procesarArchivo(file, setNotaDeVenta);
            setShowMensajeError(false);

        } else {
            console.error("No se ha seleccionado un archivo válido.");
            setShowMensajeError(true);
            setMensajeError("Debe seleccionar un archivo válido.");
        }

    };

    /**
    * Obtiene obtenerTipoDeGasto
    */
    const obtenerTipoDeGasto = async () => {

        const response = await fetch("/api/Gastos/obtenerTipoDeGasto",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER TIPO GASTO ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataTP: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetallesTP:", ObjDetalles);

                setTipoDeGasto(ObjDetalles.table2);



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
    * Obtiene los usuarios nivel 1
    */
    const obtenerResponsableGasto = async () => {

        const response = await fetch("/api/Gastos/obtenerResponsableGasto",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER RESPONSABLE GASTO ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataRF: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetallesRG:", ObjDetalles);

                setResponsableUsuario(ObjDetalles.table2);



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
    * Obtiene los Formas Pago
    */
    const obtenerCuentasBancarias = async () => {

        const response = await fetch("/api/Gastos/obtenerCuentasBancarias",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER CUENTAS BANCARIAS ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataRF: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetallesRF:", ObjDetalles);

                setCuentasBancarias(ObjDetalles.table2);



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Obtiene los Formas Pago
     */
    const obtenerFormasdePago = async () => {

        const response = await fetch("/api/Gastos/obtenerFormasdePago",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER FORMAS DE PAGO ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("dataRF: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetallesRF:", ObjDetalles);

                setFormasdePago(ObjDetalles.table2);



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };



    
   

    /**
    * Registrar gasto
    */
    const registrarGasto = async (data) => {

        if (date == null) {
            setShowMensajeError(true);
            setMensajeError("La fecha es obligatoria");
            return;
        }

        if (selectedGasto && selectedGasto.idGasto) {
            data.idGasto = selectedGasto.idGasto;
        }

        data.vNotaDeVenta_Base64 = notaDeVenta.file;
        data.vNotaDeVenta_Name = notaDeVenta.fileName;
        data.dFechaGasto = date

        data.dimporte = importe
        data.dTotal = total

        setCargando(true)

        console.log("Data parameter(registrarGasto) ", data)

        




        const response = await fetch("/api/Gastos/registrarGasto",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== Registra los Gastos ====");

            console.log("response (registrarGasto):", response);
            const data = await response.json();
            console.log("data OBJRESPONSE (registrarGasto): ", data);

            setCargando(false)
            if (data.iResponseType == 1) {

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("Obj detalles (registrarGasto)", ObjDetalles)

                setShowModalRegistrarGasto(false);

                setGastos(ObjDetalles.table2)






            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }


        }
        else {
            setCargando(false)
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };


    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value || ""} // Si value es null o undefined, muestra una cadena vacía
                placeholder="Seleccione una fecha" // Muestra el texto cuando no hay valor
                className="ps-4"
                readOnly // Evita edición manual del campo
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));

    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func,
    };


    return (
        <>
           
            <Modal
                show={showModalRegistrarGasto}
                size="lg"
                backdrop="static"
                centered
                onHide={() => { setShowModalRegistrarGasto(false); }}
            >
                <Modal.Header>

                    {
                        showModalNuevo ?
                            <>
                                <Modal.Title>Registrar Gasto</Modal.Title>
                            </>
                            :
                            <>
                                <Modal.Title>Gasto</Modal.Title>
                                

                            </>
                    }
                    <Button
                        className="btn-close"
                        onClick={() => { setShowModalRegistrarGasto(false); setSelectedGasto([]); }}
                    />
                </Modal.Header>

                <Form onSubmit={handleSubmit(registrarGasto)}>
                    

                    <Modal.Body>
                        {/* Descripción del Gasto */}
                        <Row className="mb-2">
                            <Col xl={6} lg={6} md={12} sm={12} className="order-xl-1">
                                

                                <Form.Group>
                                    <Form.Label>Descripción del Gasto</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Form.Control
                                                    size="sm"
                                                    autoComplete="off"
                                                    type="text"
                                                    defaultValue={selectedGasto?.vDescripcion ?? ""} // Garantiza un valor predeterminado
                                                    {...register('vDescripcion',
                                                        {
                                                            required: "La descripción es obligatoria",
                                                            maxLength: 500,
                                                            minLength: 1,
                                                            message: "Máximo 500 caracteres"
                                                        })
                                                    }
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.toUpperCase(); // Convierte el texto a mayúsculas
                                                    }}
                                                    isInvalid={!!errors.vDescripcion}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vDescripcion && errors.vDescripcion.message}
                                                </Form.Control.Feedback>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={selectedGasto?.vDescripcion ?? ""} // Lee valores nulos o undefined
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>

                                    }
                                </Form.Group>


                                <Form.Group>
                                    <Form.Label>Tipo de Gasto</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idTipoGasto}
                                                    defaultValue={selectedGasto ? selectedGasto.idTipoGasto : ''}

                                                    {...register("idTipoGasto",
                                                        {
                                                            required: "Seleccione un tipo de gasto"
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona un tipo de Gasto</option>
                                                    {tipoDeGasto.map((tipo, index) => (
                                                        <option
                                                            key={index}
                                                            value={tipo.idTipoGasto}>
                                                            {`${tipo.vNombre}`}
                                                        </option>
                                                    ))}
                                                    
                                                </Form.Select>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={selectedGasto?.vTipoGasto ?? ""} // Lee valores nulos o undefined
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>
                                               
                                   

                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.idTipoGasto && errors.idTipoGasto.message}
                                    </Form.Control.Feedback> 
                                </Form.Group>



                                <Form.Group>
                                    <Form.Label>Cuenta Bancaria</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Form.Select
                                                    size="sm"
                                                    defaultValue={selectedGasto ? selectedGasto.idCatCuentaBancaria : ''}
                                                    isInvalid={!!errors.idCuentaBancaria}
                                                    {...register("idCuentaBancaria",
                                                        {
                                                            required: "Seleccione una cuenta bancaria"
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona una cuenta bancaria</option>
                                                    {cuentasBancarias.map((cuenta, index) => (
                                                        <option
                                                            key={index}
                                                            value={cuenta.idCatCuentaBancaria}>
                                                            {`${cuenta.vNumCta} - ${cuenta.vNombreBanco} - ${cuenta.vNombreTitular}`}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={selectedGasto?.DetallesCuenta ?? ""} // Lee valores nulos o undefined
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>



                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.idCuentaBancaria && errors.idCuentaBancaria.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Responsable del Gasto</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Form.Select
                                                    size="sm"
                                                    defaultValue={selectedGasto ? selectedGasto.idUsuario : ''}
                                                    isInvalid={!!errors.idUsuario}
                                                    {...register("idUsuario",
                                                        {
                                                            required: "Seleccione un responsable"
                                                        })
                                                    }
                                                >
                                                    <option value="">Seleccione un responsable</option>
                                                    {responsableUsuario.map((usuario, index) => (
                                                        <option
                                                            key={index}
                                                            value={usuario.idUsuario}>
                                                            {`${usuario.vNombre} ${usuario.vApellidos}`}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={selectedGasto?.vResponsable ?? ""} // Lee valores nulos o undefined
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>



                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.idUsuario && errors.idUsuario.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Fecha de Gasto</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Col>
                                                    <DatePicker
                                                        selected={date}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd MMM yyyy" // Formato deseado
                                                        customInput={<GreetingsDateInput />}
                                                        popperModifiers={[
                                                            {
                                                                name: "preventOverflow", // Evita que el calendario se solape con otros elementos
                                                                options: {
                                                                    boundary: "viewport", // Limita al área visible
                                                                },
                                                            },
                                                            {
                                                                name: "offset", // Ajusta el desplazamiento del calendario
                                                                options: {
                                                                    offset: [0, -290], // Desplaza el calendario 10px hacia abajo
                                                                },
                                                            },
                                                        ]}
                                                       
                                                    />
                                                </Col>
                                                

                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={
                                                                    selectedGasto?.dFechaGasto
                                                                        ? new Date(selectedGasto.dFechaGasto).toLocaleDateString("es-MX", {
                                                                            day: "2-digit",
                                                                            month: "short",
                                                                            year: "numeric",
                                                                        }).replace(/(?:^|\s)([a-z])/g, char => char.toUpperCase()) // Convierte el mes a mayúscula
                                                                        : ""
                                                                }
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>



                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.fechaGasto && errors.fechaGasto.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                               
                                
                                <Form.Group>
                                    <Form.Label>Forma de Pago</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <Form.Select
                                                    defaultValue={selectedGasto ? selectedGasto.idCatFormaPago : ''}
                                                    size="sm"
                                                    isInvalid={!!errors.idFormaPago}
                                                    {...register("idFormaPago",
                                                        {
                                                            required: "Seleccione una forma de pago"
                                                        })
                                                    }
                                                >
                                                    <option value="">Seleccione una forma de Pago</option>
                                                    {formasdePago.map((pago, index) => (
                                                        <option
                                                            key={index}
                                                            value={pago.idCatFormaPago}>
                                                            {pago.vClave} - {pago.vDescripcionFormaPago}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                defaultValue={selectedGasto?.vFormaPago ?? ""} // Lee valores nulos o undefined
                                                                readOnly // Hace que el campo no sea editable
                                                            />
                                                        </>
                                                }
                                            </>



                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.idFormaPago && errors.idFormaPago.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                

                                <Form.Group>
                                    <Form.Label>Nota de Venta (Opcional)</Form.Label>

                                    {
                                        showModalNuevo || Editar ?
                                            <>
                                                <InputGroup>
                                                    <InputGroup.Text
                                                        className="text-truncate"
                                                        style={{
                                                            maxWidth: '170px', // Ajusta el tamaño máximo del texto del archivo
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {notaDeVenta.fileName}
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={(e) => {
                                                            seleccionarArchivoNotaPago(e);
                                                            e.target.value = ""; // Reinicia el valor para permitir volver a seleccionar el mismo archivo
                                                        }}
                                                        style={{ display: "none" }}
                                                        id="fileUpload_cer"
                                                    />
                                                    <Button
                                                        variant="light"
                                                        size="sm" // Hace el botón más pequeño
                                                        className="sm" // Margen derecho para separar los botones
                                                        onClick={() => document.getElementById("fileUpload_cer").click()}
                                                    >
                                                        Seleccionar archivo
                                                    </Button>
                                                    <Button
                                                        variant="light"
                                                        size="sm" // Botón pequeño
                                                        className="d-flex align-items-center justify-content-center"
                                                        onClick={() => {
                                                            setNotaDeVenta({ fileName: "", file: "" }); // Limpia la selección del archivo
                                                            document.getElementById("fileUpload_cer").value = ""; // Resetea el valor del input

                                                        }}
                                                    >
                                                        x
                                                    </Button>
                                                </InputGroup>
                                            </>
                                            :
                                            <>
                                                {
                                                    cargando ?
                                                        <>
                                                            <Skeleton />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                selectedGasto.vNotaVenta_Name ?
                                                                    <>
                                                                        <Link onClick={() => { descargarNota(selectedGasto.fileNota); }}>
                                                                            {selectedGasto?.vNotaVenta_Name}
                                                                        </Link>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Form.Control
                                                                            size="sm"
                                                                            type="text"
                                                                            defaultValue={"No existe Nota de Venta"} // Lee valores nulos o undefined
                                                                            readOnly // Hace que el campo no sea editable
                                                                        />
                                                                    </>

                                                            }

                                                        </>
                                                }
                                            </>



                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors.formaPago && errors.formaPago.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                               


                            </Col>

                            {/* Cantidad, IVA, Descuento, Importe y Total */}
                            <Col
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                className="order-xl-2 order-lg-2 order-md-2 order-sm-2"
                            >
                                <Row>   

                                    {/* Select de Moneda */}
                                    <Col md={6} sm={12} className="mb-2">


                                            <Form.Group>
                                                <Form.Label>Tipo de Moneda</Form.Label>
                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Select
                                                            defaultValue={selectedGasto?.idTipoMoneda || "1"} // Convierte a cadena
                                                            autoComplete="off"
                                                            type="text"
                                                            size="sm"

                                                            {...register("idTipoMoneda",
                                                                {
                                                                required: "El tipo de moneda es obligatorio", // Validación opcional
                                                                })
                                                            }
                                                            onChange={manejarCambioMoneda}
                                                        >
                                                            <option value="1">MXN</option>
                                                            <option value="2">USD</option>

                                                        </Form.Select>
                                                        <Form.Text className="text-muted">
                                                            TC: {tipoDeCambioTexto}
                                                        </Form.Text>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.idTipoMoneda === 1 ? "MXN" : selectedGasto?.idTipoMoneda === 2 ? "USD" : ""}
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>

                                                        }
                                                    </>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.moneda && errors.moneda.message}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                    </Col>

                                    {/* Tipo de Cambio */}
                                    <Col md={6} sm={12} className="mb-2">


                                        <Form.Group>
                                            <Form.Label>Tipo de Cambio</Form.Label>
                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        {
                                                            moneda == "1" ?
                                                                <>
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={"1"} // El valor siempre será 1
                                                                        autoComplete="off"
                                                                        size="sm"
                                                                        step="any"
                                                                        readOnly // Campo de solo lectura
                                                                        {...register("vTipoCambio", {
                                                                            required: "El tipo de cambio es obligatorio",
                                                                        })}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={tipoDeCambio.USD_CAMBIADO} // El valor cambia dinámicamente
                                                                        autoComplete="off"
                                                                        size="sm"
                                                                        step="any"
                                                                        title="El tipo de cambio es actual y puedes modificarlo" // Tooltip al pasar el mouse

                                                                        {...register("vTipoCambio", {
                                                                            required: "El tipo de cambio es obligatorio",
                                                                        })}
                                                                        onChange={(e) =>
                                                                            setTipoDeCambio((prevState) => ({
                                                                                ...prevState,
                                                                                USD_CAMBIADO: e.target.value, // Permitir edición
                                                                            }))
                                                                        }
                                                                    />
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto.vTipoCambio}
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>

                                                        }
                                                    </>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.moneda && errors.moneda.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                    {/* Cantidad */}
                                    <Col md={6} sm={12} className="mb-2">


                                        <Form.Group>
                                            <Form.Label>Cantidad</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            defaultValue={selectedGasto?.dCantidad ?? ""} // Lee valores nulos o undefined
                                                            autoComplete="off"
                                                            type="number"
                                                            step="any"
                                                            isInvalid={!!errors.dCantidad}
                                                            {...register("dCantidad",
                                                                {
                                                                    required: "La cantidad es obligatoria",
                                                                    min: { value: 0, message: "Debe ser un valor positivo" }
                                                                })
                                                            }

                                                        />
                                                        
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dCantidad ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        
                                                    </>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dCantidad && errors.dCantidad.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* Precio Unitario */}
                                    <Col md={6} sm={12}>


                                        <Form.Group>
                                            <Form.Label>Precio Unitario</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            autoComplete="off"
                                                            type="number"
                                                            defaultValue={selectedGasto?.dPrecioUnitario ?? ""} // Lee valores nulos o undefined
                                                            step="0.0001" // Set step to the smallest unit, 0.0001, for up to 4 decimals
                                                            isInvalid={!!errors.dPrecioUnitario}
                                                            {...register("dPrecioUnitario",
                                                                {
                                                                    required: "El precio unitario es obligatorio",
                                                                    min: { value: 0, message: "Debe ser un valor positivo" },

                                                                    validate: value =>
                                                                        (value.toString().split(".")[1]?.length || 0) <= 4 ||
                                                                        "Debe tener un máximo de 4 decimales",
                                                                })
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dPrecioUnitario ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        
                                                    </>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dPrecioUnitario && errors.dPrecioUnitario.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* IVA */}
                                    <Col md={6} sm={12} className="mb-2 mb-md-0">


    
                                        <Form.Group>
                                            <Form.Label>IVA</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            autoComplete="off"
                                                            type="number"
                                                            step="any"
                                                            defaultValue={selectedGasto?.dIva ?? ""} // Lee valores nulos o undefined
                                                            isInvalid={!!errors.dIva}
                                                            {...register("dIva",
                                                                {   
                                                                    required: "El Iva unitario es obligatorio",
                                                                    min: { value: 0, message: "Debe ser un valor positivo" }
                                                                })
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dIva ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        
                                                    </>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dIva && errors.dIva.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* Descuento */}
                                    <Col md={6} sm={12}>


                                        <Form.Group>
                                            <Form.Label>Descuento</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            autoComplete="off"
                                                            type="number"
                                                            step="any"
                                                            defaultValue={selectedGasto?.dDescuento ?? ""} // Lee valores nulos o undefined
                                                            isInvalid={!!errors.dDescuento}
                                                            {...register("dDescuento",
                                                                {
                                                                    min: { value: 0, message: "Debe ser un valor positivo" },
                                                                    required: "El descuento unitario es obligatorio",

                                                                })
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dDescuento ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        
                                                    </>
                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dDescuento && errors.dDescuento.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* Importe */}
                                    <Col md={6} sm={12} className="mb-2 mb-md-0">

                                        <Form.Group>
                                            <Form.Label>Importe</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            autoComplete="off"
                                                            type="text"
                                                            value={importe}
                                                            readOnly

                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dImporte ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.descuento && errors.descuento.message}
                                                        </Form.Control.Feedback>
                                                    </>

                                            }
                                        </Form.Group>
                                    </Col>

                                    {/* Total */}
                                    <Col md={6} sm={12}>


                                        <Form.Group>
                                            <Form.Label>Total</Form.Label>

                                            {
                                                showModalNuevo || Editar ?
                                                    <>
                                                        <Form.Control
                                                            size="sm"
                                                            autoComplete="off"
                                                            type="text"
                                                            value={total}
                                                            readOnly
                                                        />
                                                    </>
                                                    :
                                                    <>  
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        defaultValue={selectedGasto?.dTotal ?? ""} // Lee valores nulos o undefined
                                                                        readOnly // Hace que el campo no sea editable
                                                                    />
                                                                </>
                                                        }
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.descuento && errors.descuento.message}
                                                        </Form.Control.Feedback>
                                                    </>

                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>

                        {
                            showModalNuevo ? 
                                <>
                                    <Col className="col-auto">
                                        {
                                            cargando ?
                                                <>
                                                    {/* mostrar boton como "cargando" */}
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        transform="shrink-2"
                                                        className="d-sm-block me-2"
                                                        disabled
                                                    >
                                                        <Spinner className="text-inline" animation="border" size="sm" variant="ligth" />&nbsp; Cargando...
                                                    </IconButton>
                                                </>
                                                :
                                                <>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="check"
                                                        transform="shrink-2"
                                                        className="d-sm-block me-2"
                                                        type="submit"
                                                    >
                                                        <span className="d-none d-xl-inline-block ms-1">Guardar</span>
                                                    </IconButton>
                                                </>
                                        }

                                    </Col>



                                    <IconButton
                                        onClick={(e) => {
                                            e.preventDefault(); setShowModalRegistrarGasto(false); setSelectedGasto({});
                                        }}

                                        variant="falcon-danger "
                                        size="sm"
                                        transform="shrink-2"
                                        className="d-sm-block me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">X Cancelar</span>
                                    </IconButton>
                                </>
                                :
                                <>
                                        {
                                            Editar ?
                                                <>


                                                    <Col className="col-auto">
                                                        <IconButton
                                                        onClick={(e) => {
                                                            e.preventDefault(); setEditar(false); setDate(initialDate); reset();
                                                            }}

                                                            variant="falcon-danger "
                                                            size="sm"
                                                            transform="shrink-2"
                                                            className="d-sm-block me-2"
                                                        >
                                                            <span className="d-none d-xl-inline-block ms-1">X Cancelar</span>
                                                        </IconButton>
                                                    </Col>

                                                    <Col className="col-auto">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    {/* mostrar boton como "cargando" */}
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        transform="shrink-2"
                                                                        className="d-sm-block me-2"
                                                                        disabled
                                                                    >
                                                                        <Spinner className="text-inline" animation="border" size="sm" variant="ligth" />&nbsp; Cargando...
                                                                    </IconButton>
                                                                </>
                                                                :
                                                                <>
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="check"
                                                                        transform="shrink-2"
                                                                        className="d-sm-block me-2"
                                                                        type="submit"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">Guardar</span>
                                                                    </IconButton>
                                                                </>
                                                        }

                                                    </Col>



                                                    
                                                </>
                                                :
                                                <>

                                                    
                                                    <Col className="col-auto">
                                                        <IconButton
                                                        onClick={(e) => {
                                                            e.preventDefault(); setShowModalRegistrarGasto(false); setSelectedGasto([]); }}

                                                            variant="falcon-danger "
                                                            size="sm"
                                                            transform="shrink-2"
                                                            className="d-sm-block me-2"
                                                        >
                                                            <span className="d-none d-xl-inline-block ms-1">X Cancelar</span>
                                                        </IconButton>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <IconButton
                                                            onClick={(e) => { e.preventDefault(); setEditar(true) }}

                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="pencil-alt"
                                                            transform="shrink-2"
                                                            className="d-sm-block me-2"
                                                        >
                                                            <span className="d-none d-xl-inline-block ms-1">Editar</span>
                                                        </IconButton>
                                                    </Col>



                                                </>
                                        }


                                </>

                    }

                        
                    </Modal.Footer>
                </Form>


            </Modal>
          
        </>
    );
};

ModalRegistroGasto.propTypes = {

  

    formasdePago: PropTypes.array,

    cuentasBancarias: PropTypes.array,

    responsableUsuario: PropTypes.array,

    tipoDeGasto: PropTypes.array,

    selectedGasto: PropTypes.object,
    setSelectedGasto: PropTypes.func,

    setGastos: PropTypes.func,

    showModalNuevo: PropTypes.bool,
    setShowModalNuevo: PropTypes.func,
    showModalRegistrarGasto: PropTypes.bool,
    setShowModalRegistrarGasto: PropTypes.func,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func

};

export default ModalRegistroGasto;