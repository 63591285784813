
/**
 * 18 Dic 2024  Javier Calderon     Primer versión
 * 
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';

import CopyButton from '../../servicio/CopyButton';
import SoftBadge from 'components/common/SoftBadge';
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { Col, Spinner } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LinkPago = (
    {
        linkPago,
        setLinksPagos,
        index,
        className,

        GuardarComentario,

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }
) => {

    const { token, setToken, nombreCompleto } = useToken();

    const [eliminando, setEliminando] = useState(false);

    /**
     * Elimina link de pago
     * @param {any} idLinkPago
     */
    const eliminarLinkPago = async (idLinkPago) => {

        setEliminando(true);

        var jDataSend =
        {
            idLinkPago: idLinkPago
        };

        const response = await fetch("/api/orden/stripe_EliminaLinkPago", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jDataSend)
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {

            const data = await response.json();
            if (data.iResponseType == 1) {

                // Eliminar el link de la lista actualizada
                setLinksPagos((prevLinks) =>
                    prevLinks.filter((link) => link.idLinkPago !== idLinkPago)
                );

                GuardarComentario("<b>" + nombreCompleto + "</b>  <b class='badge badge-soft-danger'>eliminó </b> el link de pago  <b> # " + idLinkPago + " </b>", 2);

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



            setEliminando(false);
        }
        else {

            setEliminando(false);

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };


    return (
      
        <>
            {
                linkPago &&
                <>
                    <Col key={index} className={className}>

                        <div key={index} className="mb-3">
                            <div className="d-flex align-items-center">

                                {
                                    eliminando ?
                                        <>

                                            <Spinner className="text-inline me-1" animation="border" size="sm" />

                                        </>
                                        :
                                        <>
                                            <FontAwesomeIcon
                                                icon="trash"
                                                className="fs-1 me-1"
                                                transform="shrink-4"
                                                style={{ color: "#B22222", margin: "5px", cursor: "pointer" }}
                                                onClick={() => eliminarLinkPago(linkPago.idLinkPago)}
                                            />
                                        </>
                                }


                                <span
                                    id={`linkPago-${index}`}
                                    className="me-3 text-break"
                                    value={linkPago.vLink}
                                >
                                    <strong>{linkPago.idLinkPago} - Copiar Enlace de pago por
                                        ${parseFloat(linkPago.dTotalPago).toLocaleString("es-MX", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })}&nbsp;
                                        {linkPago.vMoneda}</strong>
                                </span>

                                <>
                                    {
                                        linkPago.iStatus === 0 &&
                                        <>
                                            {
                                                eliminando ?
                                                    <>
                                                        <Spinner className="text-inline me-1" animation="border" size="sm" />
                                                    </>
                                                    :
                                                    <>
                                                        <CopyButton code={linkPago.vLink} />
                                                    </>
                                            }
                                        </>
                                    }
                                </>

                            </div>
                            <div className="d-flex align-items-center">
                                <SoftBadge
                                    pill
                                    bg={'stripe'}
                                    className="fs--2 me-1"
                                >
                                    <span className="mt-1 mb-1">
                                        {linkPago.vSistemaPago}
                                    </span>
                                </SoftBadge>

                                {linkPago.iStatus == 0 ? (
                                    <>
                                        <div className="d-flex align-items-center m-0">
                                            <SoftBadge pill bg="warning" className="fs--2 me-1">
                                                <span className="mt-1 mb-1">No ha sido pagado</span>
                                            </SoftBadge>
                                            <div className="mt-0" style={{ fontSize: "0.7rem" }}>
                                                {format(new Date(linkPago.dCreatedDate), "dd MMMM yyyy", { locale: es })}
                                            </div>
                                        </div>
                                    </>
                                ) : linkPago.iStatus == 1 ? (
                                    <>
                                        <div className="d-flex align-items-center m-0">
                                            <SoftBadge pill bg="success" className="fs--2 me-1">
                                                <span className="mt-1 mb-0">Pagado exitosamente</span>
                                            </SoftBadge>
                                            <div className="mt-0" style={{ fontSize: "0.7rem" }}>
                                                {format(new Date(linkPago.dUpdatedDate), "dd MMMM yyyy", { locale: es })}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="d-flex align-items-center m-0">
                                            <SoftBadge pill bg="danger" className="fs--2 me-1">
                                                <span className="mt-1 mb-0">Error al pagar</span>
                                            </SoftBadge>
                                            <div className="mt-0" style={{ fontSize: "0.7rem" }}>
                                                {format(new Date(linkPago.dUpdatedDate), "dd MMMM yyyy", { locale: es })}
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                        </div>
                    </Col>
                </>
            }
            

        </>

    );
};

LinkPago.propTypes = {
    linkPago: PropTypes.object,
    setLinksPagos: PropTypes.func,
    index: PropTypes.number,
    className: PropTypes.string,

    GuardarComentario: PropTypes.func,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func
};

export default LinkPago;
